import { driver } from "driver.js";

export const _runHomeDocumentation = ({
  forceStart,
}: {
  forceStart: boolean;
}) => {
  const disableDriverPermitions = window.localStorage.getItem(
    "disable-driver-permitions"
  );

  if (forceStart === false && disableDriverPermitions === "1") return false;

  if (process.env.REACT_APP_ENVIRONMENT === "production") return false;

  const driverInstance = driver({
    doneBtnText: "Listo",
    nextBtnText: "Siguiente",
    prevBtnText: "Anterior",
    showProgress: true,
    steps: [
      {
        element: "#driver-logo-home",
        popover: {
          title: "Felicitaciones",
          description:
            "Me alegra que hayas podido iniciar sesión. Ahora podemos empezar a trabajar.",
        },
      },
      {
        element: "#driver-logo-home",
        popover: {
          title: "Ayuda",
          description:
            "Lamentablemente todavía no estoy disponible para todas las funciones del sistema. Sin embargo, con el tiempo podré ayudar cada vez más.",
        },
      },
      {
        element: "#driver-help-icon",
        popover: {
          title: "Ayuda",
          description:
            "Siempre que necesite ayuda en una página, puede hacer clic aquí y ver las opciones disponibles. Si estoy disponible para las funciones de esta página, estaré encantado de ayudarle.",
        },
      },
    ],
  });

  return driverInstance;
};
