export const reservation_types: any = [
  {
    label: "Reserva creada - Modelo residencial",
    value: "residential_created",
  },
  {
    label: "Entrega realizada - Modelo residencial",
    value: "residential_delivered",
  },
  {
    label: "Paquete Retirado - Modelo residencial",
    value: "residential_picked",
  },
  {
    label: "Reserva creada - Modelo Pickup Con Reserva",
    value: "pickup_created",
  },
  {
    label: "Entrega realizada - Modelo Pickup Con Reserva",
    value: "pickup_delivered",
  },
  {
    label: "Paquete Retirado - Modelo Pickup Con Reserva",
    value: "pickup_picked",
  },
  {
    label: "Reserva creada - Modelo Pickup Sin Reserva",
    value: "pickup_box_created",
  },
  {
    label: "Entrega realizada - Modelo Pickup Sin Reserva",
    value: "pickup_box_delivered",
  },
  {
    label: "Paquete Retirado - Modelo Pickup Sin Reserva",
    value: "pickup_box_picked",
  },
  {
    label: "Reserva creada - Modelo ecommerce",
    value: "ecommerce_created",
  },
  {
    label: "Entrega realizada - Modelo ecommerce",
    value: "ecommerce_delivered",
  },
  {
    label: "Paquete Retirado - Modelo ecommerce",
    value: "ecommerce_picked",
  },
  {
    label: "Reserva creada - Modelo sameday",
    value: "sameday_created",
  },
  {
    label: "Entrega realizada - Modelo sameday",
    value: "sameday_delivered",
  },
  {
    label: "Paquete Retirado - Modelo sameday",
    value: "sameday_picked",
  },
  {
    label: "Reserva cancelada",
    value: "canceled",
  },
  {
    label: "Articulo cargado - Modelo Prestamo",
    value: "lending_loaded",
  },
  {
    label: "Artículos reservados - Modelo Prestamo",
    value: "lending_reserved",
  },
  {
    label: "Artículos retirados - Modelo Prestamo",
    value: "lending_picked_up",
  },
  {
    label: "Artículos devueltos - Modelo Prestamo",
    value: "lending_delivered",
  },
  {
    label: "Reserva cancelada - Modelo Prestamo",
    value: "lending_cancelled",
  },
  {
    label: "Reserva creada - Modelo residencial custodia",
    value: "residential_custody_created",
  },
  {
    label: "Entrega realizada - Modelo residencial custodia",
    value: "residential_custody_delivered",
  },
  {
    label: "Paquete retirado - Modelo residencial custodia",
    value: "residential_custody_picked",
  },
];
