/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Input, MaskedInput, Toggle } from "rsuite";
import Logo from "../../components/Logo";
import "./style.scss";
import { useDispatch } from "react-redux";
import { set_loader } from "../../redux/actions/loader";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import ErrorValidation from "../../components/ErrorValidation";
import {
  CheckDeliveryUserSchema,
  PendingDeliveryUserSchema,
} from "../../validations/delivery.users.validations";
import { phoneMask } from "../../helpers/masks";
import CompanyService from "../../services/company.service";
import { useParams } from "react-router-dom";
import { format, clean, validate as validateRut } from "rut.js";

const RegisterQrUser = () => {
  const [state, setState] = useState<any>({
    name: "",
    email: "",
    phone: "",
    rut: "111111111",
    apartment: "",
    address: "",
    has_disability: false,
    token: "",
    dynamic_field: "",
    validations: {},
    isDisabled: false,
  });

  const [showMessage, setShowMessage] = useState(false);
  const [showPreEditForm, setShowPreEditForm] = useState(false);
  const [action, setAction] = useState("create");
  const [company, setCompany] = useState<number>(0);
  const [message, setMessage] = useState("");
  const dispatch: any = useDispatch();
  const Company = new CompanyService();
  const params = useParams();

  useEffect(() => {
    _getCompanyByToken();
  }, []);

  const _getCompanyByToken = async () => {
    try {
      dispatch(set_loader({ is_loading: true }));
      const response = await Company.getCompanyByQrToken({
        token: params.token,
      });
      setState({
        ...state,
        token: params.token,
        address: response.data.address,
        company_type: response.data.company_type,
        rut: response.data.company_type === "eventos" ? "" : state.rut,
        apartment: response.data.company_type === "eventos" ? "N/A" : "",
        dynamic_field_label: response.data.dynamic_field_label,
        dynamic_field_placeholder: response.data.dynamic_field_placeholder,
        dynamic_field_type: response.data.dynamic_field_type,
        is_dynamic_field_enabled: response.data.is_dynamic_field_enabled,
      });
      setCompany(response.data.company_id);
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      _handleError(e, "No fue posible obtener la información de la empresa");
      dispatch(set_loader({ is_loading: false }));
      setState({
        ...state,
        isDisabled: true,
      });
    }
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;

    if (name === "rut" && value.length > 0) {
      if (value.length > 12) return;
      setState({ ...state, [name]: format(value) });
      return;
    }

    setState({ ...state, [name]: value });
  };

  const _handleSubmit = async (event: any) => {
    event.preventDefault();
    let validations: any = await validate(PendingDeliveryUserSchema, state);

    if (state.phone.indexOf("_") !== -1) {
      validations = validations || {};
      validations.phone = ["Numero de telefóno inválido"];
    }

    if (state.company_type === "eventos" && !validateRut(state.rut)) {
      validations = validations || {};
      validations.rut = ["RUT inválido"];
    }

    if (state.is_dynamic_field_enabled && !state.dynamic_field) {
      validations = validations || {};
      validations.dynamic_field = ["Este campo es requerido"];
    }

    console.log(validations);

    if (validations !== false) {
      setState({ ...state, validations });
      return;
    }
    setState({ ...state, validations: {} });

    dispatch(set_loader({ is_loading: true }));

    try {
      let response: any = null;

      if (action === "create") {
        response = await Company.createQrRegisterUser({
          ...state,
          rut: clean(state.rut),
        });
      }

      if (action === "update") {
        response = await Company.updateQrRegisterUser({
          ...state,
          rut: clean(state.rut),
        });
      }

      setMessage(response?.data?.status);
      setShowMessage(true);
      setState({
        ...state,
        name: "",
        email: "",
        phone: "",
        apartment: state.company_type === "eventos" ? "N/A" : "",
        company_type: state.company_type,
        rut: state.company_type === "eventos" ? "" : state.rut,
        dynamic_field: "",
        validations: {},
      });
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));

      if (e.response.data.errors) {
        setState({
          ...state,
          validations: e.response.data.errors,
        });
        return;
      }

      _handleError(e, "No fue posible concluir la acción solicitada");
    }
  };

  const _handleSearchUser = async () => {
    let validations: any = await validate(CheckDeliveryUserSchema, state);

    if (validations !== false) {
      setState({ ...state, validations });
      return;
    }

    setState({ ...state, validations: {} });

    dispatch(set_loader({ is_loading: true }));

    try {
      const response = await Company.getUserByEmail({
        email: state.email,
        name: state.name,
        company_id: company,
      });

      setState({
        ...state,
        id: response.data.id,
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        apartment: response.data.apartment,
        rut: format(response.data.rut),
        dynamic_field: response.data.dynamic_field,
        validations: {},
      });
      setShowMessage(false);
      setShowPreEditForm(false);
      setAction("update");

      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e, "No fue posible obtener la información del usuario");
    }
  };

  const _handleEditUser = () => {
    setShowPreEditForm(true);
    setState({
      ...state,
      name: "",
      rut: "",
      email: "",
      phone: "",
      dynamic_field: "",
      validations: {},
    });
  };

  const _handleCreateUser = () => {
    setShowPreEditForm(false);
    setShowMessage(false);
    setAction("create");
    delete state.id;
    setState({
      ...state,
      name: "",
      rut: "",
      email: "",
      phone: "",
      dynamic_field: "",
      validations: {},
    });
  };

  if (showMessage) {
    return (
      <div className="container-fluid sign-in p-0">
        <div className="content d-flex justify-content-center align-items-center p-3">
          <div
            className="sign-in-box shadow text-center"
            style={{ fontSize: "18px" }}
          >
            {message === "pending" && (
              <div className="w-100 d-flex flex-column align-items-center">
                <div className="bold my-2">¡Su registro ha sido exitoso! </div>
                <div className="size-09">
                  Cuando la administración valide el registro, podrá utilizar el
                  locker
                </div>
                <a href="https://www.elock.cl" rel="noreferrer" target="_blank">
                  www.elock.cl
                </a>
              </div>
            )}

            {message === "approved" && (
              <div className="w-100 d-flex flex-column align-items-center">
                <div className="bold my-2">¡Su registro ha sido exitoso! </div>
                <a href="https://www.elock.cl" rel="noreferrer" target="_blank">
                  www.elock.cl
                </a>
              </div>
            )}

            {message === "updated" && (
              <div className="w-100 d-flex flex-column align-items-center">
                <div className="bold my-2">
                  ¡Registro actualizado con éxito!{" "}
                </div>
                <a href="https://www.elock.cl" rel="noreferrer" target="_blank">
                  www.elock.cl
                </a>
              </div>
            )}

            <div className="mt-4">
              <Button
                type="submit"
                appearance="primary"
                onClick={_handleCreateUser}
              >
                {message === "updated" ? "Volver" : "Registrar otro usuario"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showPreEditForm) {
    return (
      <div className="container-fluid sign-in p-0">
        <div className="content d-flex justify-content-center align-items-center p-3">
          <div className="sign-in-box shadow">
            <form>
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <Logo style={{ width: "100px" }} />
                </div>
                <div className="w-100 text-center size-15 mb-1 bold">
                  Informa tus datos
                </div>
                <div className="col-12 mb-3 mt-5">
                  Primer Nombre
                  <Input
                    value={state.name}
                    onChange={(e: any) =>
                      _handleOnChange({ target: { name: "name", value: e } })
                    }
                  />
                  <div className="text-end">
                    <ErrorValidation
                      validations={state.validations}
                      name="name"
                    />
                  </div>
                </div>

                <div className="col-12 mb-3">
                  Correo electrónico
                  <Input
                    value={state.email}
                    onChange={(e: any) =>
                      _handleOnChange({
                        target: { name: "email", value: e },
                      })
                    }
                  />
                  <div className="text-end">
                    <ErrorValidation
                      validations={state.validations}
                      name="email"
                    />
                  </div>
                </div>

                <div className="w-100 d-flex justify-content-center">
                  <div className="mt-3">
                    <Button
                      type="button"
                      appearance="ghost"
                      onClick={_handleCreateUser}
                      disabled={state.isDisabled}
                    >
                      Volver
                    </Button>
                  </div>

                  <div className="mt-3 ms-3">
                    <Button
                      type="button"
                      appearance="primary"
                      onClick={_handleSearchUser}
                      disabled={state.isDisabled}
                    >
                      Buscar usuario
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid sign-in p-0">
      <div className="content d-flex justify-content-center align-items-center p-3">
        <div className="sign-in-box shadow">
          <form>
            <div className="row">
              <div className="col-12 text-center mb-4">
                <Logo style={{ width: "100px" }} />
              </div>
              <div className="w-100 text-center size-15 mb-1 bold">
                Formulario inscripción Elock
              </div>
              {state.company_type !== "eventos" && (
                <div className="w-100 text-center">
                  Llena el formulario con tus datos para poder recibir y dejar
                  paquetes en el locker
                </div>
              )}
              <div className="col-12 mb-3 mt-5">
                Nombre
                <Input
                  value={state.name}
                  onChange={(e: any) =>
                    _handleOnChange({ target: { name: "name", value: e } })
                  }
                />
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="name"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                Email
                <Input
                  value={state.email}
                  onChange={(e: any) =>
                    _handleOnChange({ target: { name: "email", value: e } })
                  }
                />
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="email"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                Teléfono
                <MaskedInput
                  mask={phoneMask}
                  value={state.phone}
                  guide={true}
                  showMask={false}
                  keepCharPositions={false}
                  placeholder={""}
                  placeholderChar={"_"}
                  onChange={(value: any) =>
                    _handleOnChange({ target: { name: "phone", value } })
                  }
                />
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="phone"
                  />
                </div>
              </div>
              {state.company_type !== "eventos" && (
                <div className="col-12 mb-3">
                  Número Departamento/Casa
                  <Input
                    value={state.apartment}
                    onChange={(e: any) =>
                      _handleOnChange({
                        target: { name: "apartment", value: e },
                      })
                    }
                  />
                  <div className="text-end">
                    <ErrorValidation
                      validations={state.validations}
                      name="apartment"
                    />
                  </div>
                </div>
              )}

              {state.company_type === "eventos" && (
                <>
                  <div className="col-12 mb-3" translate="no">
                    Rut
                    <Input
                      value={state.rut}
                      onChange={(e: any) =>
                        _handleOnChange({
                          target: { name: "rut", value: e },
                        })
                      }
                    />
                    <div className="text-end">
                      <ErrorValidation
                        validations={state.validations}
                        name="rut"
                      />
                    </div>
                  </div>
                </>
              )}

              {state.is_dynamic_field_enabled && (
                <>
                  <div className="col-12 mb-3">
                    {state.dynamic_field_label}
                    <Input
                      value={state.dynamic_field}
                      placeholder={state.dynamic_field_placeholder}
                      onChange={(e: any) =>
                        _handleOnChange({
                          target: { name: "dynamic_field", value: e },
                        })
                      }
                      as={
                        state.dynamic_field_type === "textarea"
                          ? "textarea"
                          : "input"
                      }
                    />
                    <div className="text-end">
                      <ErrorValidation
                        validations={state.validations}
                        name="dynamic_field"
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="col-12 mb-3">
                <Toggle
                  checked={state.has_disability}
                  onChange={(e: any) =>
                    _handleOnChange({
                      target: { name: "has_disability", value: e },
                    })
                  }
                />
                <span className="ms-2">¿Tienes movilidad reducida?</span>
                <div className="text-end">
                  <ErrorValidation
                    validations={state.validations}
                    name="has_disability"
                  />
                </div>
              </div>

              <div className="w-100 d-flex justify-content-center">
                {state.company_type === "eventos" && action === "update" && (
                  <div className="mt-3 me-3">
                    <Button
                      type="button"
                      appearance="ghost"
                      onClick={_handleCreateUser}
                    >
                      Volver
                    </Button>
                  </div>
                )}

                {state.company_type === "eventos" && action === "create" && (
                  <div className="mt-3 me-3">
                    <Button
                      type="button"
                      appearance="ghost"
                      onClick={_handleEditUser}
                    >
                      Editar mis datos
                    </Button>
                  </div>
                )}

                <div className="mt-3">
                  <Button
                    type="submit"
                    appearance="primary"
                    onClick={_handleSubmit}
                    disabled={state.isDisabled}
                  >
                    {action === "create" ? "Registrar" : "Actualizar"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterQrUser;
