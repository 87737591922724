import { Input } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";

const ResidentialCustodyTextForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      {/* CUSTODY STEP 1 */}
      <div className="col-12 py-2 bold-300 mt-3">
        Custodia - Paso 1 - Numero teléfono
      </div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Ingresa el número de teléfono de la persona que va a retirar"
          value={data?.custody_step_one_text_one}
          onChange={(value: any) =>
            onChange({ target: { name: "custody_step_one_text_one", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="custody_step_one_text_one"
          validations={data?.validations}
        />
      </div>

      {/* CUSTODY STEP 2 */}
      <div className="col-12 py-2 bold-300 mt-3">Custodia - Paso 2 - Cajas</div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Selecciona el tamaño de caja que necesitas"
          value={data?.custody_step_two_text_one}
          onChange={(value: any) =>
            onChange({
              target: { name: "custody_step_two_text_one", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="custody_step_two_text_one"
          validations={data?.validations}
        />
      </div>

      {/* CUSTODY STEP 3 */}
      <div className="col-12 py-2 bold-300 mt-3">
        Custodia - Paso 3 - Confirmación
      </div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Si el tamaño de la caja no te sirve, selecciona la opción para cambiar caja a continuación."
          value={data?.custody_step_three_text_one}
          onChange={(value: any) =>
            onChange({
              target: { name: "custody_step_three_text_one", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="custody_step_three_text_one"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default ResidentialCustodyTextForm;
