import { Input, TagInput } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";

const EventsHelpForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      <div className="col-12 py-2 bold-300 mt-3">
        Correos electrónicos a los que se envia la solicitud de ayuda
      </div>
      <hr />
      <div className="col-12 mb-2 driver-help-text-1">
        <label className="size-08 bold-300 ms-1">Correos electrónicos</label>
        <TagInput
          trigger={["Enter", "Space", "Comma"]}
          className="w-100"
          placeholder="Ingresa los correos electrónicos"
          defaultValue={data?.events_help_emails}
          data={data?.events_help_emails}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_help_emails", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_help_emails"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Teléfonos a los que se envia la solicitud de ayuda
      </div>
      <hr />
      <div className="col-12 mb-2 driver-help-text-2">
        <label className="size-08 bold-300 ms-1">Teléfonos - (formato: 56999999999)</label>
        <TagInput
          className="w-100"
          trigger={["Enter", "Space", "Comma"]}
          placeholder="Ingresa los teléfonos"
          defaultValue={data?.events_help_phones}
          data={data?.events_help_phones}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_help_phones", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_help_phones"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3 ">Texto Ayuda</div>
      <hr />
      <div className="col-12 mb-2 driver-help-text-3">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Ingresa tu número telefónico y te contactaremos enseguida!"
          value={data?.events_help_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_help_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_help_text"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Texto éxito Ayuda</div>
      <hr />
      <div className="col-12 mb-2 driver-help-text-4">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Solicitud completada con éxito. Te llamaremos pronto."
          value={data?.events_help_success_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_help_success_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_help_success_text"
          validations={data?.validations}
        />
      </div>
      <div className="col-12 py-2 bold-300 mt-3">Texto error Ayuda</div>
      <hr />
      <div className="col-12 mb-2 driver-help-text-5">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="No fue posible completar tu solicitud. Vuelve a intentar en algunos minutos."
          value={data?.events_help_failed_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_help_failed_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_help_failed_text"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default EventsHelpForm;
