/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Logo from "../../../components/Logo";
import ItemMenu from "./ItemMenu";
import { useLocation } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { useSelector } from "react-redux";
const Menu = () => {
  const [state, setState] = useState({
    menu: [],
  });
  const { session } = useSelector((state: any) => state);
  const location = useLocation();

  useEffect(() => {
    if (session.is_logged) {
      const menuList: any = routes.filter(
        (item: any) =>
          item.permissions.find(
            (permission: any) =>
              permission === "all" ||
              permission === session?.profile?.roles[0]?.name
          ) && item.menu !== false
      );
      const menus: any = menuList.map((item: any) => item.menu);
      menus.map((item: any) => {
        if (item.url === location.pathname) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      });
      setState({ ...state, menu: menus });
    }
  }, [location.pathname]);

  return (
    <div className="row m-0 px-2 position-fixed" style={{ width: "280px" }}>
      <div className="default-template-logo d-flex justify-content-center align-items-center px-5">
        <Logo style={{ width: "80%" }} />
      </div>
      <div className="pb-5" style={{height: 'calc(100vh - 88px)', overflow: 'auto'}}>
        {state.menu &&
          state.menu.map((item: any) => <ItemMenu {...item} key={item.url} />)}
      </div>
    </div>
  );
};

export default Menu;
