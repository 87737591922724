export const residentialStateSchema = {
  home_text_one: "Selecciona si deseas Entrega o Retiro de tu caja",
  home_actions_one: [1],
  home_btn_delivery_title: "Entrega",
  home_btn_pickup_title: "Retiro",
  home_btn_custody_title: "Custodia",
  home_btn_delivery_description: "Presiona aquí para entregar un paquete.",
  home_btn_pickup_description: "Presiona aquí para retirar un paquete.",
  home_btn_custody_description:
    "¡Deja un paquete para que retire quien quieras!",
  delivery_step_one_text_one: "Digita el número de Departamento/Casa",
  delivery_step_one_keyboard_type: "spanish_basic_without_space_with_ok",
  delivery_step_two_text_one: "Selecciona el residente",
  delivery_step_three_text_one: "Selecciona el tamaño de caja que necesitas",
  delivery_step_four_text_one:
    "Si el tamaño de la caja no te sirve, selecciona la opción para cambiar caja a continuación",
  pickup_step_one_text_one: "Introduce o escanea el código QR",
  pickup_step_two_text_one: "Abre la(s) caja(s) y retira tu paquete",
  custody_step_one_text_one:
    "Ingresa el número de teléfono de la persona que va a retirar",
  custody_step_two_text_one: "Selecciona el tamaño de caja que necesitas",
  custody_step_three_text_one:
    "Si el tamaño de la caja no te sirve, selecciona la opción para cambiar caja a continuación",
};

export const keyboardTypesResidencialSchema = [
  { label: "Teclado - Números", value: "spanish_only_numbers" },
  {
    label: "Teclado - Números y letras",
    value: "spanish_basic_without_space_with_ok",
  },
];

export const residentialActionsSchema = [
  { label: "Entrega y retiro", value: 1 },
  { label: "Custodia", value: 2 },
  { label: "Servicios (Ecommerce y Sameday)", value: 3 },
];

export const residentialOfflineActionsSchema = [
  { label: "Entrega y retiro", value: 1 },
  { label: "Custodia", value: 2 },
];

export const SchemaSections:any = {
  home: [
    "home_text_one",
    "home_actions_one",
    "home_btn_delivery_title",
    "home_btn_pickup_title",
    "home_btn_custody_title",
    "home_btn_delivery_description",
    "home_btn_pickup_description",
    "home_btn_custody_description",
  ],
  delivery: [
    "delivery_step_one_text_one",
    "delivery_step_one_keyboard_type",
    "delivery_step_two_text_one",
    "delivery_step_three_text_one",
    "delivery_step_four_text_one",
  ],
  pickup: ["pickup_step_one_text_one", "pickup_step_two_text_one"],
  custody: ["custody_step_one_text_one", "custody_step_two_text_one", "custody_step_three_text_one"],
};
