import { Input } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";

const PickupPickupTextForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      {/* CUSTODY STEP 1 */}
      <div className="col-12 py-2 bold-300 mt-3">
        Retiro - Paso 1 - Codigo Qr
      </div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Introduce o escanea el código QR"
          value={data?.pickup_without_reservation_pickup_step_one_text_one}
          onChange={(value: any) =>
            onChange({
              target: { name: "pickup_without_reservation_pickup_step_one_text_one", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="pickup_without_reservation_pickup_step_one_text_one"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Retiro - Paso 2 - Cajas</div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Abre la(s) caja(s) y guarda tu paquete"
          value={data?.pickup_without_reservation_pickup_step_two_text_one}
          onChange={(value: any) =>
            onChange({
              target: { name: "pickup_without_reservation_pickup_step_two_text_one", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="pickup_without_reservation_pickup_step_two_text_one"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Retiro - Paso 3 - Mensaje éxito
      </div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Transacción completada con éxito"
          value={data?.pickup_without_reservation_pickup_step_three_text_one}
          onChange={(value: any) =>
            onChange({
              target: { name: "pickup_without_reservation_pickup_step_three_text_one", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="pickup_without_reservation_pickup_step_three_text_one"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default PickupPickupTextForm;
