import { driver } from "driver.js";

const exitComponent = () => {
  return `
    <p>Presione el botón para iniciar sesión</p>
    <p class="d-flex align-items-center"><input class="me-2" type="checkbox" id="disable-driver-permitions"  id="disable-driver-permitions"/> No volver a mostrar</p>
  `;
};
export const _runSignInDocumentation = ({
  forceStart = false,
}: {
  forceStart: boolean;
}) => {
  const disableDriverPermitions = window.localStorage.getItem(
    "disable-driver-permitions"
  );

  if (forceStart === false && disableDriverPermitions === "1") return false;

  if (process.env.REACT_APP_ENVIRONMENT === "production") return false;

  const htmlData: any = {
    activeIndex: 0,
    htmlElement: null,
  };

  const driverInstance = driver({
    onDeselected: (el, step, opts) => {
      if (htmlData.htmlElement && Object.keys(opts.state).length === 0) {
        if (htmlData.htmlElement.checked) {
          window.localStorage.setItem("disable-driver-permitions", "1");
        }
      }
    },
    onHighlighted: () => {
      const htmlElement = document.querySelector("#disable-driver-permitions");
      htmlData.htmlElement = htmlElement;
    },
    doneBtnText: "Listo",
    nextBtnText: "Siguiente",
    prevBtnText: "Anterior",
    showProgress: true,
    steps: [
      {
        element: ".sign-in-box",
        popover: {
          title: "Bienvenido",
          description:
            "Bienvenido a Elock. Soy su asistente virtual y lo guiaré en el proceso de inicio de sesión ",
        },
      },
      {
        element: ".sign-in-box form",
        popover: {
          title: "Iniciar sesión",
          description:
            "Para iniciar sesión, primero debe ingresar su email y contraseña",
        },
      },
      {
        element: "#driver-email-field",
        popover: {
          title: "Email",
          description: "Ingrese su email",
        },
      },
      {
        element: "#driver-password-field",
        popover: {
          title: "Contraseña",
          description: "Ingrese su contraseña",
        },
      },
      {
        element: "#driver-submit-btn",
        popover: {
          title: "Iniciar sesión",
          description: exitComponent(),
        },
      },
    ],
  });

  return driverInstance;
};
