import * as yup from "yup";

export const DynamicLendingReservationSchema = yup.object().shape({
  lending_home_pickup_list_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  lending_home_delivery_list_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  lending_home_pickup_list: yup
    .array()
    .nullable()
    .min(1, "Debes agregar al menos un paso")
    .required("Este campo es requerido"),
  lending_home_delivery_list: yup
    .array()
    .nullable()
    .min(1, "Debes agregar al menos un paso")
    .required("Este campo es requerido"),
  lending_load_step_one_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  lending_load_step_two_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  lending_pickup_step_one_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  lending_delivery_step_one_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  lending_delivery_step_two_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
});

export type DynamicLendingType = yup.InferType<
  typeof DynamicLendingReservationSchema
>;

export const DynamicLendingListReservationSchema = yup.object().shape({
  id: yup.string().nullable().required("Este campo es requerido"),
  title: yup.string().nullable().required("Este campo es requerido"),
});

export type DynamicLendingListType = yup.InferType<
  typeof DynamicLendingListReservationSchema
>;
