import { Input } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";
import { FaPlusCircle } from "react-icons/fa";

const LendingHomeTextForm = ({
  onChange,
  addNewListItem,
  deleteListItem,
  data,
}: any) => {
  return (
    <div className="row justify-content-between">
      {/* CUSTODY STEP 1 */}
      <div className="col" style={{ maxWidth: "48%" }}>
        <div className="row">
          <div className="col-12 py-2 bold-300 mt-3">
            Como solicito un préstamo
          </div>
          <hr className="w-75" />
          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Titulo</label>
            <Input
              placeholder="¿Como solicito?"
              value={data?.lending_home_pickup_list_title}
              onChange={(value: any) =>
                onChange({
                  target: { name: "lending_home_pickup_list_title", value },
                })
              }
            />
            <ErrorValidation
              className="text-end size-08"
              name="lending_home_pickup_list_title"
              validations={data?.validations}
            />
          </div>

          <div className="col-12">
            <div className="w-100">
              <label className="size-08 bold-300 ms-1">
                Lista de pasos para solicitar un préstamo
              </label>
              <button className="btn">
                <FaPlusCircle
                  className="size-12"
                  onClick={() =>
                    addNewListItem({ list: "lending_home_pickup_list" })
                  }
                />
              </button>
              <ErrorValidation
                className="text-end size-08"
                name="lending_home_pickup_list"
                validations={data?.validations}
              />
            </div>
            {data?.lending_home_pickup_list?.map((item: any, index: number) => (
              <div
                className="row py-2 border rounded my-3"
                key={`lending_home_pickup_list-${index}`}
              >
                <div className="col-12 mb-3">
                  <label className="size-08 bold-300 ms-1">Titulo</label>
                  <Input
                    placeholder="titulo"
                    value={item?.title}
                    onChange={(value: any) =>
                      onChange({
                        target: {
                          name: `lending_home_pickup_list.${item.id}.title`,
                          value,
                        },
                      })
                    }
                  />
                  <ErrorValidation
                    className="text-end size-08"
                    name="title"
                    validations={
                      data?.validations?.list_errors?.pickup_list_validations?.find(
                        (error: any) => error.id === item.id
                      )?.validations
                    }
                  />
                </div>
                <div className="col-12">
                  <label className="size-08 bold-300 ms-1">Descripción</label>
                  <Input
                    placeholder="Descripción"
                    value={item?.description}
                    onChange={(value: any) =>
                      onChange({
                        target: {
                          name: `lending_home_pickup_list.${item.id}.description`,
                          value,
                        },
                      })
                    }
                  />
                </div>
                <div className="col-12 text-end mt-2">
                  <button
                    className="btn border-bottom border-3 p-0"
                    onClick={() =>
                      deleteListItem({
                        list: "lending_home_pickup_list",
                        id: item.id,
                      })
                    }
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="col" style={{ maxWidth: "48%" }}>
        <div className="row">
          <div className="col-12 py-2 bold-300 mt-3">
            Como devuelvo un préstamo
          </div>
          <hr className="w-75" />
          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Titulo</label>
            <Input
              placeholder="¿Como devuelvo?"
              value={data?.lending_home_delivery_list_title}
              onChange={(value: any) =>
                onChange({
                  target: { name: "lending_home_delivery_list_title", value },
                })
              }
            />
            <ErrorValidation
              className="text-end size-08"
              name="lending_home_delivery_list_title"
              validations={data?.validations}
            />
          </div>

          <div className="col-12">
            <div className="w-100">
              <label className="size-08 bold-300 ms-1">
                Lista de pasos para devolver un préstamo
              </label>
              <button className="btn">
                <FaPlusCircle
                  className="size-12"
                  onClick={() =>
                    addNewListItem({ list: "lending_home_delivery_list" })
                  }
                />
              </button>
              <ErrorValidation
                className="text-end size-08"
                name="lending_home_delivery_list"
                validations={data?.validations}
              />
            </div>
            {data?.lending_home_delivery_list?.map(
              (item: any, index: number) => (
                <div
                  className="row py-2 border rounded my-3"
                  key={`lending_home_delivery_list-${index}`}
                >
                  <div className="col-12 mb-3">
                    <label className="size-08 bold-300 ms-1">Titulo</label>
                    <Input
                      placeholder="titulo"
                      value={item?.title}
                      onChange={(value: any) =>
                        onChange({
                          target: {
                            name: `lending_home_delivery_list.${item.id}.title`,
                            value,
                          },
                        })
                      }
                    />
                    <ErrorValidation
                      className="text-end size-08"
                      name="title"
                      validations={
                        data?.validations?.list_errors?.delivery_list_validations?.find(
                          (error: any) => error.id === item.id
                        )?.validations
                      }
                    />
                  </div>
                  <div className="col-12">
                    <label className="size-08 bold-300 ms-1">Descripción</label>
                    <Input
                      placeholder="Descripción"
                      type="textarea"
                      value={item?.description}
                      onChange={(value: any) =>
                        onChange({
                          target: {
                            name: `lending_home_delivery_list.${item.id}.description`,
                            value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="col-12 text-end mt-2">
                    <button
                      className="btn border-bottom border-3 p-0"
                      onClick={() =>
                        deleteListItem({
                          list: "lending_home_delivery_list",
                          id: item.id,
                        })
                      }
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LendingHomeTextForm;
