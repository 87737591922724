/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Nav, Tag } from "rsuite";
import ResidentialHomeTextForm from "./ResidentialHomeTextForm";
import ResidentialDeliveryTextForm from "./ResidentialDeliveryTextForm";
import ResidentialPickupTextForm from "./ResidentialPickupTextForm";
import ResidentialCustodyTextForm from "./ResidentialCustodyTextForm";
import { validateResidentialForm } from "../../../../pages/DynamicModule/validations.helper";
import { SchemaSections } from "./Schema";

const ResidentialTextForm = ({ module, onConfirm }: any) => {
  const [activeKey, setActiveKey] = useState("1");
  const [state, setState] = useState<any>({});
  const [sectionErros, setSectionErros] = useState<any>({});

  useEffect(() => {
    setState({
      ...module,
    });
  }, [module]);

  const _handleOnChangeState = (e: any) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const _handleValidations = async () => {
    const validations = await validateResidentialForm(state);
    if (validations) {
      setState({ ...state, validations });
      const errors = Object.keys(validations);
      const sections: string[] = Object.keys(SchemaSections);
      const errosBySection = sections.reduce((acc: any, section: any) => {
        const errorsInSection = errors.filter((error) =>
          SchemaSections[section].includes(error)
        );
        if (errorsInSection.length) {
          acc[section] = errorsInSection;
        }
        return acc;
      }, {});
      setSectionErros(errosBySection);
      return;
    }
    setState({ ...state, validations: {} });
    setSectionErros({});
    onConfirm({...state, validations: {}});
  };

  return (
    <div className="container-fluid py-3 px-5">
      <Nav
        appearance="subtle"
        activeKey={activeKey}
        onSelect={(value) => setActiveKey(value)}
      >
        <Nav.Item eventKey="1">
          Pagina Inicial
          {sectionErros.home && (
            <Tag color="red" className="ms-2">
              {sectionErros?.home?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="2">
          Entrega
          {sectionErros.delivery && (
            <Tag color="red" className="ms-2">
              {sectionErros?.delivery?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="3">
          Retiro
          {sectionErros.pickup && (
            <Tag color="red" className="ms-2">
              {sectionErros?.pickup?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="4">
          Custodia
          {sectionErros.custody && (
            <Tag color="red" className="ms-2">
              {sectionErros?.custody?.length}
            </Tag>
          )}
        </Nav.Item>
      </Nav>

      <div className="py-4">
        {activeKey === "1" && (
          <ResidentialHomeTextForm
            onChange={_handleOnChangeState}
            data={state}
          />
        )}

        {activeKey === "2" && (
          <ResidentialDeliveryTextForm
            onChange={_handleOnChangeState}
            data={state}
          />
        )}
        {activeKey === "3" && (
          <ResidentialPickupTextForm
            onChange={_handleOnChangeState}
            data={state}
          />
        )}
        {activeKey === "4" && (
          <ResidentialCustodyTextForm
            onChange={_handleOnChangeState}
            data={state}
          />
        )}
      </div>

      <div className="w-100 text-end">
        <Button appearance="primary" onClick={_handleValidations}>
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default ResidentialTextForm;
