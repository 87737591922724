export const _mountSpeakerMenu = ({
  _handleOnSelect,
  menuData,
  page = "/",
}: any) => {
  return {
    menu: [...menuData],
    _handleOnClick: (eventKey: any, _speaker: any) =>
      _handleOnSelect({ option: eventKey, page: page }),
  };
};
