export const states:any = [
    {
        value:'orden iniciada',
        label: 'Orden Iniciada'
    },
    {
        value:'en el locker',
        label: 'En el Locker'
    },
    {
        value:'Retirado',
        label: 'Retirado'
    },
    {
        value:'cancelado',
        label: 'Cancelado'
    },
    {
        value:'aguardando retiro',
        label: 'Aguardando Retiro'
    },
    {
        value:'reservado',
        label: 'Reservado'
    },
    {
        value:'retirado',
        label: 'Retirado'
    },
    {
        value:'entregado',
        label: 'Entregado'
    },
    {
        value:'Custodia por retirar',
        label: 'Custodia por retirar'
    },
    {
        value:'Custodia retirada',
        label: 'Custodia Retirada'
    },
]