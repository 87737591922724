import { useEffect, useState } from "react";
import { Button, Nav, Tag } from "rsuite";
import LendingHomeTextForm from "./LendingHomeTextForm";
import { v4 } from "uuid";
import LendingDeliveryTextForm from "./LendingDeliveryTextForm";
import LendingLoadtextForm from "./LendingLoadTextForm";
import LendingPickupTextForm from "./LendingPickupTextForm";
import {
  validateLendingListReservationForm,
  validateLendingReservationForm,
} from "../../../../pages/DynamicModule/validations.helper";
import { SchemaSections } from "./Schema";

const LendingtextForm = ({ module, onConfirm }: any) => {
  const [activeKey, setActiveKey] = useState("1");
  const [state, setState] = useState<any>({});
  const [sectionErros, setSectionErros] = useState<any>({});

  useEffect(() => {
    setState({
      ...module,
    });
  }, [module]);

  const _handleOnChangeState = (e: any) => {
    const { name, value } = e.target;
    const hasPoint = name.includes(".");

    if (hasPoint) {
      const [listname, id, key] = name.split(".");
      const item = state[listname].find((item: any) => item.id === id);
      item[key] = value;
      setState({
        ...state,
        [listname]: [...state[listname]],
      });
      return;
    }

    setState({ ...state, [name]: value });
  };

  const _addNewListItem = ({ list }: any) => {
    if (state[list] === undefined) {
      setState({
        ...state,
        [list]: [{ id: v4(), title: "", description: "" }],
      });
      return;
    }

    setState({
      ...state,
      [list]: [...state[list], { id: v4(), title: "", description: "" }],
    });
  };

  const _deleteListItem = ({ list, id }: any) => {
    const newList = state[list].filter((item: any) => item.id !== id);
    setState({ ...state, [list]: newList });
  };

  const _handleValidations = async () => {
    /** VALIDATE BASIC FORM FIELDS */
    let validations: any = await validateLendingReservationForm(state);

    let errosBySection: any = {};

    if (validations) {
      setState({ ...state, validations });
      const errors = Object.keys(validations);
      const sections: string[] = Object.keys(SchemaSections);
      errosBySection = sections.reduce((acc: any, section: any) => {
        const errorsInSection = errors.filter((error) =>
          SchemaSections[section].includes(error)
        );
        if (errorsInSection.length) {
          acc[section] = errorsInSection;
        }
        return acc;
      }, {});
    }

    const pickup_list_validations = [];
    const delivery_list_validations = [];

    /** VALIDATE DYNAMIC FIELDS */
    for (const item of state.lending_home_pickup_list) {
      const pickuListValidations = await validateLendingListReservationForm(
        item
      );
      if (pickuListValidations) {
        pickup_list_validations.push({
          id: item.id,
          validations: pickuListValidations,
        });
      }
    }

    /** VALIDATE DYNAMIC FIELDS */
    for (const item of state.lending_home_delivery_list) {
      const deliveryListValidations = await validateLendingListReservationForm(
        item
      );
      if (deliveryListValidations) {
        delivery_list_validations.push({
          id: item.id,
          validations: deliveryListValidations,
        });
      }
    }

    /** IF FIND SOME ERROR, SET INFO TAG */

    if (validations) {
      validations.list_errors = {};

      if (pickup_list_validations.length) {
        pickup_list_validations.forEach((item) => {
          if (Array.isArray(errosBySection.home)) {
            errosBySection.home.push(item.id);
          } else {
            errosBySection.home = [item.id];
          }
        });
        validations.list_errors.pickup_list_validations =
          pickup_list_validations;
      }

      if (delivery_list_validations.length) {
        delivery_list_validations.forEach((item) => {
          if (Array.isArray(errosBySection.home)) {
            errosBySection.home.push(item.id);
          } else {
            errosBySection.home = [item.id];
          }
        });
        validations.list_errors.delivery_list_validations =
          delivery_list_validations;
      }
      setState({ ...state, validations });
      setSectionErros(errosBySection);
      return;
    } else {
      validations = {
        list_errors: {},
      };
      if (pickup_list_validations.length) {
        pickup_list_validations.forEach((item) => {
          if (Array.isArray(errosBySection.home)) {
            errosBySection.home.push(item.id);
          } else {
            errosBySection.home = [item.id];
          }
        });
        validations.list_errors.pickup_list_validations =
          pickup_list_validations;
      }

      if (delivery_list_validations.length) {
        delivery_list_validations.forEach((item) => {
          if (Array.isArray(errosBySection.home)) {
            errosBySection.home.push(item.id);
          } else {
            errosBySection.home = [item.id];
          }
        });
        validations.list_errors.delivery_list_validations =
          delivery_list_validations;
      }

      if (delivery_list_validations.length || pickup_list_validations.length) {
        setState({ ...state, validations });
        setSectionErros(errosBySection);
        return;
      }
    }

    /** CLEAR VALIDATIONS AND SUBMIT FORM */
    setState({ ...state, validations: {} });
    setSectionErros({});
    onConfirm({ ...state, validations: {} });
  };

  return (
    <div className="container py-3 px-5">
      <Nav
        appearance="subtle"
        activeKey={activeKey}
        onSelect={(value) => setActiveKey(value)}
      >
        <Nav.Item eventKey="1">
          Pagina inicial
          {sectionErros.home && (
            <Tag color="red" className="ms-2">
              {sectionErros?.home?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="2">
          Carga
          {sectionErros.load && (
            <Tag color="red" className="ms-2">
              {sectionErros?.load?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="3">
          Retiro
          {sectionErros.pickup && (
            <Tag color="red" className="ms-2">
              {sectionErros?.pickup?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="4">
          Entrega
          {sectionErros.delivery && (
            <Tag color="red" className="ms-2">
              {sectionErros?.delivery?.length}
            </Tag>
          )}
        </Nav.Item>
      </Nav>

      <div className="py-4">
        {activeKey === "1" && (
          <LendingHomeTextForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
            deleteListItem={_deleteListItem}
          />
        )}

        {activeKey === "2" && (
          <LendingLoadtextForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
          />
        )}

        {activeKey === "3" && (
          <LendingPickupTextForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
          />
        )}

        {activeKey === "4" && (
          <LendingDeliveryTextForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
          />
        )}
      </div>
      <div className="w-100 text-end">
        <Button appearance="primary" onClick={_handleValidations}>
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default LendingtextForm;
