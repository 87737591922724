import { Roles } from "./Roles";
import DashboardIcon from "@rsuite/icons/Dashboard";
import PeoplesMapIcon from "@rsuite/icons/PeoplesMap";
import QrcodeIcon from '@rsuite/icons/Qrcode';
import DocPassIcon from "@rsuite/icons/DocPass";
import UserBadgeIcon from "@rsuite/icons/UserBadge";
import ProjectIcon from "@rsuite/icons/Project";
import { BsCardList } from "react-icons/bs";
import { BsTruck } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineFileWord } from "react-icons/ai";
import { RiAlignBottom } from "react-icons/ri";
import { GoVersions } from "react-icons/go";
import { ReactComponent as Terminal } from "../assets/img/svg/locker.svg";
import { ReactComponent as Box } from "../assets/img/svg/box-line.svg";
import { ReactComponent as LogOut } from "../assets/img/svg/logout.svg";

import Home from "../pages/Home";
import Users from "../pages/Users";
import Companies from "../pages/Companies";
import Terminals from "../pages/Terminals";
import Boxes from "../pages/Boxes";
import Logs from "../pages/Logs";
import DeliveryUsers from "../pages/DeliveryUsers";
import Orders from "../pages/Orders";
import SignIn from "../pages/SignIn";
import SignOut from "../pages/SingOut";
import Error404 from "../pages/Error404";
import Couriers from "../pages/Couriers";
import Mailers from "../pages/Mailers";
import Documentation from "../pages/Documentation";
import UsersDoc from "../pages/UsersDoc";
import ModulesDoc from "../pages/ModulesDoc";
import PostmanDoc from "../pages/PostmanDoc";
import { MdOutlineTextsms } from "react-icons/md";
import SMS from "../pages/SMS";
import Print from "../pages/Print";
import Articles from "../pages/Articles";
import QrRegister from "../pages/QrRegister";
import RegisterQrUser from "../pages/RegisterQrUser";
import Versions from "../pages/Versions";
import DynamicModule from "../pages/DynamicModule";

export const routes: any = [
  {
    component: Home,
    path: "/",
    title: "Dashboard",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN],
    menu: {
      name: "Dashboard",
      url: "/",
      Icon: DashboardIcon,
    },
  },
  {
    component: Users,
    path: "/users",
    title: "Clientes",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN],
    menu: {
      name: "Clientes",
      url: "/users",
      Icon: PeoplesMapIcon,
    },
  },
  {
    component: QrRegister,
    path: "/qr-register",
    title: "Clientes",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN],
    menu: {
      name: "Qr inscripción",
      url: "/qr-register",
      Icon: QrcodeIcon,
    },
  },
  {
    component: Companies,
    path: "/companies",
    title: "Empresas",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN],
    menu: {
      name: "Empresas",
      url: "/companies",
      Icon: ProjectIcon,
    },
  },
  {
    component: Terminals,
    path: "/terminals",
    title: "Terminales",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "Terminales",
      url: "/terminals",
      Icon: Terminal,
    },
  },
  {
    component: Articles,
    path: "/articles",
    title: "Articulos",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "Articulos",
      url: "/articles",
      Icon: RiAlignBottom,
    },
  },
  {
    component: Boxes,
    path: "/boxes",
    title: "Cajas",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER, Roles.PRIVATE_USER],
    menu: {
      name: "Cajas",
      url: "/boxes",
      Icon: Box,
    },
  },
  {
    component: DeliveryUsers,
    path: "/delivery_users",
    title: "Agregar usuario",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN],
    menu: {
      name: "Agregar usuario",
      url: "/delivery_users",
      Icon: UserBadgeIcon,
    },
  },
  {
    component: Orders,
    path: "/orders",
    title: "Órdenes",
    exact: true,
    permissions: [Roles.ADMIN, Roles.COMPANY_ADMIN, Roles.USER, Roles.PRIVATE_USER],
    menu: {
      name: "Órdenes",
      url: "/orders",
      Icon: DocPassIcon,
    },
  },
  {
    component: Versions,
    path: "/versions",
    title: "Versiones",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "Versiones",
      url: "/versions",
      Icon: GoVersions,
    },
  },
  {
    component: Mailers,
    path: "/mailer",
    title: "Template Email",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "Template Email",
      url: "/mailer",
      Icon: HiOutlineMail,
    },
  },
  {
    component: Couriers,
    path: "/company-couriers",
    title: "Couriers",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "Couriers",
      url: "/company-couriers",
      Icon: BsTruck,
    },
  },
  {
    component: SMS,
    path: "/sms",
    title: "SMS",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "SMS",
      url: "/sms",
      Icon: MdOutlineTextsms,
    },
  },
  {
    component: Logs,
    path: "/logs",
    title: "Logs",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: {
      name: "Logs",
      url: "/logs",
      Icon: BsCardList,
    },
  },
  {
    component: Documentation,
    path: "/documentation",
    title: "Documentación",
    exact: false,
    permissions: [Roles.ADMIN, Roles.API],
    menu: {
      name: "Documentación",
      url: "/documentation",
      Icon: AiOutlineFileWord,
    },
  },
  {
    component: UsersDoc,
    path: "/documentation/users",
    title: "Documentación",
    exact: false,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: Print,
    path: "/print/:key/:name",
    title: "Impresión",
    exact: false,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: ModulesDoc,
    path: "/documentation/modules",
    title: "Documentación",
    exact: false,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: PostmanDoc,
    path: "/documentation/postman",
    title: "Postman",
    exact: false,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: SignOut,
    path: "/sign-out",
    title: "Cerrar",
    exact: true,
    permissions: [Roles.ALL],
    menu: {
      name: "Cerrar",
      url: "/sign-out",
      Icon: LogOut,
    },
  },
  {
    component: SignIn,
    path: "/sign-in",
    title: "Entrar",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: RegisterQrUser,
    path: "/register-company-form/:token",
    title: "Registro de usuario",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: Error404,
    path: "*",
    title: "Error404",
    exact: true,
    permissions: [Roles.ALL],
    menu: false,
  },
  {
    component: DynamicModule,
    path: "/terminals/dynamic-module/:id",
    title: "Modulo Dinamico",
    exact: true,
    permissions: [Roles.ADMIN],
    menu: false,
  },
];
