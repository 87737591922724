/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal, Tag, TagPicker, Toggle } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import TerminalService from "../../services/terminal.service";
import { VersionSchema } from "../../validations/version.validations";
import VersionService from "../../services/versions.service";

const FormVersions = (props: any) => {
  const { type, data, handleClose, getData } = props;
  const [state, setState] = useState<any>({
    version: "",
    build: "",
    terminals: [],
    info: {
      terminals: [],
      enviroment_app: "",
      enviroment_api: "",
    },
    auto_update: false,
    description: "",
    validations: {},
  });

  const Terminal = new TerminalService();
  const Version = new VersionService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    _setData();
  }, []);

  const _setData = async () => {
    try {
      dispatch(loading_on());
      const response = await Terminal.selectList();
      if (type === "create") {
        setState({
          ...state,
          terminals: [...response.data],
        });
      } else {
        setState({
          ...state,
          ...data,
          terminals: [...response.data],
        });
      }
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(loading_off());
    }
  };

  const _handleOnChange = ({ target }: any) => {
    let { name, value } = target;
    const info_fields = ["terminals", "enviroment_app", "enviroment_api"];

    if (info_fields.includes(name)) {
      state.info[name] = value;
    } else {
      state[name] = value;
    }

    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (type === "view") {
      handleClose();
      return;
    }

    const validations: any = await validate(VersionSchema, state);

    setState({ ...state, validations });

    if (validations) {
      return;
    }

    dispatch(loading_on());

    const payload: any = {
      version: state.version,
      build: state.build,
      info: {
        terminals: state.info?.terminals,
        enviroment_app: state.info?.enviroment_app,
        enviroment_api: state.info?.enviroment_api,
      },
      auto_update: state.auto_update,
      description: state.description,
    };

    try {
      if (type === "create") {
        await Version.create(payload);
      }

      if (type === "update") {
        payload.id = state.id;
        await Version.update(payload);
      }

      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: `Caja ${
          type === "create" ? "creada" : "actualizada"
        } con éxito...`,
      });

      getData();
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Versión</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.version}</div>
            ) : (
              <Input
                placeholder="Versión"
                value={state.version}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "version", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="version"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Build</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.build}</div>
            ) : (
              <Input
                placeholder="Build"
                value={state.build}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "build", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="build"
              validations={state.validations}
            />
          </div>

          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Descripción</label>
            {type && type === "view" ? (
              <div
                className="size-08 ms-2 capitalize"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {state?.description}
              </div>
            ) : (
              <Input
                as={"textarea"}
                rows={7}
                placeholder="Descripción"
                value={state?.description}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "description", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="description"
              validations={state.validations}
            />
          </div>

          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Terminales</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.info?.terminals.map((id: any) => {
                  const terminalName = state.terminals.find(
                    (item: any) => item.value === id
                  )?.label;

                  return <Tag key={id}>{terminalName}</Tag>;
                })}
              </div>
            ) : (
              <TagPicker
                placeholder="Terminales"
                value={state?.info?.terminals}
                data={state.terminals || []}
                className="w-100"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "terminals", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="terminals"
              validations={state.validations}
            />
          </div>

          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Enviroment App</label>
            {type && type === "view" ? (
              <div
                className="size-08 ms-2 capitalize"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {state?.info?.enviroment_app}
              </div>
            ) : (
              <Input
                as={"textarea"}
                rows={7}
                placeholder="Enviroment App"
                value={state?.info?.enviroment_app}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "enviroment_app", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="enviroment_app"
              validations={state.validations}
            />
          </div>
          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Enviroment API</label>
            {type && type === "view" ? (
              <div
                className="size-08 ms-2 capitalize"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {state?.info?.enviroment_api}
              </div>
            ) : (
              <Input
                as={"textarea"}
                rows={7}
                placeholder="Enviroment API"
                value={state?.info?.enviroment_api}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "enviroment_api", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="enviroment_api"
              validations={state.validations}
            />
          </div>

          <div className="col-12 mb-2 d-flex flex-column">
            <label className="size-08 bold-300 ms-1">
              Actualización Automática
            </label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.auto_update}</div>
            ) : (
              <Toggle
                placeholder="Ancho"
                checked={state.auto_update}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "auto_update", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="auto_update"
              validations={state.validations}
            />
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormVersions;
