/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "rsuite";
import { v4 as uuid } from "uuid";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import BoxService from "../../services/box.service";
import BoxTypeService from "../../services/box_type.service";
import { BoxSchema } from "../../validations/box.validations";
import Box from "./Box";
import { configs, configs_side, models } from "./config";

const FormBoxTerminal = (props: any) => {
  const { data, handleClose, handleConfirm } = props;
  const [state, setState] = useState<any>({
    components: [],
    tabs: 0,
    selectedTab: 0,
    terminal: data,
  });
  const [boxTypes, setBoxTypes] = useState<any>([]);
  const BoxTypes = new BoxTypeService();
  const BoxDataAccessObject = new BoxService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    _loadData();
  }, []);

  const _loadData = async () => {
    const response: any = await BoxTypes.selectList();
    setBoxTypes(response.data);
    if (data.boxes) {
      data.boxes.forEach((box: any) => {
        _handleAddBox(box);
      });
    }
  };

  const _handleAddBox = (data: any = null) => {
    let id: any = uuid();
    let stateComponent: any = {
      desk_door_number: "",
      desk_number: "",
      door_number: "",
      box_type_id: "",
      model_type: 1,
      hexadecimal: "",
      is_enabled: true,
      is_busy: false,
      is_accessible: false,
      validations: {},
      new: true,
    };

    if (data) {
      stateComponent = { ...data, validations: {} };
      id = data.id;
    }
    const component: any = (data: any) => <Box id={id} {...data} />;
    state.components.push({
      Component: component,
      state: stateComponent,
      id: id,
    });

    setState({ ...state });
  };

  const _handleCreatebasicConfigs = () => {
    configs.forEach((box: any) => {
      const data: any = {
        id: uuid(),
        ...box,
      };
      _handleAddBox(data);
    });
    _pushToastMessage({
      type: "success",
      header: "Éxito",
      text: "Cajas del main cargadas con éxito.",
    });
  };

  const _handleCreateSideConfigs = () => {
    configs_side.forEach((box: any) => {
      const data: any = {
        id: uuid(),
        ...box,
      };
      _handleAddBox(data);
    });
    _pushToastMessage({
      type: "success",
      header: "Éxito",
      text: "Cajas del side cargadas con éxito.",
    });
  };

  const _handleDelete = (id: any) => {
    setState((prevState: any) => {
      const components: any = prevState.components.filter(
        (c: any) => c.id !== id
      );
      return { ...prevState, components: components };
    });
  };

  const _handleOnChange = ({ target }: any) => {
    const component: any = state.components.find(
      (c: any) => c.id === target.id
    );

    if (component) {
      component.state[target.name] = target.value;
    }
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();
    let errors: any = 0;
    for (const box of state.components) {
      const validations: any = await validate(BoxSchema, box.state);
      if (validations) {
        errors++;
        box.state = { ...box.state, validations };
      } else {
        box.state = { ...box.state, validations: {} };
      }
    }

    setState({ ...state });

    if (errors > 0) {
      return;
    }

    const payload: any = state.components.map((box: any) => {
      const item: any = {
        id: box.state.id,
        box_type_id: box.state.box_type_id,
        desk_door_number: box.state.desk_door_number,
        desk_number: box.state.desk_number,
        door_number: box.state.door_number,
        model_type: box.state.model_type,
        hexadecimal: box.state.hexadecimal,
        is_enabled: box.state.is_enabled,
        is_busy: box.state.is_busy,
        is_accessible: box.state.is_accessible,
        new: box.state.new,
        terminal_id: state.terminal.id,
        device_id: 1,
      };

      if (item.new) {
        delete item.id;
      }

      return item;
    });

    dispatch(loading_on());
    try {
      await BoxDataAccessObject.syncBoxes({
        boxes: payload,
        terminal_id: state.terminal.id,
      });

      _pushToastMessage({
        type: "success",
        header: "Éxito",
        text: "Cajas sincronizadas correctamente.",
      });
    } catch (e: any) {
      _handleError(e, e.message);
      console.log("LOGGER", e.message);
      dispatch(loading_off());
      return;
    }

    handleConfirm();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 py-4 text-end">
          <Button
            type="button"
            onClick={() => _handleCreatebasicConfigs()}
            appearance="primary"
          >
            {" "}
            Cargar Cajas Main
          </Button>

          <Button
            type="button"
            onClick={() => _handleCreateSideConfigs()}
            appearance="primary"
            className="mx-2"
          >
            {" "}
            Cargar Cajas Side
          </Button>

          <Button
            type="button"
            onClick={() => _handleAddBox()}
            appearance="primary"
            className="mx-2"
          >
            {" "}
            Agregar Caja
          </Button>
        </div>
      </div>

      {state.components && state.components?.length > 0 ? (
        <div className="row">
          <div className="col-12 text-end pe-4 bold-300 my-3">
            {state.components?.length} Cajas cargadas
          </div>
        </div>
      ) : null}

      <form onSubmit={_handleOnSubmit}>
        {state.components && state.components.length === 0 && (
          <div className="w-100 text-center">
            Ninguna caja asociada a este terminal
          </div>
        )}
        {state.components &&
          state.components.map((box: any, index: any) => (
            <div className="w-100 mb-3" key={index}>
              <box.Component
                box_types={boxTypes}
                models={models}
                _handleOnChange={_handleOnChange}
                _handleDelete={_handleDelete}
                state={box.state}
              />
            </div>
          ))}
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormBoxTerminal;
