import validate from "../../helpers/validate";
import {
  DynamicEventListSchema,
  DynamicEventsSchema,
} from "../../validations/dynamic.events.validations";
import {
  DynamicLendingListReservationSchema,
  DynamicLendingReservationSchema,
} from "../../validations/dynamic.lending.validations";
import { DynamicPickupWithoutReservationValidationsSchema } from "../../validations/dynamic.pickup-without-reservation.validations";
import { DynamicPickupValidationsSchema } from "../../validations/dynamic.pickup.validations";
import { DynamicResidentialValidationsSchema } from "../../validations/dynamic.residential.validations";

const validateResidentialForm = async (values: any) => {
  const validations = await validate(
    DynamicResidentialValidationsSchema,
    values
  );
  return validations;
};

const validatePickupForm = async (values: any) => {
  const validations = await validate(DynamicPickupValidationsSchema, values);
  return validations;
};

const validatePickupWithoutReservationForm = async (values: any) => {
  const validations = await validate(
    DynamicPickupWithoutReservationValidationsSchema,
    values
  );
  return validations;
};

const validateLendingReservationForm = async (values: any) => {
  const validations = await validate(DynamicLendingReservationSchema, values);
  return validations;
};

const validateLendingListReservationForm = async (values: any) => {
  const validations = await validate(
    DynamicLendingListReservationSchema,
    values
  );
  return validations;
};
const validateEventForm = async (values: any) => {
  const validations = await validate(DynamicEventsSchema, values);
  return validations;
};

const validateEventListForm = async (values: any) => {
  const validations = await validate(DynamicEventListSchema, values);
  return validations;
};

export {
  validateResidentialForm,
  validatePickupForm,
  validatePickupWithoutReservationForm,
  validateLendingReservationForm,
  validateLendingListReservationForm,
  validateEventForm,
  validateEventListForm,
};
