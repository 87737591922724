import { _runHomeDocumentation } from "../../../documentation/driver/home.documentation";
import { MdNotStarted } from "react-icons/md";
import { FaPrayingHands } from "react-icons/fa";
import { _runEventsDocumentation } from "../../../documentation/driver/events-module.documentation";

export const getAvailableHelpPages = () => {
  const disableDriverPermitions = window.localStorage.getItem(
    "disable-driver-permitions"
  );
  return [
    {
      page: "/",
      exact: true,
      popQuantity: 0,
      autoStart: true,
      helper: _runHomeDocumentation,
      helpers: [
        {
          label:
            !disableDriverPermitions || disableDriverPermitions === "0"
              ? "Deshabilitar auto inicio"
              : "Habilitar auto inicio",
          Icon: MdNotStarted,
          show: true,
          eventKey: 1,
        },
        {
          label: "Bienvendida",
          Icon: FaPrayingHands,
          show: true,
          eventKey: 2,
        },
      ],
    },
    {
      page: "/terminals/dynamic-module",
      exact: false,
      popQuantity: 1,
      autoStart: false,
      helper: _runEventsDocumentation,
      helpers: [
        {
          label:
            !disableDriverPermitions || disableDriverPermitions === "0"
              ? "Deshabilitar auto inicio"
              : "Habilitar auto inicio",
          Icon: MdNotStarted,
          show: true,
          eventKey: 1,
        },
        {
          label:
            "Configurar módulo de eventos",
          Icon: MdNotStarted,
          show: true,
          eventKey: 2,
        },
      ],
    },
  ];
};
