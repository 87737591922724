/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Uploader } from "rsuite";
import { toBase64 } from "../../helpers/images";

const UploadImage = (props: any) => {
  const { onError, onChange, clearData } = props;
  const [state, setState] = useState<any>({ FileList: [] });

  useEffect(() => {
    if (clearData) {
      setState({ ...state, fileList: [] });
    }
  }, [clearData]);

  const _handleOnChange = async (e: any) => {
    try {
      let imageData: any = null;
      if (e.length > 0) {
        imageData = await toBase64(e[e.length - 1].blobFile);
        setState({
          ...state,
          image: imageData,
          fileList: [e[e.length - 1]],
        });
      } else {
        setState({ ...state, image: null, fileList: [] });
      }
      onChange(imageData);
    } catch (e: any) {
      console.log(e);
      onError(e);
    }
  };
  return (
    <Uploader
      listType="text"
      onChange={_handleOnChange}
      fileList={state.fileList}
      onError={(e: any) => console.log("ERROR LOG", e.message)}
      autoUpload={false}
      action=""
      accept=".jpg,.png,.bmp,.jpeg,.webp"
    >
      <button type="button">Imagem</button>
    </Uploader>
  );
};

export default UploadImage;
