/* eslint-disable react-hooks/exhaustive-deps */
import PeoplesIcon from "@rsuite/icons/Peoples";
import MenuIcon from "@rsuite/icons/Menu";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Whisper } from "rsuite";
import { MoreMenu } from "../../../components/MoreMenu";
import { _mountSpeakerMenu } from "../../../pages/DeliveryUsers/Header/speaker";
import { getAvailableHelpPages } from "./helperAvailablePages";
import { handleSpeakerHome } from "./speakerFunctionsHome";
import { MdOutlineHelp } from "react-icons/md";
import { handleSpeakerEvents } from "./speakerFunctionsDynamicModule";

const Header = () => {
  const { session } = useSelector((state: any) => state);
  const [driverInstance, setDriverInstance] = useState<any>(null);
  const [helperMenu, setHelperMenu] = useState<any>({
    menu: [],
    show: false,
    page: "",
  });
  const [observer, setObserver] = useState<any>(1);

  useEffect(() => {
    handleHelp();
  }, [observer]);

  const handleHelp = () => {
    const helperData = getAvailableHelpPages().find((item: any) => {
      if (item.exact) {
        return item.page === window.location.pathname;
      }

      if (!item.exact) {
        const splited = window.location.pathname.split("/");
        for (let i = 0; i < item.popQuantity; i++) {
          splited.pop();
        }

        return item.page === splited.join("/");
      }
      return false;
    });

    if (driverInstance) {
      if (driverInstance.isActive()) {
        driverInstance.destroy();
      }
    }

    if (helperData) {
      setHelperMenu({
        menu: helperData.helpers,
        show: true,
        page: helperData.page,
      });

      if (!helperData.autoStart) return;

      const instance = helperData?.helper({ forceStart: false });

      if (!instance) return;

      setDriverInstance(instance);
      setTimeout(() => {
        instance.drive();
      }, 100);
    }
  };

  const _handleOnSelectSpeaker = (data: any) => {
    setObserver(observer + 1);
    switch (data.page) {
      case "/":
        handleSpeakerHome(data);
        break;
      case "/terminals/dynamic-module":
        handleSpeakerEvents(data);
        break;
      default:
        break;
    }
  };

  const Actions = ({ props }: any) => {
    const { handleOnMenuClick, menu, page } = props;
    const _speaker: any = _mountSpeakerMenu({
      _handleOnSelect: handleOnMenuClick,
      menuData: menu,
      page,
    });

    return (
      <Whisper
        placement="auto"
        trigger="click"
        speaker={(whisper_payload: any, ref: any) =>
          MoreMenu(whisper_payload, ref, _speaker)
        }
      >
        <button className="btn" style={{ color: "rgb(110, 117, 159)" }}>
          <MdOutlineHelp className="me-3 people-icon" id="driver-help-icon" />
        </button>
      </Whisper>
    );
  };

  return (
    <div className="default-template-header">
      <div className="d-block d-sm-none">
        <MenuIcon className="me-3 people-icon" />
      </div>
      <div className="d-flex align-items-center" id="driver-logo-home">
        {helperMenu.show && (
          <Actions
            props={{
              handleOnMenuClick: _handleOnSelectSpeaker,
              menu: helperMenu.menu,
              page: helperMenu.page,
            }}
          />
        )}
        <PeoplesIcon className="me-3 people-icon" />
        <div className="d-flex flex-column justify-content-center">
          <span className="size-09">{session.profile?.name}</span>
          <span className="size-08">
            {session.profile?.roles?.length > 0
              ? session.profile?.roles[0]?.label
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
