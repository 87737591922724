import { Input, Toggle } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";
import { FaPlusCircle } from "react-icons/fa";

const EventsMyLockerForm = ({
  onChange,
  data,
  addNewListItem,
  deleteListItem,
}: any) => {
  return (
    <div className="row">
      <div className="col-12 py-2 bold-300 mt-3">PIN PAD</div>
      <hr className="w-75" />
      <div className="col-12 py-2 driver-mybox-pin">
        <Toggle
          defaultChecked={data?.events_my_locker_pinpad_required}
          onChange={(value) =>
            onChange({
              target: { name: "events_my_locker_pinpad_required", value },
            })
          }
        />
        <span className="ms-3 size-09">
          Desea que el usuario ingrese un PIN para retirar sus pertenencias?
        </span>
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Instruciones</div>
      <hr className="w-75" />
      <div className="col-12 mb-2 driver-mybox-text-1">
        <label className="size-08 bold-300 ms-1">Titulo</label>
        <Input
          placeholder="¿Como guardo o saco cosas de mi casillero?"
          value={data?.events_my_locker_list_title}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_my_locker_list_title", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_my_locker_list_title"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 driver-mybox-instructions">
        <div className="w-100">
          <label className="size-08 bold-300 ms-1">
            Lista de pasos para solicitar un préstamo
          </label>
          <button className="btn">
            <FaPlusCircle
              className="size-12"
              onClick={() => addNewListItem({ list: "events_my_locker_list" })}
            />
          </button>
          <ErrorValidation
            className="text-end size-08"
            name="events_my_locker_list"
            validations={data?.validations}
          />
        </div>
        {data?.events_my_locker_list?.map((item: any, index: number) => (
          <div
            className="row py-2 border rounded my-3"
            key={`events_my_locker_list-${index}`}
          >
            <div className="col-12 mb-3">
              <label className="size-08 bold-300 ms-1">Titulo</label>
              <Input
                placeholder="titulo"
                value={item?.title}
                onChange={(value: any) =>
                  onChange({
                    target: {
                      name: `events_my_locker_list.${item.id}.title`,
                      value,
                    },
                  })
                }
              />
              <ErrorValidation
                className="text-end size-08"
                name="title"
                validations={
                  data?.validations?.list_errors?.my_locker_list_validations?.find(
                    (error: any) => error.id === item.id
                  )?.validations
                }
              />
            </div>
            <div className="col-12">
              <label className="size-08 bold-300 ms-1">Descripción</label>
              <Input
                placeholder="Descripción"
                value={item?.description}
                onChange={(value: any) =>
                  onChange({
                    target: {
                      name: `events_my_locker_list.${item.id}.description`,
                      value,
                    },
                  })
                }
              />
            </div>
            <div className="col-12 text-end mt-2">
              <button
                className="btn border-bottom border-3 p-0"
                onClick={() =>
                  deleteListItem({
                    list: "events_my_locker_list",
                    id: item.id,
                  })
                }
              >
                Eliminar
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Paso 2 - Confirmación</div>
      <hr className="w-75" />
      <div className="col-12 mb-2 driver-mybox-text-2">
        <label className="size-08 bold-300 ms-1">Titulo</label>
        <Input
          placeholder="Guarda tus pertenencias y cierra el casillero!"
          value={data?.events_my_locker_confirmation_title}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_my_locker_confirmation_title", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_my_locker_confirmation_title"
          validations={data?.validations}
        />
      </div>
      <div className="col-12 mb-2 driver-mybox-text-3">
        <label className="size-08 bold-300 ms-1">Subtitulo</label>
        <Input
          placeholder="Puedes guardar y sacar cosas las veces que quieras presionando en Mi
          Casillero"
          value={data?.events_my_locker_confirmation_subtitle}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_my_locker_confirmation_subtitle", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_my_locker_confirmation_subtitle"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default EventsMyLockerForm;
