import api from "./api";
import store from "../redux/store";

class ArticleService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/articles${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  selectList = async () => {
    try {
      const response = await api.get(`/articles/list/select-list`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  create = async (payload: any) => {
    try {
      const response = await api.post("/articles", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (payload: any) => {
    try {
      const response = await api.put(`/articles/update/${payload.id}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  delete = async (id: any) => {
    try {
      const response = await api.delete(`/articles/delete/${id}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  deleteList = async (list: any) => {
    try {
      const response: any = await api.post(`/articles/delete-list`, {
        ids: list,
      });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  createMassiveArticles = async (payload: any) => {
    try {
      const response = await api.post("/articles/massive", payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
  
}

export default ArticleService;
