/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loading_off, loading_on } from "../../redux/actions/loader";
import { useSelector } from "react-redux";

const Print = () => {
  const [state, setState] = useState<any>({
    image: "",
    permissionsGranted: false,
  });
  const params = useParams();
  const User = new UserService();
  const dispatch: any = useDispatch();
  const loader = useSelector((state: any) => state.loader);

  useEffect(() => {
    _generateQrCode();
  }, []);

  const _generateQrCode = async () => {
    try {
      dispatch(loading_on());
      const response = await User.generateQr(params.key);
      setState({
        ...state,
        image: response.data?.image,
        permissionsGranted: true,
      });
      dispatch(loading_off());
    } catch (e: any) {
      dispatch(loading_off());
    }
  };

  if (loader.is_loading) return;

  if (!state.permissionsGranted) {
    return (
      <div
        className="d-flex w-100 justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center border rounded p-3">
          <span className="bold">
            No tienes permisos para ver este contenido
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="d-flex w-100 justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center border rounded p-3">
        <img src={state.image} alt="QR Code" />
        <span className="bold">{params.key}</span>
        <span className="bold">{params.name}</span>
      </div>
    </div>
  );
};

export default Print;
