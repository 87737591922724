import { Input, SelectPicker } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";
import { keyboardTypesResidencialSchema } from "./Schema";

const ResidentialDeliveryTextForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      {/* DELIVERY STEP 1 */}
      <div className="col-12 py-2 bold-300 mt-3">
        Entrega - Paso 1 - Numero departamento
      </div>
      <hr />
      <div className="col-6 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Digita el número de Departamento/Casa"
          value={data?.delivery_step_one_text_one}
          onChange={(value: any) =>
            onChange({ target: { name: "delivery_step_one_text_one", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="delivery_step_one_text_one"
          validations={data?.validations}
        />
      </div>

      <div className="col-6 mb-2">
        <label className="size-08 bold-300 ms-1">Tipo del teclado</label>
        <SelectPicker
          data={keyboardTypesResidencialSchema}
          className="w-100"
          placeholder="Selecciona el tipo del teclado"
          onChange={(value) =>
            onChange({
              target: { name: "delivery_step_one_keyboard_type", value },
            })
          }
          value={data?.delivery_step_one_keyboard_type}
        />
        <ErrorValidation
          className="text-end size-08"
          name="delivery_step_one_keyboard_type"
          validations={data?.validations}
        />
      </div>

      {/* DELIVERY STEP 2 */}
      <div className="col-12 py-2 bold-300 mt-3">
        Entrega - Paso 2 - Residentes
      </div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Selecciona el residente"
          value={data?.delivery_step_two_text_one}
          onChange={(value: any) =>
            onChange({ target: { name: "delivery_step_two_text_one", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="delivery_step_two_text_one"
          validations={data?.validations}
        />
      </div>

      {/* DELIVERY STEP 3 */}
      <div className="col-12 py-2 bold-300 mt-3">Entrega - Paso 3 - Cajas</div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Selecciona el tamaño de caja que necesitas"
          value={data?.delivery_step_three_text_one}
          onChange={(value: any) =>
            onChange({
              target: { name: "delivery_step_three_text_one", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="delivery_step_three_text_one"
          validations={data?.validations}
        />
      </div>

      {/* DELIVERY STEP 4 */}
      <div className="col-12 py-2 bold-300 mt-3">
        Entrega - Paso 4 - Confirmación
      </div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Si el tamaño de la caja no te sirve, selecciona la opción para cambiar caja a continuación."
          value={data?.delivery_step_four_text_one}
          onChange={(value: any) =>
            onChange({
              target: { name: "delivery_step_four_text_one", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="delivery_step_four_text_one"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default ResidentialDeliveryTextForm;
