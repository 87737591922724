import { SelectPicker } from "rsuite";

const SelectModuleForm = ({ onChange, data, value, className = "" }: any) => {
  return (
    <div className="row">
      <div className="col-12">
      <div className="size-08 bold-300">Modulo base</div>
        <SelectPicker
          value={value}
          className={ `w-100 ${className}` }
          placeholder="Selecciona el modulo base"
          data={data}
          onChange={onChange}
          valueKey="id"
          labelKey="name"
        />
      </div>
    </div>
  );
};

export default SelectModuleForm;
