import api from "./api";
import store from "../redux/store";

class LendingConfigsService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  create = async (payload: any) => {
    try {
      const response: any = await api.post(`/lending-configs/create`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  update = async (payload: any) => {
    try {
      const response: any = await api.put(`/lending-configs/update/${payload.id}`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default LendingConfigsService;
