import * as yup from "yup";

export const MassiveOrderSchema = yup.object().shape({
  nombre: yup.string().nullable().required("Este campo es requerido"),
  email: yup
    .string()
    .email("Formato de email inválido")
    .nullable()
    .required("Este campo es requerido"),
  rut: yup.string().nullable().required("Este campo es requerido"),
  telefono: yup.string().nullable().required("Este campo es requerido"),
  nombre_item: yup.string().nullable().required("Este campo es requerido"),
});

export const MassiveOrderResidentSchema = yup.object().shape({
  nombre: yup.string().nullable().required("Este campo es requerido"),
  email: yup
    .string()
    .email("Formato de email inválido")
    .nullable()
    .required("Este campo es requerido"),
  direccion: yup.string().nullable().required("Este campo es requerido"),
  departamento: yup.string().nullable().required("Este campo es requerido"),
  telefono: yup.string().nullable().required("Este campo es requerido"),
  rut: yup.string().nullable().required("Este campo es requerido"),
  pin: yup.number().nullable().typeError("Este campo solo acepta números"),
});
export const MassiveLoadEventSchema = yup.object().shape({
  nombre: yup.string().nullable().required("Este campo es requerido"),
  email: yup
    .string()
    .email("Formato de email inválido")
    .nullable()
    .required("Este campo es requerido"),
  direccion: yup.string().nullable().required("Este campo es requerido"),
  departamento: yup.string().nullable().required("Este campo es requerido"),
  telefono: yup.string().nullable().required("Este campo es requerido"),
  pin: yup.number().nullable().typeError("Este campo solo acepta números"),
});

export type MassiveOrderType = yup.InferType<typeof MassiveOrderSchema>;
export type MassiveLoadEventType = yup.InferType<typeof MassiveLoadEventSchema>;
export type MassiveOrderResidentType = yup.InferType<
  typeof MassiveOrderResidentSchema
>;
