import { Input, Toggle } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";

const EventsDeliveryForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      {/* DELIVERY STEP 1 */}

      <div className="col-12 py-2 bold-300 mt-3">PIN</div>
      <hr className="w-75" />
      <div className="col-12 py-2 driver-delivery-pin">
        <Toggle
          defaultChecked={data?.events_delivery_pinpad_required}
          onChange={(value) =>
            onChange({
              target: { name: "events_delivery_pinpad_required", value },
            })
          }
        />
        <span className="ms-3 size-09">
          Desea que el usuario ingrese un PIN para guardar sus pertenencias?
        </span>
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Selección Caja</div>
      <hr className="w-75" />
      <div className="col-12 py-2 driver-delivery-box-required">
        <Toggle
          defaultChecked={data?.events_delivery_boxes_required}
          onChange={(value) =>
            onChange({
              target: { name: "events_delivery_boxes_required", value },
            })
          }
        />
        <span className="ms-3 size-09">
          Desea que el usuario seleccione un tamaño de caja?
        </span>
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Flujo sin selección de caja activa - Confirmación
      </div>
      <hr />

      <div className="col-12 mb-2 driver-delivery-text-1">
        <label className="size-08 bold-300 ms-1">Texto No hay Cajas Disponibles</label>
        <Input
          placeholder="No hay Cajas Disponibles"
          value={data?.events_delivery_single_fail_step_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_delivery_single_fail_step_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_delivery_single_fail_step_text"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 mb-2 driver-delivery-text-2">
        <label className="size-08 bold-300 ms-1">Texto Confirmación</label>
        <Input
          placeholder="Guarda tus pertenencias y cierra la puerta."
          value={data?.events_delivery_single_success_step_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_delivery_single_success_step_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_delivery_single_success_step_text"
          validations={data?.validations}
        />
      </div>

      {/* DELIVERY STEP 2 */}
      <div className="col-12 py-2 bold-300 mt-3">
        Flujo con selección de caja activa - Paso 1 - Cajas
      </div>
      <hr />
      <div className="col-12 mb-2 driver-delivery-text-3">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Selecciona el tamaño de caja que necesitas"
          value={data?.events_delivery_step1_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_delivery_step1_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_delivery_step1_text"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Guardar - Paso 2 - Confirmar
      </div>
      <hr />
      <div className="col-12 mb-2 driver-delivery-text-4">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Si el tamaño de la caja no te sirve, selecciona la opción para cambiar caja a continuación."
          value={data?.events_delivery_step2_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_delivery_step2_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_delivery_step2_text"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default EventsDeliveryForm;
