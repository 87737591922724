import { createAction } from "redux-actions";

export const DISPATCH_EVENT: any = createAction("DISPATCH_EVENT");
export const SAVE_STATE: any = createAction("SAVE_STATE");

export const dispatch_event = (payload: any) => {
  return async (dispatch: any) => {
    await dispatch(DISPATCH_EVENT(payload));
    await dispatch(SAVE_STATE());
    return Promise.resolve();
  };
};

