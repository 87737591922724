import * as yup from "yup";

export const DeliveryUserSchema = yup.object().shape({
  name: yup.string().nullable().required("Este campo es requerido"),
  phone: yup.string().nullable().required("Este campo es requerido"),
  address: yup.string().nullable().required("Este campo es requerido"),
  rut: yup.string().nullable().required("Este campo es requerido"),
  email: yup.string().email().nullable().required("Este campo es requerido"),
  apartment: yup.string().nullable().required("Este campo es requerido"),
  company_id: yup.string().nullable().required("Este campo es requerido"),
});

export type DeliveryUserType = yup.InferType<typeof DeliveryUserSchema>;

export const DeliveryUserPinSchema = yup.object().shape({
  name: yup.string().nullable().required("Este campo es requerido"),
  phone: yup.string().nullable().required("Este campo es requerido"),
  address: yup.string().nullable().required("Este campo es requerido"),
  rut: yup.string().nullable().required("Este campo es requerido"),
  email: yup.string().email().nullable().required("Este campo es requerido"),
  apartment: yup.string().nullable().required("Este campo es requerido"),
  company_id: yup.string().nullable().required("Este campo es requerido"),
  pin: yup.string().min(4, "Pin debe contener 4 números").nullable().typeError("Este campo es requerido").required("Este campo es requerido"),
});

export type DeliveryUserPinType = yup.InferType<typeof DeliveryUserPinSchema>;

export const PendingDeliveryUserSchema = yup.object().shape({
  name: yup.string().nullable().required("Este campo es requerido"),
  phone: yup.string().nullable().required("Este campo es requerido"),
  address: yup.string().nullable().required("Este campo es requerido"),
  email: yup.string().email('informa un correo electrónico valido').nullable().required("Este campo es requerido"),
  apartment: yup.string().nullable().required("Este campo es requerido"),
});

export type PendingDeliveryUserType = yup.InferType<typeof PendingDeliveryUserSchema>;

export const CheckDeliveryUserSchema = yup.object().shape({
  name: yup.string().min(3, "El nombre debe tener al menos 3 caracteres").nullable().required("Este campo es requerido"),
  email: yup.string().email().nullable().required("Este campo es requerido"),
});

export type CheckDeliveryUserType = yup.InferType<typeof CheckDeliveryUserSchema>;