export const pickupStateSchema = {
  pickup_home_btn_load_title: "Carga",
  pickup_home_btn_load_description:
    "Haga click aquí para hacer una carga de un pedido.",
  pickup_home_btn_pickup_title: "Retiro",
  pickup_home_btn_pickup_description:
    "Haga click aquí para hacer un retiro de un pedido.",
  pickup_load_step_one_text_one: "Introduce o escanea el código QR",
  pickup_load_step_two_text_one: "Abre la(s) caja(s) y guarda tu paquete",
  pickup_pickup_step_one_text_one: "Introduce o escanea el código QR",
  pickup_pickup_step_two_text_one: "Abre la(s) caja(s) y retira tu paquete",
  pickup_pickup_step_three_text_one: "Transacción completada con éxito",
};

export const SchemaSections: any = {
  home: [
    "pickup_home_btn_load_title",
    "pickup_home_btn_load_description",
    "pickup_home_btn_pickup_title",
    "pickup_home_btn_pickup_description",
  ],
  delivery: ["pickup_load_step_one_text_one", "pickup_load_step_two_text_one"],
  pickup: [
    "pickup_pickup_step_one_text_one",
    "pickup_pickup_step_two_text_one",
    "pickup_pickup_step_three_text_one",
  ],
};
