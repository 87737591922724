import { driver } from "driver.js";
import { dispatch_event } from "../../redux/actions/messenger";
import store from "../../redux/store";

export const _runEventsDocumentation = ({
  forceStart,
}: {
  forceStart: boolean;
}) => {
  const dispatch: any = store.dispatch;

  const disableDriverPermitions = window.localStorage.getItem(
    "disable-driver-permitions"
  );

  if (forceStart === false && disableDriverPermitions === "1") return false;

  if (process.env.REACT_APP_ENVIRONMENT === "production") return false;

  const driverInstance = driver();

  driverInstance.setConfig({
    doneBtnText: "Listo",
    nextBtnText: "Siguiente",
    prevBtnText: "Anterior",
    showProgress: false,
    // showButtons: ["next"],
    steps: [
      {
        element: "#driver-logo-home",
        popover: {
          title: "Bienvenidas",
          description:
            "Bienvenido al asistente de configuración del módulo de eventos. A continuación explicaré cada paso y cada funcionalidad de este módulo.",
        },
        onHighlightStarted: () => {
          dispatch(
            dispatch_event({
              action: "select-nav-event",
              attributes: { key: "1" },
            })
          );
        },
      },
      {
        element: ".driver-select-module",
        popover: {
          title: "Asistente de configuración",
          description:
            "El primero paso es seleccionar el modulo eventos. Haz clic en siguiente y lo seleccionaré por ti.",
          onNextClick: () => {
            dispatch(
              dispatch_event({ action: "select-module-event", attributes: {} })
            );
            setTimeout(() => {
              driverInstance.moveNext();
            }, 100);
          },
        },
      },
      {
        element: ".driver-select-template",
        popover: {
          title: "Asistente de configuración",
          description:
            "Los templates son configuraciones guardadas que te ayudarán a configurar más rápidamente los terminales del módulo de eventos. Por ahora no usaremos ninguno. Haremos todos los pasos para que puedas entender cada parte del módulo.",
        },
      },
      {
        element: ".driver-logo-company",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí tenemos la opción de agregar un logo. La imagen cargada aquí aparecerá en lugar del logotipo de Elock en la terminal.",
        },
      },
      {
        element: ".driver-map-terminal-image",
        popover: {
          title: "Asistente de configuración",
          description:
            "La siguiente configuración es agregar un mapa. El mapa ayuda al usuario a ubicarse en el evento, y saber dónde se encuentran las terminales. Los formatos aceptados son png, jpg y webp. Preferiblemente una imagen leviana y con una resolución máxima de 1024x768.",
        },
      },
      {
        element: ".driver-map-terminal-video",
        popover: {
          title: "Asistente de configuración",
          description:
            "También es posible agregar un video. Este vídeo debe subirse a YouTube. Después de subir el video a YouTube, simplemente copie el código del video e ingréselo aquí. El código es la última parte de la URL. https://www.youtube.com/watch?v=XXXXXXXXXX",
        },
      },
      {
        element: ".driver-btn-terminal-map",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puedes cambiar el texto del botón con la imagen.",
        },
      },
      {
        element: ".driver-btn-terminal-video",
        popover: {
          title: "Asistente de configuración",
          description:
            "Y aquí puedes cambiar el texto del botón que muestra el vídeo.",
        },
      },
      {
        element: ".driver-home-btn-1",
        popover: {
          title: "Asistente de configuración",
          description:
            "El proximo paso son los textos de los botones de la pagina inicial. Aquí puedes cambiar el texto de los botones de la página de inicio de la terminal.",
        },
      },
      {
        element: ".driver-instructions",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puedes agregar instrucciones para los usuarios. Estas instrucciones aparecerán en la pagina inicial del terminal. En el lado izquierdo están las instrucciones de entrega y en el lado derecho están las instrucciones de retiro.",
        },
      },
      {
        element: ".driver-nav-2",
        popover: {
          title: "Asistente de configuración",
          description:
            "Vayamos a la página 2. Aquí encontraremos las configuraciónes para guardar algo en la terminal. Haga clic en Siguiente para que yo puedar cargar la página 2.",
          onNextClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "2" },
              })
            );
            setTimeout(() => {
              driverInstance.moveNext();
            }, 100);
          },
          onPrevClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "1" },
              })
            );
            setTimeout(() => {
              driverInstance.movePrevious();
            }, 100);
          },
        },
      },
      {
        element: ".driver-delivery-pin",
        popover: {
          title: "Asistente de configuración",
          description:
            "La primera opción es donde configuras si la persona debe ingresar un PIN para guardar sus pertenencias.",
        },
      },
      {
        element: ".driver-delivery-box-required",
        popover: {
          title: "Asistente de configuración",
          description:
            "A continuación, podrás configurar si la persona debe elegir el tamaño de la caja.",
        },
      },
      {
        element: ".driver-delivery-text-1",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí configuras el texto que se mostrará si no hay casillas disponibles.",
        },
      },
      {
        element: ".driver-delivery-text-2",
        popover: {
          title: "Asistente de configuración",
          description:
            "Y aquí, el texto que se muestra cuando finaliza el flujo.",
        },
      },
      {
        element: ".driver-delivery-text-3",
        popover: {
          title: "Asistente de configuración",
          description:
            "Si la selección de cajas está activa, este texto se mostrará al momento de seleccionar una caja",
        },
      },
      {
        element: ".driver-delivery-text-4",
        popover: {
          title: "Asistente de configuración",
          description:
            "Este será el texto que se mostrará al final del flujo. En este caso, si la caja no es adecuada para el cliente, éste podrá seleccionar otro tamaño de caja.",
          onNextClick: () => {
            driverInstance.moveNext();
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          },
        },
      },
      {
        element: ".driver-nav-3",
        popover: {
          title: "Asistente de configuración",
          description:
            "Vayamos a la página 3. Aquí encontraremos las configuraciónes para retirar algo de la terminal. Haga clic en Siguiente para que yo puedar cargar la página 3.",
          onNextClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "3" },
              })
            );
            setTimeout(() => {
              driverInstance.moveNext();
            }, 100);
          },
          onPrevClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "2" },
              })
            );
            setTimeout(() => {
              driverInstance.movePrevious();
            }, 100);
          },
        },
      },
      {
        element: ".driver-pickup-pin",
        popover: {
          title: "Asistente de configuración",
          description:
            "La primera opción es donde configuras si la persona debe ingresar un PIN para retirar sus pertenencias.",
        },
      },
      {
        element: ".driver-pickup-text-1",
        popover: {
          title: "Asistente de configuración",
          description:
            "A continuación puede configurar el mensaje que se muestra después de que se abre la puerta.",
          onNextClick: () => {
            driverInstance.moveNext();
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          },
        },
      },
      {
        element: ".driver-nav-4",
        popover: {
          title: "Asistente de configuración",
          description:
            "Vayamos a la página 4. Aquí encontraremos las configuraciónes para retirar algo de la terminal. Haga clic en Siguiente para que yo puedar cargar la página 4.",
          onNextClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "4" },
              })
            );
            setTimeout(() => {
              driverInstance.moveNext();
            }, 100);
          },
          onPrevClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "3" },
              })
            );
            setTimeout(() => {
              driverInstance.movePrevious();
            }, 100);
          },
        },
      },
      {
        element: ".driver-mybox-pin",
        popover: {
          title: "Asistente de configuración",
          description:
            "La primera opción es donde configuras si la persona debe ingresar un PIN para retirar y/o guardar sus pertenencias.",
        },
      },
      {
        element: ".driver-mybox-text-1",
        popover: {
          title: "Asistente de configuración",
          description:
            "Después de configurar si se debe ingresar el pin, es hora de configurar el título de la página. Este será el titulo de las instrucciones de la pagina 'Mis Casilleros'.",
        },
      },
      {
        element: ".driver-mybox-instructions",
        popover: {
          title: "Asistente de configuración",
          description:
            "Luego de definir el título, llega el momento de configurar los pasos que debe realizar el usuario para retirar o guardar sus pertenencias. Aquí podrás informar cada paso necesario para la entrega y recogida de paquetes.",
        },
      },
      {
        element: ".driver-mybox-text-2",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí defines el primer texto de la pagina de confirmación.",
        },
      },
      {
        element: ".driver-mybox-text-3",
        popover: {
          title: "Asistente de configuración",
          description:
            "Y luego un subtitulo para dejar claro el mensaje al usuario.",
        },
      },
      {
        element: ".driver-nav-5",
        popover: {
          title: "Asistente de configuración",
          description:
            "Vayamos a la página 5. Aquí encontraremos el PIN. Haga clic en Siguiente para que yo puedar cargar la página 5.",
          onNextClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "5" },
              })
            );
            setTimeout(() => {
              driverInstance.moveNext();
            }, 100);
          },
          onPrevClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "4" },
              })
            );
            setTimeout(() => {
              driverInstance.movePrevious();
            }, 100);
          },
        },
      },
      {
        element: ".driver-pin-text-1",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí es donde configuras el texto que se mostrará como título en la página con el teclado para ingresar el PIN.",
        },
      },
      {
        element: ".driver-nav-6",
        popover: {
          title: "Asistente de configuración",
          description:
            "Vayamos a la página 6. Aquí encontraremos las configuraciones de ayuda al cliente. Haga clic en Siguiente para que yo puedar cargar la página 6.",
          onNextClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "6" },
              })
            );
            setTimeout(() => {
              driverInstance.moveNext();
            }, 100);
          },
          onPrevClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "5" },
              })
            );
            setTimeout(() => {
              driverInstance.movePrevious();
            }, 100);
          },
        },
      },
      {
        element: ".driver-help-text-1",
        popover: {
          title: "Asistente de configuración",
          description:
            "El primer campo es donde configuras a qué correos electrónicos llegarán los mensajes de solicitud de ayuda. Después de ingresar cada correo electrónico, debe presionar la barra espaciadora o enter para confirmar.",
        },
      },
      {
        element: ".driver-help-text-2",
        popover: {
          title: "Asistente de configuración",
          description:
            "El siguiente campo son los teléfonos a donde se envían los mensajes SMS pidiendo ayuda. El formato de los telefonos debe ser 56xxxxxxxxx. Después de ingresar cada numero de telefono, debes presionar la barra espaciadora o enter para confirmar.",
        },
      },
      {
        element: ".driver-help-text-3",
        popover: {
          title: "Asistente de configuración",
          description:
            "El siguiente campo es el titulo de la pagina de ayuda. Este texto se mostrará en la página de ayuda, junto al teclado numerico, para el usuario ingresar su numero de telefono.",
        },
      },
      {
        element: ".driver-help-text-4",
        popover: {
          title: "Asistente de configuración",
          description:
            "Este es el texto que se muestra cuando el mensaje de ayuda se envía correctamente.",
        },
      },
      {
        element: ".driver-help-text-5",
        popover: {
          title: "Asistente de configuración",
          description:
            "Y aquí está el texto con el mensaje que se mostrará en caso de que no sea posible enviar el mensaje.",
          onNextClick: () => {
            driverInstance.moveNext();
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          },
        },
      },
      {
        element: ".driver-nav-7",
        popover: {
          title: "Asistente de configuración",
          description:
            "Vayamos a la página 7. Aquí encontraremos las configuraciones de colores. Haga clic en Siguiente para que yo puedar cargar la página 7.",
          onNextClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "7" },
              })
            );
            setTimeout(() => {
              driverInstance.moveNext();
            }, 100);
          },
          onPrevClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "6" },
              })
            );
            setTimeout(() => {
              driverInstance.movePrevious();
            }, 100);
          },
        },
      },
      {
        element: ".driver-colors-1",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puedes cambiar el color de fondo de la aplicación.",
        },
      },
      {
        element: ".driver-colors-2",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puedes cambiar el color principal de la aplicación. Este color se aplica al color principal del texto de la aplicación.",
        },
      },
      {
        element: ".driver-colors-3",
        popover: {
          title: "Asistente de configuración",
          description:
            "Este es el color secundario. El color de contraste con el color principal.",
        },
      },
      {
        element: ".driver-colors-4",
        popover: {
          title: "Asistente de configuración",
          description:
            "Este color se aplica al color de fondo de los botones de la aplicación.",
        },
      },
      {
        element: ".driver-colors-5",
        popover: {
          title: "Asistente de configuración",
          description:
            "Y aquí puedes configurar el color del texto de los botones.",
        },
      },
      {
        element: ".driver-colors-6",
        popover: {
          title: "Asistente de configuración",
          description:
            "Para hacer el diseño más dinámico, se puede modificar el color de algunos botones específicos. El botón de ayuda es uno de ellos. Aquí puede configurar el color de la función del botón de ayuda.",
        },
      },
      {
        element: ".driver-colors-7",
        popover: {
          title: "Asistente de configuración",
          description: "Y aquí está el color del texto del botón de ayuda.",
        },
      },
      {
        element: ".driver-colors-8",
        popover: {
          title: "Asistente de configuración",
          description:
            "En la mayoría de las páginas, hay un recuadro de fondo que proporciona contraste entre el fondo y los componentes de la pantalla. Aquí puedes configurar el color de este recuadro. Presta atención a un punto importante. El componente de color acepta colores con opacidad. Puedes aumentar o disminuir la opacidad de un color.",
        },
      },
      {
        element: ".driver-colors-9",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puede configurar el color de fondo del componente que representa la caja en la aplicación.",
        },
      },
      {
        element: ".driver-colors-10",
        popover: {
          title: "Asistente de configuración",
          description:
            "Dentro del componente 'caja', hay un círculo con un ícono de caja dentro. Aquí puedes configurar el color del círculo.",
        },
      },
      {
        element: ".driver-colors-11",
        popover: {
          title: "Asistente de configuración",
          description: "Aquí el color del icono de la caja.",
        },
      },
      {
        element: ".driver-colors-12",
        popover: {
          title: "Asistente de configuración",
          description: "Y aquí el color del texto del componente caja.",
        },
      },
      {
        element: ".driver-colors-13",
        popover: {
          title: "Asistente de configuración",
          description:
            "También es posible realizar algunos cambios en el componente PIN. El primero es el color de fondo donde aparecen los números. Elige un color que contraste con el color de los números.",
        },
      },
      {
        element: ".driver-colors-14",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puedes configurar el color del texto del componente PIN.",
        },
      },
      {
        element: ".driver-colors-15",
        popover: {
          title: "Asistente de configuración",
          description:
            "El botón OK está resaltado para que sea más fácil de ver. Aquí puedes configurar el color de fondo del Botón Ok.",
        },
      },
      {
        element: ".driver-colors-16",
        popover: {
          title: "Asistente de configuración",
          description: "Y aquí el color del texto.",
        },
      },
      {
        element: ".driver-nav-8",
        popover: {
          title: "Asistente de configuración",
          description:
            "Vayamos a la página 8. Aquí encontraremos las configuraciones de la empresa. Los cambios realizados en el área de empresa se aplican a todos los terminales de esta empresa. Haga clic en Siguiente para que yo puedar cargar la página 8.",
          onNextClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "8" },
              })
            );
            setTimeout(() => {
              driverInstance.moveNext();
            }, 100);
          },
          onPrevClick: () => {
            dispatch(
              dispatch_event({
                action: "select-nav-event",
                attributes: { key: "7" },
              })
            );
            setTimeout(() => {
              driverInstance.movePrevious();
            }, 100);
          },
        },
      },
      {
        element: ".driver-company-map",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puedes agregar un mapa. El mapa ayuda al usuario a ubicarse en el evento, y saber dónde se encuentran las terminales. Los formatos aceptados son png, jpg y webp. Preferiblemente una imagen leviana y con una resolución máxima de 1024x768.",
        },
      },
      {
        element: ".driver-company-video",
        popover: {
          title: "Asistente de configuración",
          description:
            "También es posible agregar un video. Este vídeo debe subirse a YouTube. Después de subir el video a YouTube, simplemente copie el código del video e ingréselo aquí. El código es la última parte de la URL. https://www.youtube.com/watch?v=XXXXXXXXXX",
        },
      },
      {
        element: ".driver-btn-company-map",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puedes cambiar el texto del botón con la imagen.",
        },
      },
      {
        element: ".driver-btn-company-video",
        popover: {
          title: "Asistente de configuración",
          description:
            "Y aquí puedes cambiar el texto del botón que muestra el vídeo.",
        },
      },
      {
        element: ".driver-company-max-reservations",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí podrás habilitar o deshabilitar el número de reservas simultáneas por empresa. Recuerda que esto se refiere al número de reservas por empresa y no por terminal. Cada terminal podrá tener una única reserva por persona.",
        },
      },
      {
        element: ".driver-company-quantity-reservations",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puedes definir el número de reservas que cada persona puede realizar en los terminales de una empresa.",
        },
      },
      {
        element: ".driver-company-max-reservations-text",
        popover: {
          title: "Asistente de configuración",
          description:
            "Este mensaje se mostrará cuando no sea posible realizar mas reservas y el modulo de informaciones sobre reservas en otra terminal esté deshabilitado.",
        },
      },
      {
        element: ".driver-company-info",
        popover: {
          title: "Asistente de configuración",
          description:
            "Aquí puede habilitar o deshabilitar el módulo de información de reservas. Si una persona intenta guardar algo en un terminal, y el sistema detecta que esa persona ya tiene algo guardado en otro terminal, se mostrará un mensaje informando de la existencia de otra reserva.",
        },
      },
      {
        element: ".driver-company-info-text-1",
        popover: {
          title: "Asistente de configuración",
          description:
            "Este mensaje se mostrará cuando haya una reserva en otro terminal y no sea posible realizar más reservas.",
        },
      },
      {
        element: ".driver-company-info-text-2",
        popover: {
          title: "Asistente de configuración",
          description:
            "Este mensaje se mostrará cuando haya una reserva en otro terminal y sea posible realizar otra reservas en este terminal.",
        },
      },
      {
        element: ".driver-save-as-template",
        popover: {
          title: "Asistente de configuración",
          description:
            "Al final del flujo, es posible guardar la configuración como plantilla. Entonces, al crear otro terminal con la misma configuración, simplemente seleccione la plantilla y se cargarán todas las configuraciones.",
        },
      },
      {
        element: ".driver-save-configs",
        popover: {
          title: "Asistente de configuración",
          description:
            "Con todo listo, ahora solo guarda la configuración. Si algo está mal configurado, el sistema te avisará en qué sesión se encuentra el error. ¡Muchas gracias!",
        },
      },
    ],
  });

  return driverInstance;
};
