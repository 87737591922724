/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Nav, SelectPicker, Tag, Toggle } from "rsuite";
import EventsHomeTextForm from "./EventsHomeTextForm";
import { v4 } from "uuid";
import EventsMyLockerForm from "./EventsMyLockersForm";
import EventsDeliveryForm from "./EventsDeliveryForm";
import EventsPickupForm from "./EventsPickupForm";
import {
  validateEventForm,
  validateEventListForm,
} from "../../../../pages/DynamicModule/validations.helper";
import { SchemaSections } from "./Schema";
import EventsPinPadForm from "./EventsPinPadForm";
import EventsHelpForm from "./EventsHelpForm";
import EventsColorsForm from "./EventsColorsForm";
import ModuleService from "../../../../services/module.service";
import { useDispatch } from "react-redux";
import { loading_off, loading_on } from "../../../../redux/actions/loader";
import EventsCompanyForm from "./EventsCompanyForm";
import { useSelector } from "react-redux";

const EventsTextForm = ({ module, onConfirm }: any) => {
  const [activeKey, setActiveKey] = useState("1");
  const [state, setState] = useState<any>({});
  const [sectionErros, setSectionErros] = useState<any>({});
  const [saveConfig, setSaveConfig] = useState(false);
  const [templates, setTemplates] = useState<any>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(2);
  const dispatch: any = useDispatch();
  const { messenger } = useSelector((state: any) => state);

  useEffect(() => {
    setState({
      ...module,
    });
    _getTemplates();
  }, [module]);

  useEffect(() => {
    switch (messenger?.action) {
      case "select-nav-event":
        setActiveKey(messenger?.attributes?.key);
        break;
      default:
        break;
    }
  }, [messenger]);

  const _getTemplates = async () => {
    try {
      dispatch(loading_on());
      const response: any = await new ModuleService().getTemplates(
        module.module_id
      );
      setTemplates(response.data);
      setSelectedTemplate(null);
      dispatch(loading_off());
    } catch (e: any) {
      dispatch(loading_off());
    }
  };

  const _handleOnChangeState = (e: any) => {
    const { name, value } = e.target;
    const hasPoint = name.includes(".");
    if (hasPoint) {
      const [listname, id, key] = name.split(".");
      const item = state[listname].find((item: any) => item.id === id);
      item[key] = value;
      setState({
        ...state,
        [listname]: [...state[listname]],
      });
      return;
    }

    setState({ ...state, [name]: value });
  };

  const _addNewListItem = ({ list }: any) => {
    if (state[list] === undefined) {
      setState({
        ...state,
        [list]: [{ id: v4(), title: "", description: "" }],
      });
      return;
    }

    setState({
      ...state,
      [list]: [...state[list], { id: v4(), title: "", description: "" }],
    });
  };

  const _deleteListItem = ({ list, id }: any) => {
    const newList = state[list].filter((item: any) => item.id !== id);
    setState({ ...state, [list]: newList });
  };

  const _handleValidations = async () => {
    /* scroll to top */
    window.scrollTo(0, 0);

    /** VALIDATE BASIC FORM FIELDS */
    let validations: any = await validateEventForm(state);

    let errosBySection: any = {};

    if (validations) {
      setState({ ...state, validations });
      const errors = Object.keys(validations);
      const sections: string[] = Object.keys(SchemaSections);
      errosBySection = sections.reduce((acc: any, section: any) => {
        const errorsInSection = errors.filter((error) =>
          SchemaSections[section].includes(error)
        );
        if (errorsInSection.length) {
          acc[section] = errorsInSection;
        }
        return acc;
      }, {});
    }

    const pickup_list_validations = [];
    const delivery_list_validations = [];
    const my_locker_list_validations = [];

    /** VALIDATE DYNAMIC FIELDS */
    for (const item of state.events_home_pickup_list) {
      const pickuListValidations = await validateEventListForm(item);
      if (pickuListValidations) {
        pickup_list_validations.push({
          id: item.id,
          validations: pickuListValidations,
        });
      }
    }

    /** VALIDATE DYNAMIC FIELDS */
    for (const item of state.events_home_delivery_list) {
      const deliveryListValidations = await validateEventListForm(item);
      if (deliveryListValidations) {
        delivery_list_validations.push({
          id: item.id,
          validations: deliveryListValidations,
        });
      }
    }

    /** VALIDATE DYNAMIC FIELDS */
    for (const item of state.events_my_locker_list) {
      const myLockerListValidations = await validateEventListForm(item);
      if (myLockerListValidations) {
        my_locker_list_validations.push({
          id: item.id,
          validations: myLockerListValidations,
        });
      }
    }

    /** IF FIND SOME ERROR, SET INFO TAG */
    if (validations) {
      validations.list_errors = {};

      if (pickup_list_validations.length) {
        pickup_list_validations.forEach((item) => {
          if (Array.isArray(errosBySection.home)) {
            errosBySection.home.push(item.id);
          } else {
            errosBySection.home = [item.id];
          }
        });
        validations.list_errors.pickup_list_validations =
          pickup_list_validations;
      }

      if (delivery_list_validations.length) {
        delivery_list_validations.forEach((item) => {
          if (Array.isArray(errosBySection.home)) {
            errosBySection.home.push(item.id);
          } else {
            errosBySection.home = [item.id];
          }
        });
        validations.list_errors.delivery_list_validations =
          delivery_list_validations;
      }

      if (my_locker_list_validations.length) {
        my_locker_list_validations.forEach((item) => {
          if (Array.isArray(errosBySection.my_locker)) {
            errosBySection.my_locker.push(item.id);
          } else {
            errosBySection.my_locker = [item.id];
          }
        });
        validations.list_errors.my_locker_list_validations =
          my_locker_list_validations;
      }
      setState({ ...state, validations });
      setSectionErros(errosBySection);
      return;
    } else {
      validations = {
        list_errors: {},
      };
      if (pickup_list_validations.length) {
        pickup_list_validations.forEach((item) => {
          if (Array.isArray(errosBySection.home)) {
            errosBySection.home.push(item.id);
          } else {
            errosBySection.home = [item.id];
          }
        });
        validations.list_errors.pickup_list_validations =
          pickup_list_validations;
      }

      if (delivery_list_validations.length) {
        delivery_list_validations.forEach((item) => {
          if (Array.isArray(errosBySection.home)) {
            errosBySection.home.push(item.id);
          } else {
            errosBySection.home = [item.id];
          }
        });
        validations.list_errors.delivery_list_validations =
          delivery_list_validations;
      }

      if (delivery_list_validations.length || pickup_list_validations.length) {
        setState({ ...state, validations });
        setSectionErros(errosBySection);
        return;
      }
    }

    /** CLEAR VALIDATIONS AND SUBMIT FORM */
    setState({ ...state, validations: {} });
    setSectionErros({});
    setSelectedTemplate(null);

    onConfirm({
      ...state,
      save_config_as_template: saveConfig,
      validations: {},
    });
  };

  const _handleSelectTemplate = (value: any) => {
    try {
      const template = templates.find((item: any) => item.id === value);
      if (!template) return;
      setSelectedTemplate(template?.id);
      setState({
        ...template.data,
        terminal_id: module.terminal_id,
        id: module?.id || null,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container py-3 px-5">
      <div className="row mb-4">
        <div className="col-12 driver-select-template">
          <div className="size-08 bold-300">Templates</div>
          <SelectPicker
            value={selectedTemplate}
            className="w-100"
            data={templates}
            onChange={_handleSelectTemplate}
            placeholder="Selecciona un template"
            valueKey="id"
            labelKey="name"
          />
        </div>
      </div>

      <Nav
        appearance="subtle"
        activeKey={activeKey}
        onSelect={(value) => setActiveKey(value)}
      >
        <Nav.Item eventKey="1" className="driver-nav-1">
          Pagina inicial
          {sectionErros.home && (
            <Tag color="red" className="ms-2">
              {sectionErros?.home?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="2" className="driver-nav-2">
          Guardar
          {sectionErros.delivery && (
            <Tag color="red" className="ms-2">
              {sectionErros?.delivery?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="3" className="driver-nav-3">
          Retirar
          {sectionErros.pickup && (
            <Tag color="red" className="ms-2">
              {sectionErros?.pickup?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="4" className="driver-nav-4">
          Mis casilleros
          {sectionErros.my_locker && (
            <Tag color="red" className="ms-2">
              {sectionErros?.my_locker?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="5" className="driver-nav-5">
          PIN
          {sectionErros.pinpad && (
            <Tag color="red" className="ms-2">
              {sectionErros?.pinpad?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="6" className="driver-nav-6">
          Ayuda
          {sectionErros.help && (
            <Tag color="red" className="ms-2">
              {sectionErros?.help?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="7" className="driver-nav-7">
          Colores
          {sectionErros.colors && (
            <Tag color="red" className="ms-2">
              {sectionErros?.colors?.length}
            </Tag>
          )}
        </Nav.Item>
        <Nav.Item eventKey="8" className="driver-nav-8">
          Empresa
          {sectionErros.company && (
            <Tag color="red" className="ms-2">
              {sectionErros?.company?.length}
            </Tag>
          )}
        </Nav.Item>
      </Nav>

      <div className="py-4">
        {activeKey === "1" && (
          <EventsHomeTextForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
            deleteListItem={_deleteListItem}
          />
        )}

        {activeKey === "2" && (
          <EventsDeliveryForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
          />
        )}

        {activeKey === "3" && (
          <EventsPickupForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
          />
        )}

        {activeKey === "4" && (
          <EventsMyLockerForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
            deleteListItem={_deleteListItem}
          />
        )}

        {activeKey === "5" && (
          <EventsPinPadForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
          />
        )}

        {activeKey === "6" && (
          <EventsHelpForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
          />
        )}

        {activeKey === "7" && (
          <EventsColorsForm
            onChange={_handleOnChangeState}
            data={state}
            addNewListItem={_addNewListItem}
          />
        )}

        {activeKey === "8" && (
          <EventsCompanyForm onChange={_handleOnChangeState} data={state} />
        )}
      </div>

      <div className="row">
        <div className="col-12 d-flex align-items-center driver-save-as-template">
          <Toggle
            defaultChecked={saveConfig}
            onChange={() => setSaveConfig(!saveConfig)}
          />
          <span className="ms-2 size-09">Guardar configuraciones</span>
        </div>
      </div>
      <div className="w-100 text-end driver-save-configs">
        <Button appearance="primary" onClick={_handleValidations}>
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default EventsTextForm;
