import { Input, Toggle } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";
import WhisperComponent from "../../../../components/WhisperComponent";
import { BsInfoCircle } from "react-icons/bs";
import UploadImage from "../../../../components/UploadImage";
import { useState } from "react";

const EventsCompanyForm = ({ onChange, data }: any) => {
  const [clearData, setClearData] = useState(0);
  const _handleClearImage = (data: any) => {
    setClearData(clearData + 1);
    onChange(data);
  };

  return (
    <div className="row">
      <div className="col-12 py-2 bold-300 mt-3">Mapa area del evento</div>
      <hr />

      {/** MAP IMAGE */}
      <div className="col-6 mb-2 driver-company-map">
        <label className="size-08 bold-300 ms-1">Selecciona una imagen</label>
        <UploadImage
          onChange={(value: any) =>
            onChange({
              target: {
                name: "events_company_map_img_upload",
                value,
              },
            })
          }
          onError={console.log}
          clearData={clearData}
        />

        {data?.events_company_map_img_upload && (
          <div
            className="position-relative p-3 mt-4 rounded"
            style={{ maxWidth: "250px", border: "1px dotted #333" }}
          >
            <button
              className="btn position-absolute bold"
              style={{ top: 0, right: 0 }}
              onClick={() =>
                _handleClearImage({
                  target: {
                    name: "events_company_map_img_upload",
                    value: "",
                  },
                })
              }
            >
              x
            </button>
            <img
              src={data?.events_company_map_img_upload}
              alt="logo"
              className="img-fluid"
              style={{ maxWidth: "200px" }}
            />
          </div>
        )}
      </div>

      {/** MAP VIDEO */}
      <div className="col-12 mb-3 driver-company-video">
        <label className="size-08 bold-300 ms-1">
          <WhisperComponent
            capitalizeOff
            text="El código del video, es el código que se encuentra en la URL del video de Youtube. Ej: https://www.youtube.com/watch?v=XXXXXXXXXXX"
          >
            Código del video del mapa del evento ( Youtube )
            <BsInfoCircle className="ms-3 size-12" style={{ color: "blue" }} />
          </WhisperComponent>
        </label>
        <Input
          placeholder="Informa el codigo del video del mapa del evento ( Youtube )"
          value={data?.events_company_map_video_url}
          onChange={(value: any) =>
            onChange({
              target: {
                name: "events_company_map_video_url",
                value,
              },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_company_map_video_url"
          validations={data?.validations}
        />
      </div>

      <div className="col-6 mb-2 driver-btn-company-map">
        <label className="size-08 bold-300 ms-1">Texto botón imagen</label>
        <Input
          placeholder="Texto botón imagen"
          value={data?.events_company_map_btn_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_company_map_btn_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_company_map_btn_text"
          validations={data?.validations}
        />
      </div>

      <div className="col-6 mb-2 driver-btn-company-video">
        <label className="size-08 bold-300 ms-1">Texto botón video</label>
        <Input
          placeholder="Texto botón video"
          value={data?.events_company_video_btn_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_company_video_btn_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_company_video_btn_text"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Cantidad de reservas por empresa
      </div>
      <hr className="w-75" />
      <div className="col-12 py-2 mb-2 driver-company-max-reservations">
        <Toggle
          defaultChecked={data?.events_company_max_reservations_enabled}
          onChange={(value) =>
            onChange({
              target: {
                name: "events_company_max_reservations_enabled",
                value,
              },
            })
          }
        />
        <span className="ms-3 size-09">
          Limitar cantidad de reservas para una empresa
        </span>
      </div>

      <div className="col-12 mb-3 driver-company-quantity-reservations">
        <label className="size-08 bold-300 ms-1">
          Cantidad de reservas por empresa
        </label>
        <Input
          placeholder="Informa la cantidad de reservas maxima por empresa"
          value={data?.events_company_max_reservations}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_company_max_reservations", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_company_max_reservations"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 mb-3 driver-company-max-reservations-text">
        <label className="size-08 bold-300 ms-1">
          <WhisperComponent
            capitalizeOff
            text="Este mensaje se mostrará cuando no sea posible realizar mas reservas y el modulo de informaciones sobre reservas en otra terminal esté deshabilitado."
          >
            Mensaje de limite de reservas
            <BsInfoCircle className="ms-3 size-12" style={{ color: "blue" }} />
          </WhisperComponent>
        </label>
        <Input
          placeholder="Se ha alcanzado el límite de reservas simultáneas para su usuario."
          value={data?.events_company_max_reservations_message}
          onChange={(value: any) =>
            onChange({
              target: {
                name: "events_company_max_reservations_message",
                value,
              },
            })
          }
          as={"textarea"}
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_company_max_reservations_message"
          validations={data?.validations}
        />
      </div>

      {/** INFORMATIONS */}

      <div className="col-12 py-2 bold-300 mt-3">
        Información sobre reservas en otra terminal
      </div>
      <hr className="w-75" />
      <div className="col-12 py-2 mb-2 driver-company-info">
        <Toggle
          defaultChecked={data?.events_company_info_enabled}
          onChange={(value) =>
            onChange({
              target: { name: "events_company_info_enabled", value },
            })
          }
        />

        <span className="ms-3 size-09">
          Informar si hay alguna reserva activa en otra terminal
        </span>
      </div>

      <div className="col-12 mb-3 driver-company-info-text-1">
        <label className="size-08 bold-300 ms-1">
          <WhisperComponent
            capitalizeOff
            text="Este mensaje se mostrará cuando haya una reserva en otro terminal y no sea posible realizar más reservas."
          >
            Mensaje de reserva encontrado en otra terminal - Caso 1
            <BsInfoCircle className="ms-3 size-12" style={{ color: "blue" }} />
          </WhisperComponent>
        </label>
        <Input
          placeholder="Sus pertenencias se encuentran en otro locker. A continuación puede ver cual el locker y como llegar hasta el."
          value={data?.events_company_max_reservations_message_one}
          onChange={(value: any) =>
            onChange({
              target: {
                name: "events_company_max_reservations_message_one",
                value,
              },
            })
          }
          as={"textarea"}
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_company_max_reservations_message_one"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 mb-3 driver-company-info-text-2">
        <label className="size-08 bold-300 ms-1">
          <WhisperComponent
            capitalizeOff
            text="Este mensaje se mostrará cuando haya una reserva en otro terminal y sea posible realizar otra reservas en este terminal."
          >
            Mensaje de reserva encontrado en otra terminal - Caso 2
            <BsInfoCircle className="ms-3 size-12" style={{ color: "blue" }} />
          </WhisperComponent>
        </label>
        <Input
          placeholder="Sus pertenencias se encuentran en otro locker. A continuación puede ver cual el locker y como llegar hasta el. Haga click en guardar para guardar algo en este locker."
          value={data?.events_company_max_reservations_message_two}
          onChange={(value: any) =>
            onChange({
              target: {
                name: "events_company_max_reservations_message_two",
                value,
              },
            })
          }
          as={"textarea"}
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_company_max_reservations_message_two"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default EventsCompanyForm;
