/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Input,
  MaskedInput,
  Modal,
  SelectPicker,
  Toggle,
} from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { phoneMask } from "../../helpers/masks";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import DeliveryUserService from "../../services/delivery_user.service";
import {
  DeliveryUserPinSchema,
  DeliveryUserSchema,
} from "../../validations/delivery.users.validations";

const FormDeliveryUser = (props: any) => {
  const { type, data, handleClose, handleConfirm } = props;
  const [companies, setCompanies] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [state, setState] = useState<any>({
    name: "",
    phone: "",
    address: "",
    rut: "",
    email: "",
    apartment: "",
    company_id: "",
    pin: "",
    dynamic_field: "",
    has_disability: false,
  });
  const Company = new CompanyService();
  const DeliveryUser = new DeliveryUserService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (type !== "view") {
      _getData();
    }
    if (type !== "create") {
      setState({
        ...state,
        ...data,
        pin: data?.delivery_user_config?.info?.pin || "",
      });
    }
  }, []);

  const _getData = async () => {
    dispatch(loading_on());
    try {
      const response: any = await Company.selectList();
      setCompanies(response.data);
      if (type === "update") {
        const company = response.data.find(
          (company: any) => company.value === data.company_id
        );
        setSelectedCompany(company);
      }
      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(loading_off());
    }
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;

    if (name === "pin") {
      if (value.length > 4) {
        return;
      }
      state[name] = value.replace(/\D/g, "");
    } else {
      state[name] = value;
    }

    if (name === "company_id") {
      const company = companies.find((company: any) => company.value === value);
      setSelectedCompany(company);
    }

    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (type === "view") {
      handleClose();
      return;
    }

    let validations: any = false;

    if (selectedCompany?.company_type?.name === "eventos") {
      validations = await validate(DeliveryUserPinSchema, state);
    } else {
      validations = await validate(DeliveryUserSchema, state);
    }

    if (state.phone.indexOf("_") !== -1) {
      validations = validations || {};
      validations.phone = ["Formato inválido"];
    }

    setState({ ...state, validations });

    if (validations !== false) {
      return;
    }

    const payload: any = {
      name: state.name,
      phone: state.phone,
      address: state.address,
      rut: state.rut,
      email: state.email,
      apartment: state.apartment,
      company_id: state.company_id,
      has_disability: state.has_disability,
      dynamic_field: state.dynamic_field || null,
      pin: state.pin || null,
    };

    dispatch(loading_on());

    try {
      if (type === "create") {
        await DeliveryUser.create(payload);
        _pushToastMessage({
          type: "success",
          header: "Éxito",
          text: "Usuario creado con éxito",
        });
      }

      if (type === "update") {
        payload.id = state.id;
        await DeliveryUser.update(payload);
        _pushToastMessage({
          type: "success",
          header: "Éxito",
          text: "Usuario actualizado con éxito",
        });
      }
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(loading_off());
      return;
    }

    handleConfirm();
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nombre</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.name}</div>
            ) : (
              <Input
                placeholder="Nombre"
                value={state.name}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "name", value } })
                }
              />
            )}
            <ErrorValidation
              name="name"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Telefóno</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.phone}</div>
            ) : (
              <MaskedInput
                mask={phoneMask}
                value={state.phone}
                guide={true}
                showMask={true}
                keepCharPositions={true}
                placeholder={""}
                placeholderChar={"_"}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "phone", value } })
                }
              />
            )}
            <ErrorValidation
              name="phone"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Dirección</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.address}</div>
            ) : (
              <Input
                placeholder="Dirección"
                value={state.address}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "address", value } })
                }
              />
            )}
            <ErrorValidation
              name="address"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Departamento</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.apartment}</div>
            ) : (
              <Input
                placeholder="Departamento"
                value={state.apartment}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "apartment", value } })
                }
              />
            )}
            <ErrorValidation
              name="apartment"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Rut</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.rut}</div>
            ) : (
              <Input
                placeholder="Rut"
                value={state.rut}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "rut", value } })
                }
              />
            )}
            <ErrorValidation
              name="rut"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Email</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.email}</div>
            ) : (
              <Input
                placeholder="Email"
                value={state.email}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "email", value } })
                }
              />
            )}
            <ErrorValidation
              name="email"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>

          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Empresa</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.company?.name}
              </div>
            ) : (
              <SelectPicker
                className="w-100"
                value={state.company_id}
                locale={{
                  placeholder: "Selecciona una empresa",
                  searchPlaceholder: "Buscar",
                  emptyMessage: "Ninguna empresa encontrada",
                  noResultsText: "Ninguna empresa encontrada",
                }}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: {
                      name: "company_id",
                      value,
                    },
                  })
                }
                data={companies}
              />
            )}
            <ErrorValidation
              name="company_id"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>
          {type && type === "view" ? (
            <div className="col-6">
              <label className="size-08 bold-300 ms-1">Pin</label>
              <div className="size-08 ms-2 capitalize">{state.pin}</div>
            </div>
          ) : null}

          {state.company?.is_dynamic_field_enabled ? (
            <div className="col-6 mb-2">
              <label className="size-08 bold-300 ms-1">
                {state.company?.dynamic_field_label}
              </label>
              {type && type === "view" ? (
                <div className="size-08 ms-2 capitalize">
                  {state.dynamic_field}
                </div>
              ) : (
                <Input
                  placeholder={state.company?.dynamic_field_placeholder}
                  value={state.dynamic_field}
                  as={
                    state?.company?.dynamic_field_type === "textarea"
                      ? "textarea"
                      : "input"
                  }
                  onChange={(value: any) =>
                    _handleOnChange({
                      target: { name: "dynamic_field", value },
                    })
                  }
                />
              )}
              <ErrorValidation
                name="dynamic_field"
                className="size-08 text-end"
                validations={state.validations}
              />
            </div>
          ) : null}

          {selectedCompany?.company_type?.name === "eventos" && (
            <div className="col-6 mb-2">
              <label className="size-08 bold-300 ms-1">Pin</label>
              {type && type === "view" ? (
                <div className="size-08 ms-2 capitalize">{state.pin}</div>
              ) : (
                <Input
                  placeholder="Pin"
                  value={state.pin}
                  onChange={(value: any) =>
                    _handleOnChange({ target: { name: "pin", value } })
                  }
                />
              )}
              <ErrorValidation
                name="pin"
                className="size-08 text-end"
                validations={state.validations}
              />
            </div>
          )}

          <div className="col-6 mb-2 d-flex flex-column">
            <label className="size-08 bold-300 ms-1">Inclusión</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">
                {state.has_disability ? "Si" : "No"}
              </div>
            ) : (
              <Toggle
                checked={state.has_disability}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "has_disability", value } })
                }
              />
            )}
            <ErrorValidation
              name="has_disability"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>

          {state.delivery_user_config?.info?.qrcode && (
            <div className="col-6 mb-2 d-flex flex-column">
              <label className="size-08 bold-300 ms-1">Código QR Elock</label>
              <div className="size-08 ms-2">
                <a
                  href={`print/${state.delivery_user_config?.info?.qrcode}/${state.name}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {state.delivery_user_config?.info?.qrcode}
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormDeliveryUser;
