import { _runEventsDocumentation } from "../../../documentation/driver/events-module.documentation";

export const handleSpeakerEvents = (data: any) => {
  switch (data.option) {
    case 1:
      const disableDriverPermitions = window.localStorage.getItem(
        "disable-driver-permitions"
      );
      window.localStorage.setItem(
        "disable-driver-permitions",
        disableDriverPermitions === "1" ? "0" : "1"
      );
      break;
    case 2:
      const instance: any = _runEventsDocumentation({ forceStart: true });
      instance.drive();
      break;
    default:
      break;
  }
};
