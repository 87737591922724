/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, DatePicker, Input, Modal } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import { LendingConfigsSchema } from "../../validations/lending_configs.validatios";
import LendingConfigsService from "../../services/lending_configs.service";
import { FcInfo } from "react-icons/fc";
import WhisperComponent from "../../components/WhisperComponent";
import moment from "moment";

const FormLendingConfigs = (props: any) => {
  const { type, data, handleClose, open } = props;

  const [state, setState] = useState<any>({
    first_mark_duration: "",
    first_mark_message: "",
    second_mark_duration: "",
    second_mark_message: "",
    third_mark_duration: "",
    company_id: data?.id,
    clear_reservation_at: moment().toDate(),
    validations: {},
    hasPreviousConfigs: false,
  });
  const dispatch: any = useDispatch();

  const LendingConfigs = new LendingConfigsService();

  useEffect(() => {
    if (open) {
      if (data && data.lending_configs) {
        const clear_reservation_at = data.lending_configs?.clear_reservation_at;
        const [hours, minutes, seconds] = clear_reservation_at.split(':');
        setState({
          ...state,
          ...data.lending_configs,
          clear_reservation_at: moment().hour(hours).minute(minutes).second(seconds).toDate(),
          hasPreviousConfigs: true,
        });
      }
    }
  }, [open]);

  const _handleOnChange = ({ target }: any) => {
    console.log(target)
    if (
      target.name === "first_mark_duration" ||
      target.name === "second_mark_duration" ||
      target.name === "third_mark_duration"
    ) {
      target.value = target.value.replace(/\D/g, "");
    }
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (type === "view") {
      handleClose();
      return;
    }

    let validations: any = await validate(LendingConfigsSchema, state);

    if (
      parseInt(state.first_mark_duration) > parseInt(state.second_mark_duration)
    ) {
      if (validations) {
        validations = {
          ...validations,
          second_mark_duration: [
            "La duracion final del prestamo debe ser mayor o igual a la duración del prestamo",
          ],
        };
      } else {
        validations = {
          second_mark_duration: [
            "La duracion final del prestamo debe ser mayor o igual a la duración del prestamo",
          ],
        };
      }
    }

    setState({ ...state, validations });

    if (validations !== false) {
      return;
    }

    dispatch(loading_on());

    if (!state.hasPreviousConfigs) {
      try {
        const payload = {...state};
        payload.clear_reservation_at = moment(payload.clear_reservation_at).format('HH:mm:ss');
        await LendingConfigs.create(payload);
        _pushToastMessage({
          type: "success",
          header: "Succeso",
          text: "Configuraciones de prestamo creadas con éxito",
        });
        handleClose(true);
      } catch (e: any) {
        _handleError(e, "No fue posible crear las configuraciones de prestamo");
        if (e.response?.status === 422) {
          setState({ ...state, validations: e.response.data });
        }
        dispatch(loading_off());
        return;
      }
    }

    if (state.hasPreviousConfigs) {
      try {
        const payload = {...state};
        payload.clear_reservation_at = moment(payload.clear_reservation_at).format('HH:mm:ss');
        await LendingConfigs.update(payload);
        _pushToastMessage({
          type: "success",
          header: "Succeso",
          text: "Configuraciones de prestamo actualizadas con éxito",
        });
        handleClose(true);
      } catch (e: any) {
        _handleError(
          e,
          "No fue posible actualizar las configuraciones de prestamo"
        );
        if (e.response?.status === 422) {
          setState({ ...state, validations: e.response.data });
        }
        dispatch(loading_off());
        return;
      }
    }
    setState({ ...state, validations: {} });
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          {/**
           * 1. Duracion de la reserva para el usuario hacer el retiro. Default 15 minutos
           */}
          <div className="col-12 mb-2">
            <div className="d-flex align-items-center py-2">
              <label className="size-08 bold-300 ms-1 me-3">
                Duracion de la reserva ( en minutos )
              </label>
              <WhisperComponent
                width="auto"
                text="Este campo define el tiempo, en minutos, que tiene el usuario para retirar el artículo del terminal"
              >
                <FcInfo />
              </WhisperComponent>
            </div>

            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.third_mark_duration}
              </div>
            ) : (
              <Input
                placeholder="Duracion de la reserva de retiro"
                value={state.third_mark_duration}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "third_mark_duration", value },
                  })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation
                validations={state.validations}
                name="third_mark_duration"
              />
            </div>
          </div>

          <div className="w-100">
            <hr className="my-4" />
          </div>

          {/**
           * 2. Duracion del prestamo. Default 240 minutos
           */}
          <div className="col-12 mb-2">
            <div className="d-flex align-items-center py-2">
              <label className="size-08 bold-300 ms-1 me-3">
                Duración del prestamo ( en minutos )
              </label>
              <WhisperComponent
                width="auto"
                text="Este campo define la duración del prestamo en minutos. Cuando quedan 60 minutos para finalizar la reserva se enviará un email informando que queda 1 hora para finalizar la reserva."
              >
                <FcInfo />
              </WhisperComponent>
            </div>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.first_mark_duration}</div>
            ) : (
              <Input
                placeholder="Duracion del prestamo"
                value={state.first_mark_duration}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "first_mark_duration", value },
                  })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation
                validations={state.validations}
                name="first_mark_duration"
              />
            </div>
          </div>

          <div
            className={`col-12 mb-2 ${
              type === "update" || type === "view" ? "d-none" : ""
            }`}
          >
            <div className="d-flex align-items-center py-2">
              <label className="size-08 bold-300 ms-1 me-3">Mensaje</label>
              <WhisperComponent
                width="auto"
                text="Mensaje que se mostrará al usuario si realiza la entrega fuera del tiempo límite de reserva."
              >
                <FcInfo />
              </WhisperComponent>
            </div>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.first_mark_message}
              </div>
            ) : (
              <Input
                placeholder="Mensaje para encargos"
                value={state.first_mark_message}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "first_mark_message", value },
                  })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation
                validations={state.validations}
                name="first_mark_message"
              />
            </div>
          </div>

          <div className="w-100">
            <hr className="my-4" />
          </div>

          {/**
           * 3. Duracion final del prestamo. Default 1440 minutos
           */}
          <div className="col-12 mb-2">
            <div className="d-flex align-items-center py-2">
              <label className="size-08 bold-300 ms-1 me-3">
                Duración final del prestamo ( en minutos )
              </label>
              <WhisperComponent
                width="auto"
                text="Este campo define la duración final del prestamo en minutos. El terminal no aceptará entrega después de este tiempo."
              >
                <FcInfo />
              </WhisperComponent>
            </div>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.second_mark_duration}</div>
            ) : (
              <Input
                placeholder="Duracion del prestamo"
                value={state.second_mark_duration}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "second_mark_duration", value },
                  })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation
                validations={state.validations}
                name="second_mark_duration"
              />
            </div>
          </div>

          <div
            className={`col-12 mb-2 ${
              type === "update" || type === "view" ? "d-none" : ""
            }`}
          >
            <div className="d-flex align-items-center py-2">
              <label className="size-08 bold-300 ms-1 me-3">Mensaje</label>
              <WhisperComponent
                width="auto"
                text="Mensaje que se mostrará al usuario si realiza la entrega fuera del tiempo límite de reserva."
              >
                <FcInfo />
              </WhisperComponent>
            </div>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.second_mark_message}
              </div>
            ) : (
              <Input
                placeholder="Mensaje para encargos"
                value={state.second_mark_message}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "second_mark_message", value },
                  })
                }
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation
                validations={state.validations}
                name="second_mark_message"
              />
            </div>
          </div>

          <div className="w-100">
            <hr className="my-4" />
          </div>

          <div
            className={`col-12 mb-2 ${
              type === "update" || type === "view" ? "d-none" : ""
            }`}
          >
            <div className="d-flex align-items-center py-2">
              <label className="size-08 bold-300 ms-1 me-3">
                Hora para eliminar reservas
              </label>
              <WhisperComponent
                width="auto"
                text="Ingrese una hora para que el sistema borre todas las reservas que no han sido entregadas."
              >
                <FcInfo />
              </WhisperComponent>
            </div>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.clear_reservation_at}
              </div>
            ) : (
              <DatePicker
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "clear_reservation_at", value },
                  })
                }
                className="w-100"
                placeholder="Selecciona hora"
                format="HH:mm:ss"
                value={state.clear_reservation_at}
                cleanable={false}
                placement="auto"
              />
            )}
            <div className="text-end size-08">
              <ErrorValidation
                validations={state.validations}
                name="clear_reservation_at"
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={() => handleClose(false)} appearance="subtle">
              Cancelar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormLendingConfigs;
