import api from "./api";
import store from "../redux/store";

class QrRegisterService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list = async (search: any = null) => {
    try {
      let query: any = "";
      if (search) {
        query =
          "?" +
          Object.keys(search)
            .map((key: any) => `${key}=${search[key]}`)
            .join("&");
      }
      const response = await api.get(`/pending-delivery-users${query}`);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  approve = async (payload: any) => {
    try {
      const response = await api.post(
        `/pending-delivery-users/approve`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  approveMany = async (list: any) => {
    try {
      const response: any = await api.post(
        `/pending-delivery-users/approve-many`,
        {
          ids: list,
        }
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  reject = async (payload: any) => {
    try {
      const response = await api.post(`/pending-delivery-users/reject`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  rejectMany = async (list: any) => {
    try {
      const response: any = await api.post(`/pending-delivery-users/reject-many`, {
        ids: list,
      });
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default QrRegisterService;
