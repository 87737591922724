import * as yup from "yup";

export const DynamicPickupValidationsSchema = yup.object().shape({
  pickup_home_btn_load_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_home_btn_load_description: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_home_btn_pickup_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_home_btn_pickup_description: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_load_step_one_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_load_step_two_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_pickup_step_one_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_pickup_step_two_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_pickup_step_three_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
});

export type DynamicPickupType = yup.InferType<
  typeof DynamicPickupValidationsSchema
>;
