import * as yup from "yup";

export const LendingConfigsSchema = yup.object().shape({
  first_mark_duration: yup.number().typeError("Este campo es requerido").min(1, "La cantidad de minutos debe ser mayor a 0").nullable().required("Este campo es requerido"),
  first_mark_message: yup.string().nullable().required("Este campo es requerido"),
  second_mark_duration: yup.number().typeError("Este campo es requerido").min(1, "La cantidad de minutos debe ser mayor a 0").nullable().required("Este campo es requerido"),
  second_mark_message: yup.string().nullable().required("Este campo es requerido"),
  third_mark_duration: yup.number().typeError("Este campo es requerido").min(1, "La cantidad de minutos debe ser mayor a 0").nullable().required("Este campo es requerido"),
});

export type LendingConfigsType = yup.InferType<typeof LendingConfigsSchema>;
