/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

const ColorPicker = ({ onChange, value, label }: any) => {
  const [state, setState] = useState<any>({
    displayColorPicker: false,
    color: {
      r: "0",
      g: "0",
      b: "0",
      a: "1",
    },
  });

  useEffect(() => {
    if (value) {
      setState({ ...state, color: value });
    }
  }, [value]);

  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state.displayColorPicker });
  };

  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
  };

  const handleChange = (color: any) => {
    setState({ ...state, color: color.rgb });
    onChange(color.rgb);
  };

  const styles: any = {
    color: {
      width: "100%",
      height: "14px",
      borderRadius: "2px",
      background: `rgba(${state.color?.r}, ${state.color?.g}, ${state.color?.b}, ${state.color?.a})`,
    },
    swatch: {
      width: "100%",
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  return (
    <div>
      <label className="size-08 bold-300 ms-1">{label}</label>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {state.displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={state.color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
