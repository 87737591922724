import { Input } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";

const PickupDeliveryTextForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      {/* CUSTODY STEP 1 */}
      <div className="col-12 py-2 bold-300 mt-3">
        Carga - Paso 2 - Cantidad de cajas
      </div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Selecciona cuantas cajas necesitas"
          value={data?.pickup_without_reservation_load_step_two_text_one}
          onChange={(value: any) =>
            onChange({ target: { name: "pickup_without_reservation_load_step_two_text_one", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="pickup_without_reservation_load_step_two_text_one"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Carga - Paso 3 - Cajas
      </div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Abre la(s) caja(s) y guarda tu paquete"
          value={data?.pickup_without_reservation_load_step_three_text_one}
          onChange={(value: any) =>
            onChange({ target: { name: "pickup_without_reservation_load_step_three_text_one", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="pickup_without_reservation_load_step_three_text_one"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default PickupDeliveryTextForm;
