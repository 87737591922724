import { v4 } from "uuid";

export const lendingStateSchema = {
  lending_home_pickup_list_title: "¿Como solicito?",
  lending_home_delivery_list_title: "¿Como devuelvo?",
  lending_home_pickup_list: [
    {
      id: v4(),
      title: "Realiza tu reserva",
      description: "",
    },
    {
      id: v4(),
      title: "Acercate al Autopréstamo",
      description:
        "Escanea el QR de tu carnet de identidad para realizar el préstamo",
    },
    {
      id: v4(),
      title: "Retira tus articulos",
      description: "del casillero asignado ;)",
    },
  ],
  lending_home_delivery_list: [
    {
      id: v4(),
      title: "Acércate al autopréstamo",
      description: "",
    },
    {
      id: v4(),
      title: "Escanea el código",
      description: "de los articulos que solicitaste",
    },
    {
      id: v4(),
      title: "Automaticamente el Autopréstamo",
      description: "te asignará un casillero",
    },
    {
      id: v4(),
      title: "Guarda los articulos",
      description: "en el casillero asignado ;)",
    },
    {
      id: v4(),
      title: "¡Recuerda cerrar el casillero! ;)",
      description: "",
    },
  ],
  lending_load_step_one_text_one:
    "Escanea o ingresa el codigo de los articulos",
  lending_load_step_two_text_one: "Guarda los articulos y cierra la puerta",
  lending_pickup_step_one_text_one: "Retira los articulos y cierra la puerta",
  lending_delivery_step_one_text_one:
    "Escanea o ingresa el codigo de los articulos",
  lending_delivery_step_two_text_one: "Guarda los articulos y cierra la puerta",
};

export const SchemaSections: any = {
  home: [
    "lending_home_pickup_list_title",
    "lending_home_delivery_list_title",
    "lending_home_pickup_list",
    "lending_home_delivery_list",
  ],
  delivery: [
    "lending_delivery_step_one_text_one",
    "lending_delivery_step_two_text_one",
  ],
  load: ["lending_load_step_one_text_one", "lending_load_step_two_text_one"],
  pickup: ["lending_pickup_step_one_text_one"],
};
