/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, DatePicker, Input, Modal, SelectPicker } from "rsuite";
import ErrorValidation from "../../../components/ErrorValidation";
import { loading_off, loading_on } from "../../../redux/actions/loader";
import OrderService from "../../../services/orders.service";
import { states } from "./order_states";
import { _pushToastMessage } from "../../../helpers";

const Filters = (props: any) => {
  const { handleClose, handleCallback, clearFilters } = props;
  const dispatch: any = useDispatch();
  const Order = new OrderService();

  const [state, setState] = useState<any>({ validations: {}, inputs: { end: moment().toDate(), start: moment().subtract(30, "days").toDate() } });

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    setState({ ...state, inputs: { ...state.inputs, [name]: value } });
  };

  useEffect(() => {
    if (props.filters) {
      if (props.filters.start) props.filters.start = moment(props.filters.start).toDate();
      if (props.filters.end) props.filters.end = moment(props.filters.end).subtract(1, "days").toDate();
      setState({ ...state, inputs: { ...props.filters } });
    }
  }, []);

  const _handleSearch = async () => {
    const params: any = { ...state.inputs };
    const end = moment(params.end)
    const start = moment(params.start)

    if (end.diff(start, "days") > 30) {
      _pushToastMessage({ type: "error", header: "Error", text: "El rango de fechas no puede ser mayor a 30 días" });
      return;
    }
    
    dispatch(loading_on());
    try {

      if (params.start) {
        params.start = moment(params.start).format("yyyy-MM-DD");
      }

      if (params.end) {
        params.end = moment(params.end).add(1, "day").format("yyyy-MM-DD");
      }

      const response: any = await Order.filter(params);
      handleCallback(response.data, params);
      dispatch(loading_off());
    } catch (e: any) {
      console.log(e.message);
      dispatch(loading_off());
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-2">
          <label className="size-08 bold-300 ms-1">Nombre</label>
          <Input
            placeholder="Ingresa nombre"
            value={state.inputs.name}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "name", value } })
            }
          />
          <ErrorValidation
            name="name"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Dirección</label>
          <Input
            placeholder="Ingresa dirección"
            value={state.inputs.address}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "address", value } })
            }
          />
          <ErrorValidation
            name="terminal"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Numero Departamento</label>
          <Input
            placeholder="Ingresa numero departamento"
            value={state.inputs.apartment}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "apartment", value } })
            }
          />
          <ErrorValidation
            name="external_reference"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Terminal</label>
          <Input
            placeholder="Ingresa nombre del terminal"
            value={state.inputs.terminal}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "terminal", value } })
            }
          />
          <ErrorValidation
            name="terminal"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Referencia Externa</label>
          <Input
            placeholder="Ingresa referencia externa"
            value={state.inputs.external_reference}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "external_reference", value } })
            }
          />
          <ErrorValidation
            name="external_reference"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Empresa</label>
          <Input
            placeholder="Ingresa nombre de la empresa"
            value={state.inputs.company}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "company", value } })
            }
          />
          <ErrorValidation
            name="company"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>

        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Estado</label>
          <SelectPicker
            placeholder="Selecciona estado"
            data={states}
            value={state.inputs.state}
            className="w-100"
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "state", value } })
            }
          />
          <ErrorValidation
            name="state"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Desde</label>
          <DatePicker
            placeholder="Informa fecha inicio"
            value={state.inputs.start}
            className="w-100"
            cleanable={false}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "start", value } })
            }
            format="dd-MM-yyyy"
          />
          <ErrorValidation
            name="start"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>

        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Hasta</label>
          <DatePicker
            placeholder="Informa fecha termino"
            className="w-100"
            cleanable={false}
            value={state.inputs.end}
            onChange={(value: any) =>
              _handleOnChange({ target: { name: "end", value } })
            }
            format="dd-MM-yyyy"
          />
          <ErrorValidation
            name="end"
            className="size-08 text-end"
            validations={state.validations}
          />
        </div>
      </div>

      <div className="row mt-4">
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cerrar
          </Button>
          <Button type="button" appearance="subtle" onClick={clearFilters}>
            Limpiar Filtros
          </Button>
          <Button type="button" appearance="primary" onClick={_handleSearch}>
            Confirmar
          </Button>
        </Modal.Footer>
      </div>
    </div>
  );
};

export default Filters;
