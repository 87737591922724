/* eslint-disable react-hooks/exhaustive-deps */
import { Input, TagPicker } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";
import {
  residentialActionsSchema,
  residentialOfflineActionsSchema,
} from "./Schema";
import { useEffect, useState } from "react";

const ResidentialHomeTextForm = ({ onChange, data }: any) => {
  const [actions, setActions] = useState<any>([]);
  useEffect(() => {
    if (data.slug === "residential-module") {
      setActions(residentialActionsSchema);
    } else {
      setActions(residentialOfflineActionsSchema);
    }
  }, [data.slug]);

  return (
    <div className="row">
      {/* HOME */}
      <div className="col-12 py-2 bold-300 mt-3">Pagina inicial</div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Selecciona si deseas Entrega o Retiro de tu caja"
          value={data?.home_text_one}
          onChange={(value: any) =>
            onChange({ target: { name: "home_text_one", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="home_text_one"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Acciones</label>
        <TagPicker
          data={actions}
          className="w-100"
          placeholder="Selecciona las acciones disponibles en el terminal"
          onChange={(value) =>
            onChange({
              target: { name: "home_actions_one", value },
            })
          }
          value={data?.home_actions_one}
        />
        <ErrorValidation
          className="text-end size-08"
          name="home_actions_one"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Butones</div>
      <hr />

      <div className="col-6 mb-2">
        <label className="size-08 bold-300 ms-1">Titulo butón entrega</label>
        <Input
          placeholder="Entrega"
          value={data?.home_btn_delivery_title}
          onChange={(value: any) =>
            onChange({ target: { name: "home_btn_delivery_title", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="home_btn_delivery_title"
          validations={data?.validations}
        />
      </div>

      <div className="col-6 mb-2">
        <label className="size-08 bold-300 ms-1">Texto butón entrega</label>
        <Input
          placeholder="Presiona aquí para entregar un paquete."
          value={data?.home_btn_delivery_description}
          onChange={(value: any) =>
            onChange({
              target: { name: "home_btn_delivery_description", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="home_btn_delivery_description"
          validations={data?.validations}
        />
      </div>

      <hr />

      <div className="col-6 mb-2">
        <label className="size-08 bold-300 ms-1">Titulo butón Retiro</label>
        <Input
          placeholder="Retiro"
          value={data?.home_btn_pickup_title}
          onChange={(value: any) =>
            onChange({ target: { name: "home_btn_pickup_title", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="home_btn_pickup_title"
          validations={data?.validations}
        />
      </div>

      <div className="col-6 mb-2">
        <label className="size-08 bold-300 ms-1">Texto butón retiro</label>
        <Input
          placeholder="Presiona aquí para retirar un paquete."
          value={data?.home_btn_pickup_description}
          onChange={(value: any) =>
            onChange({
              target: { name: "home_btn_pickup_description", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="home_btn_pickup_description"
          validations={data?.validations}
        />
      </div>

      <hr />

      <div className="col-6 mb-2">
        <label className="size-08 bold-300 ms-1">Titulo butón Custodia</label>
        <Input
          placeholder="Custodia"
          value={data?.home_btn_custody_title}
          onChange={(value: any) =>
            onChange({ target: { name: "home_btn_custody_title", value } })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="home_btn_custody_title"
          validations={data?.validations}
        />
      </div>

      <div className="col-6 mb-2">
        <label className="size-08 bold-300 ms-1">Texto butón Custodia</label>
        <Input
          placeholder="¡Deja un paquete para que retire quien quieras!"
          value={data?.home_btn_custody_description}
          onChange={(value: any) =>
            onChange({
              target: { name: "home_btn_custody_description", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="home_btn_custody_description"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default ResidentialHomeTextForm;
