import ColorPicker from "../../../../components/ColorPicker";

const EventsColorsForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      <div className="col-12 py-2 bold-300 mt-3">Colores principales</div>
      <hr className="w-75" />
      <div className="col-4 driver-colors-1">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: { name: "events_home_bg_color", value: color },
            })
          }
          label="Color de fondo"
          value={data.events_home_bg_color}
        />
      </div>
      <div className="col-4 driver-colors-2">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: { name: "events_home_primary_color", value: color },
            })
          }
          label="Color primario"
          value={data.events_home_primary_color}
        />
      </div>
      <div className="col-4 driver-colors-3">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: { name: "events_home_secondary_color", value: color },
            })
          }
          label="Color secondario"
          value={data.events_home_secondary_color}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Color botones</div>
      <hr className="w-75" />

      <div className="col-12 py-2 bold-300 mt-3">Color del botón estándar</div>
      <hr className="w-75" />

      <div className="col-4 driver-colors-4">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: { name: "events_home_btn_bg_color", value: color },
            })
          }
          label="Color fondo botones"
          value={data.events_home_btn_bg_color}
        />
      </div>
      <div className="col-4 driver-colors-5">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: { name: "events_home_btn_text_color", value: color },
            })
          }
          label="Color texto botones"
          value={data.events_home_btn_text_color}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Color del botón ayuda</div>
      <hr className="w-75" />

      <div className="col-4 driver-colors-6">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: { name: "events_home_btn_help_bg_color", value: color },
            })
          }
          label="Color fondo boton ayuda"
          value={data.events_home_btn_help_bg_color}
        />
      </div>
      <div className="col-4 driver-colors-7">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: { name: "events_home_btn_help_text_color", value: color },
            })
          }
          label="Color texto boton ayuda"
          value={data.events_home_btn_help_text_color}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Colores componentes</div>
      <hr className="w-75" />
      <div className="col-4 driver-colors-8">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: {
                name: "events_default_backdrop_color",
                value: color,
              },
            })
          }
          label="Color de fondo recuadro principal"
          value={data.events_default_backdrop_color}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Colores cajas</div>
      <hr className="w-75" />
      <div className="col-4 driver-colors-9">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: {
                name: "events_default_backdrop_box_color",
                value: color,
              },
            })
          }
          label="Color de fondo recuadro caja"
          value={data.events_default_backdrop_box_color}
        />
      </div>
      <div className="col-4 driver-colors-10">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: { name: "events_default_circle_color", value: color },
            })
          }
          label="Color de fondo circulo caja"
          value={data.events_default_circle_color}
        />
      </div>
      <div className="col-4 driver-colors-11">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: { name: "events_default_box_color", value: color },
            })
          }
          label="Color de la caja (Icono)"
          value={data.events_default_box_color}
        />
      </div>
      <div className="col-4 driver-colors-12">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: {
                name: "events_default_box_text_color",
                value: color,
              },
            })
          }
          label="Color texto caja"
          value={data.events_default_box_text_color}
        />
      </div>
      <div className="col-12 py-2 bold-300 mt-3">
        Colores teclado numérico (PIN Code y Ayuda)
      </div>
      <hr className="w-75" />
      <div className="col-4 driver-colors-13">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: {
                name: "events_pinpad_bg_numbers_color",
                value: color,
              },
            })
          }
          label="Color de fondo del campo numeros"
          value={data.events_pinpad_bg_numbers_color}
        />
      </div>
      <div className="col-4 driver-colors-14">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: {
                name: "events_pinpad_text_numbers_color",
                value: color,
              },
            })
          }
          label="Color del texto campo numeros"
          value={data.events_pinpad_text_numbers_color}
        />
      </div>
      <div className="col-4 driver-colors-15">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: {
                name: "events_pinpad_btn_ok_bg_color",
                value: color,
              },
            })
          }
          label="Color de fondo del botón OK (Teclado)"
          value={data.events_pinpad_btn_ok_bg_color}
        />
      </div>
      <div className="col-4 driver-colors-16">
        <ColorPicker
          onChange={(color: any) =>
            onChange({
              target: {
                name: "events_pinpad_btn_ok_text_color",
                value: color,
              },
            })
          }
          label="Color del texto del botón OK (Teclado)"
          value={data.events_pinpad_btn_ok_text_color}
        />
      </div>
    </div>
  );
};

export default EventsColorsForm;
