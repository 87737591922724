/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal, SelectPicker, Toggle } from "rsuite";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import ErrorValidation from "../../components/ErrorValidation";
import validate from "../../helpers/validate";
import { DynamicFieldSchema } from "../../validations/dynamic_fiels.validations";

const FormQrRegister = (props: any) => {
  const { type, data, handleClose } = props;
  const [state, setState] = useState<any>({
    is_dynamic_field_enabled: false,
    dynamic_field_label: "",
    dynamic_field_type: "",
    dynamic_field_placeholder: "",
  });

  const dispatch: any = useDispatch();
  const Company = new CompanyService();

  useEffect(() => {
    setState({ ...state, ...data });
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;

    if (name === "is_dynamic_field_enabled" && !value) {
      state.validations = {};
    }
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    if (type === "view") {
      handleClose();
      return;
    }

    if (state.is_dynamic_field_enabled) {
      const validations = await validate(DynamicFieldSchema, state);

      if (validations) {
        setState({ ...state, validations });
        return;
      }
    }

    setState({ ...state, validations: {} });

    dispatch(loading_on());

    const payload: any = {
      company_id: state.id,
      auto_accept_external_users: state.auto_accept_external_users,
      is_dynamic_field_enabled: state.is_dynamic_field_enabled,
      dynamic_field_label: state.dynamic_field_label,
      dynamic_field_type: state.dynamic_field_type,
      dynamic_field_placeholder: state.dynamic_field_placeholder,
    };

    try {
      const response = await Company.createQrRegister(payload);
      setState({ ...state, ...response.data?.company, validations: {} });

      if (state.qr_code_image_path) {
        handleClose();
        _pushToastMessage({
          type: "success",
          header: "Succeso",
          text: `Información actualizada con éxito...`,
        });
        return;
      }
      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: `Qr generado con éxito...`,
      });
      dispatch(loading_off());
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          {state.qr_code_image_path && (
            <div className="col-12 mb-2 d-flex flex-column">
              <label className="size-08 bold-300 ms-1">Codigo QR</label>
              <img
                src={state.qr_code_image_path}
                alt="qr"
                style={{ width: "250px" }}
              />
            </div>
          )}

          {state.qr_code_plain_text && (
            <div className="col-12 mb-2 d-flex flex-column">
              <label className="size-08 bold-300 ms-1">
                Link para formulario
              </label>
              <a
                href={state.qr_code_plain_text}
                target="_blank"
                rel="noreferrer"
              >
                Accesa al formulario clicando aqui
              </a>
            </div>
          )}

          <div className="col-6 mb-2 d-flex flex-column">
            <label className="size-08 bold-300 ms-1">
              Aprobación automatica
            </label>
            <Toggle
              checked={state.auto_accept_external_users}
              onChange={(value: any) =>
                _handleOnChange({
                  target: { name: "auto_accept_external_users", value },
                })
              }
            />
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-6 mb-2 d-flex flex-column">
            <label className="size-08 bold-300 ms-1">
              Habilitar campo dinamico
            </label>
            <Toggle
              checked={state.is_dynamic_field_enabled}
              onChange={(value: any) =>
                _handleOnChange({
                  target: { name: "is_dynamic_field_enabled", value },
                })
              }
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">
              Tipo del campo dinamico
            </label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.dynamic_field_label}
              </div>
            ) : (
              <SelectPicker
                disabled={!state.is_dynamic_field_enabled}
                className="w-100"
                placeholder="Tipo de campo"
                value={state.dynamic_field_type}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "dynamic_field_type", value },
                  })
                }
                data={[
                  { label: "Text", value: "text" },
                  { label: "Text Area", value: "textarea" },
                ]}
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="dynamic_field_type"
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">
              Nombre del campo dinamico
            </label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.dynamic_field_label}
              </div>
            ) : (
              <Input
                placeholder="Nombre del campo"
                value={state.dynamic_field_label}
                autoComplete="off"
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "dynamic_field_label", value },
                  })
                }
                disabled={!state.is_dynamic_field_enabled}
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="dynamic_field_label"
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">
              Placeholder del campo dinamico
            </label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.dynamic_field_placeholder}
              </div>
            ) : (
              <Input
                placeholder="Texto de ayuda"
                value={state.dynamic_field_placeholder}
                autoComplete="off"
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "dynamic_field_placeholder", value },
                  })
                }
                disabled={!state.is_dynamic_field_enabled}
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="dynamic_field_placeholder"
            />
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              {state.qr_code_image_path ? "Guardar" : "Generar"}
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormQrRegister;
