import { ImSearch } from "react-icons/im";
import ButtonIcon from "../../../components/ButtonIcon";

const Header = (props: any) => {
  const { handleSearchModal } = props;

  return (
    <>
      <div>SMS</div>
      <div className="d-flex">
        <ButtonIcon
          Icon={ImSearch}
          label=""
          width="20px"
          height="20px"
          style={{ marginTop: "5px" }}
          onClick={handleSearchModal}
        />
      </div>
    </>
  );
};

export default Header;
