import { Input } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";

const EventsPinPadForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      

      <div className="col-12 py-2 bold-300 mt-3">Texto Pin Pad</div>
      <hr />
      <div className="col-12 mb-2 driver-pin-text-1">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Ingresa tu codigo PIN"
          value={data?.events_pinpad_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_pinpad_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_pinpad_text"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default EventsPinPadForm;
