const Header = ({handleCreate, handleSearch, handleExport, handleSpeakerMenu}:any) => {

  return (
    <>
      <div className="py-3">Configuraciones Modulo</div>
    </>
  );
};

export default Header;
