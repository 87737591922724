export default interface IArticle {
  id: number;
  name: string;
  company_id: number;
  code: string;
}

export const attributes = {
  id: "ID",
  name: "Nombre articulo",
  company: "Empresa",
  code: "Codigo",
};
