/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import BoxTypeService from "../../services/box_type.service";
import { BoxTypeSchema } from "../../validations/box.type.validations";
import moment from "moment";

const FormSmS = (props: any) => {
  const { type, data, handleClose, getData } = props;
  const [state, setState] = useState<any>({
    delivery_user_name: "",
    status: "",
    phone: "",
    template_id: "",
    template_name: "",
    fields: "",
    id_trx: "",
    details: "",
    validations: {},
  });

  const BoxType = new BoxTypeService();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (type !== "create") {
      setState({ ...state, ...data });
    }
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (type === "view") {
      handleClose();
      return;
    }

    const validations: any = await validate(BoxTypeSchema, state);

    setState({ ...state, validations });

    if (validations) {
      return;
    }

    dispatch(loading_on());

    const payload: any = {
      name: state.name,
      width: state.width,
      height: state.height,
      depht: state.depht,
    };

    try {
      if (type === "create") {
        await BoxType.create(payload);
      }

      if (type === "update") {
        payload.id = state.id;
        await BoxType.update(payload);
      }

      _pushToastMessage({
        type: "success",
        header: "Succeso",
        text: `Caja ${
          type === "create" ? "creada" : "actualizada"
        } con éxito...`,
      });

      getData();
    } catch (e: any) {
      dispatch(loading_off());
      _handleError(e, e.message);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nombre</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.delivery_user_name}
              </div>
            ) : (
              <Input
                placeholder="Nombre"
                value={state.delivery_user_name}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "delivery_user_name", value },
                  })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="delivery_user_name"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Status</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.status}</div>
            ) : (
              <Input
                placeholder="status"
                value={state.status}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "status", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="status"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Teléfono</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.phone}</div>
            ) : (
              <Input
                placeholder="Teléfono"
                value={state.phone}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "phone", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="phone"
              validations={state.validations}
            />
          </div>
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Template</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.template_name}
              </div>
            ) : (
              <Input
                placeholder="Template"
                value={state.template_name}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "template_name", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="template_name"
              validations={state.validations}
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Template ID</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.template_id}</div>
            ) : (
              <Input
                placeholder="Template"
                value={state.template_id}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "template_id", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="template_id"
              validations={state.validations}
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Fecha envio</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{moment(state.created_at).format("DD-MM-yyyy hh:mm")}</div>
            ) : (
              <Input
                placeholder="Fecha envio"
                value={state.created_at}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "created_at", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="created_at"
              validations={state.validations}
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">ID TRX</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.id_trx}</div>
            ) : (
              <Input
                placeholder="Fecha envio"
                value={state.id_trx}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "id_trx", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="id_trx"
              validations={state.validations}
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Detalles</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.details}</div>
            ) : (
              <Input
                placeholder="Detalles"
                value={state.details}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "details", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="details"
              validations={state.validations}
            />
          </div>

          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Campos del template</label>
            {type && type === "view" ? (
              <pre className="rounded p-3 mt-3 border">
                <code>{JSON.stringify(state.fields, undefined, 2)}</code>
              </pre>
            ) : (
              <Input
                placeholder="Descripción"
                value={state.description}
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "description", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              name="description"
              validations={state.validations}
            />
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormSmS;
