import { Input } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";
import { FaPlusCircle } from "react-icons/fa";
import UploadImage from "../../../../components/UploadImage";
import { useState } from "react";
import WhisperComponent from "../../../../components/WhisperComponent";
import { BsInfoCircle } from "react-icons/bs";

const EventsHomeTextForm = ({
  onChange,
  addNewListItem,
  deleteListItem,
  data,
}: any) => {
  const [clearData, setClearData] = useState(0);
  const _handleClearImage = (data: any) => {
    setClearData(clearData + 1);
    onChange(data);
  };
  return (
    <div className="row justify-content-between">
      <div className="col-12 py-2 bold-300 mt-3">Logo</div>
      <hr />

      <div className="col-6 mb-2 driver-logo-company">
        <label className="size-08 bold-300 ms-1">Selecciona una imagen</label>
        <UploadImage
          onChange={(value: any) =>
            onChange({ target: { name: "events_default_logo_upload", value } })
          }
          onError={console.log}
          clearData={clearData}
        />

        {data?.events_default_logo_upload && (
          <div
            className="position-relative p-3 mt-4 rounded"
            style={{ maxWidth: "250px", border: "1px dotted #333" }}
          >
            <button
              className="btn position-absolute bold"
              style={{ top: 0, right: 0 }}
              onClick={() =>
                _handleClearImage({
                  target: { name: "events_default_logo_upload", value: "" },
                })
              }
            >
              x
            </button>
            <img
              src={data?.events_default_logo_upload}
              alt="logo"
              className="img-fluid"
              style={{ maxWidth: "200px" }}
            />
          </div>
        )}
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Mapa area del terminal</div>
      <hr />

      {/** MAP IMAGE */}
      <div className="col-6 mb-2 driver-map-terminal-image">
        <label className="size-08 bold-300 ms-1">Selecciona una imagen</label>
        <UploadImage
          onChange={(value: any) =>
            onChange({
              target: {
                name: "events_default_company_map_img_upload",
                value,
              },
            })
          }
          onError={console.log}
          clearData={clearData}
        />

        {data?.events_default_company_map_img_upload && (
          <div
            className="position-relative p-3 mt-4 rounded"
            style={{ maxWidth: "250px", border: "1px dotted #333" }}
          >
            <button
              className="btn position-absolute bold"
              style={{ top: 0, right: 0 }}
              onClick={() =>
                _handleClearImage({
                  target: {
                    name: "events_default_company_map_img_upload",
                    value: "",
                  },
                })
              }
            >
              x
            </button>
            <img
              src={data?.events_default_company_map_img_upload}
              alt="logo"
              className="img-fluid"
              style={{ maxWidth: "200px" }}
            />
          </div>
        )}
      </div>

      {/** MAP VIDEO */}
      <div className="col-12 mb-3 driver-map-terminal-video">
        <label className="size-08 bold-300 ms-1">
          <WhisperComponent
            capitalizeOff
            text="El código del video, es el código que se encuentra en la URL del video de Youtube. Ej: https://www.youtube.com/watch?v=XXXXXXXXXXX"
          >
            Código del video del mapa del terminal ( Youtube )
            <BsInfoCircle className="ms-3 size-12" style={{ color: "blue" }} />
          </WhisperComponent>
        </label>
        <Input
          placeholder="Informa el código del video del mapa del terminal ( Youtube )"
          value={data?.events_default_company_map_video_url}
          onChange={(value: any) =>
            onChange({
              target: {
                name: "events_default_company_map_video_url",
                value,
              },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_default_company_map_video_url"
          validations={data?.validations}
        />
      </div>

      <div className="col-6 mb-2 driver-btn-terminal-map">
        <label className="size-08 bold-300 ms-1">Texto botón imagen</label>
        <Input
          placeholder="Texto botón imagen"
          value={data?.events_home_map_btn_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_home_map_btn_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_home_map_btn_text"
          validations={data?.validations}
        />
      </div>

      <div className="col-6 mb-2 driver-btn-terminal-video">
        <label className="size-08 bold-300 ms-1">Texto botón video</label>
        <Input
          placeholder="Texto botón video"
          value={data?.events_home_video_btn_text}
          onChange={(value: any) =>
            onChange({
              target: { name: "events_home_video_btn_text", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="events_home_video_btn_text"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">Texto butones</div>
      <hr />
      <div className="driver-home-btn-1">
        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Texto botón 1</label>
          <Input
            placeholder="Mis Casilleros"
            value={data?.events_home_first_btn_text}
            onChange={(value: any) =>
              onChange({
                target: { name: "events_home_first_btn_text", value },
              })
            }
          />
          <ErrorValidation
            className="text-end size-08"
            name="events_home_first_btn_text"
            validations={data?.validations}
          />
        </div>

        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Texto botón 2</label>
          <Input
            placeholder="¿No puedes retirar tus items? Presiona aquí y te llamaremos"
            value={data?.events_home_second_btn_text}
            onChange={(value: any) =>
              onChange({
                target: { name: "events_home_second_btn_text", value },
              })
            }
          />
          <ErrorValidation
            className="text-end size-08"
            name="events_home_second_btn_text"
            validations={data?.validations}
          />
        </div>

        <div className="col-6 mb-2">
          <label className="size-08 bold-300 ms-1">Texto botón Multimedia (Imagen y video)</label>
          <Input
            placeholder="Mapa evento"
            value={data?.events_home_third_btn_text}
            onChange={(value: any) =>
              onChange({
                target: { name: "events_home_third_btn_text", value },
              })
            }
          />
          <ErrorValidation
            className="text-end size-08"
            name="events_home_third_btn_text"
            validations={data?.validations}
          />
        </div>
      </div>
      <div className="w-100 row justify-content-around driver-instructions">
        <div className="col" style={{ maxWidth: "48%" }}>
          <div className="row">
            <div className="col-12 py-2 bold-300 mt-3">
              Como solicito un préstamo
            </div>
            <hr className="w-75" />
            <div className="col-12 mb-2">
              <label className="size-08 bold-300 ms-1">Titulo</label>
              <Input
                placeholder="¿Como solicito?"
                value={data?.events_home_pickup_list_title}
                onChange={(value: any) =>
                  onChange({
                    target: { name: "events_home_pickup_list_title", value },
                  })
                }
              />
              <ErrorValidation
                className="text-end size-08"
                name="events_home_pickup_list_title"
                validations={data?.validations}
              />
            </div>

            <div className="col-12">
              <div className="w-100">
                <label className="size-08 bold-300 ms-1">
                  Lista de pasos para solicitar un préstamo
                </label>
                <button className="btn">
                  <FaPlusCircle
                    className="size-12"
                    onClick={() =>
                      addNewListItem({ list: "events_home_pickup_list" })
                    }
                  />
                </button>
                <ErrorValidation
                  className="text-end size-08"
                  name="events_home_pickup_list"
                  validations={data?.validations}
                />
              </div>
              {data?.events_home_pickup_list?.map(
                (item: any, index: number) => (
                  <div
                    className="row py-2 border rounded my-3"
                    key={`events_home_pickup_list-${index}`}
                  >
                    <div className="col-12 mb-3">
                      <label className="size-08 bold-300 ms-1">Titulo</label>
                      <Input
                        placeholder="titulo"
                        value={item?.title}
                        onChange={(value: any) =>
                          onChange({
                            target: {
                              name: `events_home_pickup_list.${item.id}.title`,
                              value,
                            },
                          })
                        }
                      />
                      <ErrorValidation
                        className="text-end size-08"
                        name="title"
                        validations={
                          data?.validations?.list_errors?.pickup_list_validations?.find(
                            (error: any) => error.id === item.id
                          )?.validations
                        }
                      />
                    </div>
                    <div className="col-12">
                      <label className="size-08 bold-300 ms-1">
                        Descripción
                      </label>
                      <Input
                        placeholder="Descripción"
                        value={item?.description}
                        onChange={(value: any) =>
                          onChange({
                            target: {
                              name: `events_home_pickup_list.${item.id}.description`,
                              value,
                            },
                          })
                        }
                      />
                    </div>
                    <div className="col-12 text-end mt-2">
                      <button
                        className="btn border-bottom border-3 p-0"
                        onClick={() =>
                          deleteListItem({
                            list: "events_home_pickup_list",
                            id: item.id,
                          })
                        }
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <div className="col" style={{ maxWidth: "48%" }}>
          <div className="row">
            <div className="col-12 py-2 bold-300 mt-3">
              Como devuelvo un préstamo
            </div>
            <hr className="w-75" />
            <div className="col-12 mb-2">
              <label className="size-08 bold-300 ms-1">Titulo</label>
              <Input
                placeholder="¿Como devuelvo?"
                value={data?.events_home_delivery_list_title}
                onChange={(value: any) =>
                  onChange({
                    target: { name: "events_home_delivery_list_title", value },
                  })
                }
              />
              <ErrorValidation
                className="text-end size-08"
                name="events_home_delivery_list_title"
                validations={data?.validations}
              />
            </div>

            <div className="col-12">
              <div className="w-100">
                <label className="size-08 bold-300 ms-1">
                  Lista de pasos para devolver un préstamo
                </label>
                <button className="btn">
                  <FaPlusCircle
                    className="size-12"
                    onClick={() =>
                      addNewListItem({ list: "events_home_delivery_list" })
                    }
                  />
                </button>
                <ErrorValidation
                  className="text-end size-08"
                  name="events_home_delivery_list"
                  validations={data?.validations}
                />
              </div>
              {data?.events_home_delivery_list?.map(
                (item: any, index: number) => (
                  <div
                    className="row py-2 border rounded my-3"
                    key={`events_home_delivery_list-${index}`}
                  >
                    <div className="col-12 mb-3">
                      <label className="size-08 bold-300 ms-1">Titulo</label>
                      <Input
                        placeholder="titulo"
                        value={item?.title}
                        onChange={(value: any) =>
                          onChange({
                            target: {
                              name: `events_home_delivery_list.${item.id}.title`,
                              value,
                            },
                          })
                        }
                      />
                      <ErrorValidation
                        className="text-end size-08"
                        name="title"
                        validations={
                          data?.validations?.list_errors?.delivery_list_validations?.find(
                            (error: any) => error.id === item.id
                          )?.validations
                        }
                      />
                    </div>
                    <div className="col-12">
                      <label className="size-08 bold-300 ms-1">
                        Descripción
                      </label>
                      <Input
                        placeholder="Descripción"
                        type="textarea"
                        value={item?.description}
                        onChange={(value: any) =>
                          onChange({
                            target: {
                              name: `events_home_delivery_list.${item.id}.description`,
                              value,
                            },
                          })
                        }
                      />
                    </div>
                    <div className="col-12 text-end mt-2">
                      <button
                        className="btn border-bottom border-3 p-0"
                        onClick={() =>
                          deleteListItem({
                            list: "events_home_delivery_list",
                            id: item.id,
                          })
                        }
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsHomeTextForm;
