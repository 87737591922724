import * as yup from "yup";

export const DynamicResidentialValidationsSchema = yup.object().shape({
  home_text_one: yup.string().nullable().required("Este campo es requerido"),
  home_actions_one: yup.array().nullable().of(yup.number()).required("Este campo es requerido"),
  home_btn_delivery_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  home_btn_pickup_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  home_btn_custody_title: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  home_btn_delivery_description: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  home_btn_pickup_description: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  home_btn_custody_description: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  delivery_step_one_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  delivery_step_one_keyboard_type: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  delivery_step_two_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  delivery_step_three_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  delivery_step_four_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_step_one_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  pickup_step_two_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  custody_step_one_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  custody_step_two_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
  custody_step_three_text_one: yup
    .string()
    .nullable()
    .required("Este campo es requerido"),
});

export type DynamicResidentialType = yup.InferType<
  typeof DynamicResidentialValidationsSchema
>;
