import * as yup from "yup";

export const DynamicPickupWithoutReservationValidationsSchema = yup
  .object()
  .shape({
    pickup_without_reservation_home_btn_load_title: yup
      .string()
      .nullable()
      .required("Este campo es requerido"),
    pickup_without_reservation_home_btn_load_description: yup
      .string()
      .nullable()
      .required("Este campo es requerido"),
    pickup_without_reservation_home_btn_pickup_title: yup
      .string()
      .nullable()
      .required("Este campo es requerido"),
    pickup_without_reservation_home_btn_pickup_description: yup
      .string()
      .nullable()
      .required("Este campo es requerido"),
    pickup_without_reservation_load_step_two_text_one: yup
      .string()
      .nullable()
      .required("Este campo es requerido"),
    pickup_without_reservation_load_step_three_text_one: yup
      .string()
      .nullable()
      .required("Este campo es requerido"),
    pickup_without_reservation_pickup_step_one_text_one: yup
      .string()
      .nullable()
      .required("Este campo es requerido"),
    pickup_without_reservation_pickup_step_two_text_one: yup
      .string()
      .nullable()
      .required("Este campo es requerido"),
    pickup_without_reservation_pickup_step_three_text_one: yup
      .string()
      .nullable()
      .required("Este campo es requerido"),
  });

export type DynamicPickupWithoutReservationType = yup.InferType<
  typeof DynamicPickupWithoutReservationValidationsSchema
>;
