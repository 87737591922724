/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Input, Modal, Toggle } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import CompanyService from "../../services/company.service";
import { _pushToastMessage } from "../../helpers";

const FormConfig = ({ data, handleClose }: { data: any; handleClose: any }) => {
  const [state, setState] = useState<any>({
    setup: {
      events_config: {
        enabled: false,
        max_reservations: 0,
        max_reservations_message: "",
      },
    },
    validations: {},
  });
  useEffect(() => {
    console.log(data.config);
    if (data?.config) {
      setState({ ...state, ...data.config, company_id: data?.id });
    } else {
      setState({ ...state, company_id: data?.id });
    }
  }, []);

  const _handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      setup: {
        ...state.setup,
        events_config: {
          ...state.setup.events_config,
          [name]: value,
        },
      },
    });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();

    try {
      if (
        state.setup?.events_config?.enabled &&
        (!state.setup?.events_config?.max_reservations ||
          state.setup?.events_config?.max_reservations < 1)
      ) {
        setState({
          ...state,
          validations: {
            ...state.validations,
            max_reservations: ["Este campo es requerido"],
          },
        });
        return;
      }
      const payload = { ...state };
      delete payload.validations;

      await new CompanyService().createOrUpdateConfig(payload);

      _pushToastMessage({
        header: "Configuración",
        text: "Configuración actualizada correctamente",
        type: "success",
      });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <hr />
            <Toggle
              checked={state.setup?.events_config?.enabled}
              onChange={(value: any) =>
                _handleOnChange({ target: { name: "enabled", value } })
              }
            />
            <span className="size-09 ms-3">
              {" "}
              Limitar cantidades de reservas para una empresa
            </span>
          </div>
          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">
              Cantidad de reservas por empresa
            </label>
            <Input
              placeholder="Cantidad de reservas permitidas"
              value={state.setup?.events_config?.max_reservations}
              onChange={(value: any) =>
                _handleOnChange({ target: { name: "max_reservations", value } })
              }
              disabled={!state.setup?.events_config?.enabled}
            />
            <ErrorValidation
              name="max_reservations"
              className="size-08 text-end"
              validations={state.validations}
            />
          </div>
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormConfig;
