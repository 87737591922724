import { Fragment, useEffect, useState } from "react";
import { Checkbox, Divider, Table, Tooltip, Whisper } from "rsuite";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import MoreIcon from "@rsuite/icons/More";
import { MoreMenu } from "../../../components/MoreMenu";
import { _actionMenuSpeaker } from "../speaker";
import { attributes } from "../Schema";
import ModalComponent from "../../../components/Modal/Modal";
import { _pushToastMessage } from "../../../helpers";
import ConfirmationModal from "../../../components/ConfirmationModal";
import BoxTypeService from "../../../services/box_type.service";
import { loading_off, loading_on } from "../../../redux/actions/loader";
import { _handleError } from "../../../helpers/errors";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import FormVersions from "../../../forms/FormVersions";

export const TableComponent = (props: any) => {
  const {
    data,
    getData,
    indeterminate,
    checked,
    handleCheck,
    handleCheckAll,
    checkedKeys,
  } = props;
  const { loader } = useSelector((state: any) => state);
  const [tableHeader, setTableHeader] = useState<any>([]);
  const [state, setState] = useState<any>({});

  const [confirmation, setConfirmation] = useState<any>({
    open: false,
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  const dispatch: any = useDispatch();

  const BoxType = new BoxTypeService();

  useEffect(() => {
    const fields: any = { ...attributes };
    const tableHeader: any = Object.keys(fields).map((key: any) => {
      return {
        label: fields[key],
        key: key,
        align: "left",
        alignFlex: "flex-start",
        flexGrow: 1,
      };
    });
    setTableHeader(tableHeader);
  }, []);

  const handleSelectChange = (payload: any) => {
    let selectedData: any = null;
    switch (payload.option) {
      case 1:
        selectedData = data.data.find((item: any) => item.id === payload.id);
        setState({ ...state, updateBoxModal: true, selectedData });
        break;
      case 2:
        selectedData = data.data.find((item: any) => item.id === payload.id);
        setState({ ...state, viewBoxModal: true, selectedData });
        break;
      case 3:
        setConfirmation({
          open: true,
          text: "¿Seguro que quieres eliminar esta caja",
          handleClose: () => setConfirmation({ ...confirmation, open: false }),
          handleConfirm: () => _deleteSelected(payload.id),
        });
        break;
      default:
        console.log(payload);
    }
  };

  const _deleteSelected = async (id: any) => {
    dispatch(loading_on());
    try {
      await BoxType.delete(id);
      _pushToastMessage({
        type: "success",
        header: "Éxito",
        text: "Caja eliminada con éxito",
      });
      setConfirmation({ ...confirmation, open: false });
      getData();
    } catch (e: any) {
      _handleError(e, e.message);
      setConfirmation({ ...confirmation, open: false });
      dispatch(loading_off());
    }
  };

  const _toggleUpdateBoxModal = () =>
    setState({ ...state, updateBoxModal: !state.updateBoxModal });

  const _toggleUpdateBoxModalAndReload = () => {
    setState({ ...state, updateBoxModal: !state.updateBoxModal });
    getData();
  };

  const _toggleViewBoxModal = () =>
    setState({ ...state, viewBoxModal: !state.viewBoxModal });

  const Actions = (props: any) => {
    const { payload } = props;
    const _speaker: any = _actionMenuSpeaker(payload, handleSelectChange);

    return (
      <div className="row me-3">
        <div className="col-12">
          <button
            className="btn px-0"
            onClick={() => handleSelectChange({ option: 2, id: payload.id })}
          >
            <ParagraphIcon />
          </button>
          <Divider vertical />
          <span style={{ cursor: "pointer" }}>
            <Whisper
              controlId={payload.id}
              placement="auto"
              trigger="click"
              speaker={(whisper_payload: any, ref: any) =>
                MoreMenu(whisper_payload, ref, _speaker)
              }
            >
              <MoreIcon />
            </Whisper>
          </span>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <ModalComponent
        open={state.updateBoxModal}
        size="md"
        title="Actualizar caja"
        handleClose={_toggleUpdateBoxModal}
      >
        <FormVersions
          type="update"
          data={state.selectedData}
          getData={_toggleUpdateBoxModalAndReload}
        />
      </ModalComponent>

      <ModalComponent
        open={state.viewBoxModal}
        size="sm"
        title="Caja"
        handleClose={_toggleViewBoxModal}
      >
        <FormVersions type="view" data={state.selectedData} />
      </ModalComponent>

      <ModalComponent
        open={confirmation.open}
        size="md"
        title="Eliminar empresa"
        handleClose={() => confirmation.handleClose()}
      >
        <ConfirmationModal
          text={confirmation.text}
          handleConfirm={confirmation.handleConfirm}
        />
      </ModalComponent>

      <Table
        data={data.data}
        rowClassName="striped"
        rowHeight={80}
        autoHeight
        loading={loader.is_loading}
        locale={{ emptyMessage: "No encontramos ningún dato." }}
      >
        <Table.Column align="center" verticalAlign="middle">
          <Table.HeaderCell
            verticalAlign="middle"
            align="center"
            style={{ padding: 0 }}
          >
            <Checkbox
              onChange={handleCheckAll}
              indeterminate={indeterminate}
              checked={checked}
            />
          </Table.HeaderCell>
          <Table.Cell
            verticalAlign="middle"
            align="center"
            style={{ padding: 0 }}
          >
            {(rowData) => {
              return (
                <Checkbox
                  onChange={handleCheck}
                  value={rowData.id}
                  checked={checkedKeys.some((item: any) => item === rowData.id)}
                />
              );
            }}
          </Table.Cell>
        </Table.Column>

        {tableHeader &&
          tableHeader.map((column: any, index: any) => (
            <Table.Column
              align={column.align}
              flexGrow={column.flexGrow}
              key={`table-column-${index}`}
            >
              <Table.HeaderCell>
                <span className="bold" style={{ textTransform: "capitalize" }}>
                  {column.label}
                </span>
              </Table.HeaderCell>

              <Table.Cell
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: column.alignFlex,
                }}
              >
                {(rowData) => {
                  switch (column.key) {
                    case "auto_update":
                      return <div className="w-100 text-center">{rowData[column.key] ? "Sí" : "No"}</div>;
                    case "terminals":
                      return <div className="w-100">{rowData.info['terminals']?.join(' , ')}</div>
                    default:
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={<Tooltip>{rowData[column.key]}</Tooltip>}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData[column.key]}
                          </div>
                        </Whisper>
                      );
                  }
                }}
              </Table.Cell>
            </Table.Column>
          ))}

        <Table.Column align="right" flexGrow={1} verticalAlign="middle">
          <Table.HeaderCell>{""}</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return <Actions payload={rowData} />;
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Fragment>
  );
};
