import { Input } from "rsuite";
import ErrorValidation from "../../../../components/ErrorValidation";

const LendingDeliveryTextForm = ({ onChange, data }: any) => {
  return (
    <div className="row">
      {/* CUSTODY STEP 1 */}
      <div className="col-12 py-2 bold-300 mt-3">
        Entrega de articulos - Paso 1
      </div>
      <hr />
      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Escanea o ingressa el codigo de los articulos"
          value={data?.lending_delivery_step_one_text_one}
          onChange={(value: any) =>
            onChange({
              target: { name: "lending_delivery_step_one_text_one", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="lending_delivery_step_one_text_one"
          validations={data?.validations}
        />
      </div>

      <div className="col-12 py-2 bold-300 mt-3">
        Entrega de articulos - Confirmación
      </div>
      <hr />

      <div className="col-12 mb-2">
        <label className="size-08 bold-300 ms-1">Texto</label>
        <Input
          placeholder="Escanea o ingressa el codigo de los articulos"
          value={data?.lending_delivery_step_two_text_one}
          onChange={(value: any) =>
            onChange({
              target: { name: "lending_delivery_step_two_text_one", value },
            })
          }
        />
        <ErrorValidation
          className="text-end size-08"
          name="lending_delivery_step_two_text_one"
          validations={data?.validations}
        />
      </div>
    </div>
  );
};

export default LendingDeliveryTextForm;
