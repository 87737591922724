import { handleActions } from "redux-actions";
import { DISPATCH_EVENT } from "../actions/messenger";

export const INITIAL_STATE = {
  action: '',
  attributes: {},
};

export default handleActions(
  {
    [DISPATCH_EVENT]: (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
  },
  INITIAL_STATE
);
