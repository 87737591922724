import { v4 } from "uuid";

export const eventsStateSchema = {
  events_default_logo_upload: "",
  events_default_company_map_img_upload: "",
  events_default_company_map_video_url: "",
  events_home_map_btn_text: "Mapa terminal",
  events_home_video_btn_text: "Video terminal",
  events_company_map_btn_text: "Mapa evento",
  events_company_video_btn_text: "Video evento",
  events_home_third_btn_text: "Mapa del evento",
  events_default_backdrop_color: { r: 255, g: 255, b: 255, a: 0.1 },
  events_default_backdrop_box_color: { r: 255, g: 255, b: 255, a: 0.2 },
  events_default_circle_color: { r: 52, g: 52, b: 52, a: 1 },
  events_default_box_color: { r: 255, g: 255, b: 0, a: 1 },
  events_default_box_text_color: { r: 255, g: 255, b: 255, a: 1 },
  events_home_bg_color: { r: 0, g: 0, b: 0, a: 1 },
  events_home_primary_color: { r: 255, g: 255, b: 255, a: 1 },
  events_home_secondary_color: { r: 255, g: 255, b: 0, a: 1 },
  events_home_qrcode_bg_color: { r: 255, g: 255, b: 255, a: 1 },
  events_home_btn_text_color: { r: 0, g: 0, b: 0, a: 1 },
  events_home_btn_bg_color: { r: 255, g: 255, b: 0, a: 1 },
  events_home_btn_help_text_color: { r: 255, g: 255, b: 255, a: 1 },
  events_home_btn_help_bg_color: { r: 0, g: 0, b: 0, a: 1 },
  events_home_pickup_list_title: "¿Como guardo?",
  events_home_delivery_list_title: "¿Como retiro?",
  events_home_first_btn_text: "Mis Casilleros",
  events_home_second_btn_text:
    "¿No puedes retirar tus items? Presiona aquí y te llamaremos",
  events_home_pickup_list: [
    {
      id: v4(),
      title: "Luego de registrarse, escanea",
      description: "el QR de tu cedula de identidad",
    },
    {
      id: v4(),
      title: "Ingresa tu codigo PIN",
      description: "",
    },
    {
      id: v4(),
      title: "Selecciona un tamaño de caja",
      description: "",
    },
    {
      id: v4(),
      title: "Guarda tus pertenencias",
      description: "y cierra la puerta",
    },
    {
      id: v4(),
      title: "Puedes guardar e sacar cosas",
      description: "las veces que quieras presionando en mi casillero ;)",
    },
  ],
  events_home_delivery_list: [
    {
      id: v4(),
      title: "Escanea tu cedula de identidad",
      description: "para abrir tu casillero",
    },
    {
      id: v4(),
      title: "Ingresa tu codigo PIN",
      description: "",
    },
    {
      id: v4(),
      title: "Saca tus pertenencias",
      description: "y cierra la puerta ;)",
    },
  ],
  events_delivery_pinpad_required: true,
  events_delivery_boxes_required: true,
  events_delivery_single_success_step_text:
    "Guarda tus pertenencias y cierra la puerta.",
  events_delivery_single_fail_step_text: "No hay cajas disponibles",
  events_delivery_step1_text: "Selecciona el tamaño de caja que necesitas",
  events_delivery_step2_text:
    "Si el tamaño de la caja no te sirve, selecciona la opción para cambiar caja a continuación.",
  events_pickup_pinpad_required: true,
  events_pickup_step1_text:
    "Abre la caja y retira todos tus items. Tu reserva sera finalizada al cerrar la puerta.",
  events_my_locker_list: [
    {
      id: v4(),
      title: "Escanea el QR",
      description: "de tu cedula de identidad",
    },
    {
      id: v4(),
      title: "Ingresa tu codigo PIN",
      description: "",
    },
    {
      id: v4(),
      title: "Guarda o saca tus pertenencias",
      description: "y cierra la puerta",
    },
    {
      id: v4(),
      title: "Puedes guardar e sacar cosas",
      description: "las veces que quieras presionando en Mi Casillero ;)",
    },
  ],
  events_my_locker_pinpad_required: true,
  events_my_locker_list_title: "¿Como guardo o saco cosas de mi casillero?",
  events_my_locker_confirmation_title:
    "Guarda tus pertenencias y cierra el casillero!",
  events_my_locker_confirmation_subtitle:
    "Puedes guardar y sacar cosas las veces que quieras presionando en Mi Casillero",

  events_pinpad_text: "Ingresa tu codigo PIN",
  events_help_emails: [],
  events_help_phones: [],
  events_help_text:
    "Ingresa tu número telefónico y te contactaremos enseguida!",
  events_help_success_text:
    "Solicitud completada con éxito. Te llamaremos pronto.",
  events_help_failed_text:
    "No fue posible completar tu solicitud. Vuelve a intentar en algunos minutos.",
  events_pinpad_bg_numbers_color: { r: 255, g: 255, b: 255, a: 0.2 },
  events_pinpad_text_numbers_color: { r: 255, g: 255, b: 255, a: 1 },
  events_pinpad_btn_ok_bg_color: { r: 255, g: 255, b: 0, a: 1 },
  events_pinpad_btn_ok_text_color: { r: 0, g: 0, b: 0, a: 1 },
  events_company_map_img_upload: "",
  events_company_map_video_url: "",
  events_company_max_reservations_enabled: false,
  events_company_max_reservations: 1,
  events_company_max_reservations_message:
    "Se ha alcanzado el límite de reservas simultáneas para su usuario.",
  events_company_info_enabled: true,
  events_company_max_reservations_message_one:
    "Sus pertenencias se encuentran en otro locker. A continuación puede ver cual el locker y como llegar hasta el.",
  events_company_max_reservations_message_two:
    "Sus pertenencias se encuentran en otro locker. A continuación puede ver cual el locker y como llegar hasta el. Haga click en guardar para guardar algo en este locker.",
};

export const SchemaSections: any = {
  home: [
    "events_home_pickup_list_title",
    "events_home_delivery_list_title",
    "events_home_first_btn_text",
    "events_home_second_btn_text",
    "events_home_third_btn_text",
    "events_home_pickup_list",
    "events_home_delivery_list",
    "events_home_map_btn_text",
    "events_home_video_btn_text",
  ],
  delivery: [
    "events_delivery_step1_text",
    "events_delivery_step2_text",
    "events_delivery_single_success_step_text",
    "events_delivery_single_fail_step_text",
  ],
  pickup: ["events_pickup_step1_text"],
  my_locker: [
    "events_my_locker_list_title",
    "events_my_locker_list",
    "events_my_locker_confirmation_title",
    "events_my_locker_confirmation_subtitle",
  ],
  pinpad: ["events_pinpad_text"],
  help: [
    "events_help_emails",
    "events_help_phones",
    "events_help_text",
    "events_help_success_text",
    "events_help_failed_text",
  ],
  company: [
    "events_company_max_reservations_enabled",
    "events_company_max_reservations",
    "events_company_max_reservations_message",
    "events_company_info_enabled",
    "events_company_max_reservations_message_one",
    "events_company_max_reservations_message_two",
    "events_company_map_btn_text",
    "events_company_video_btn_text",
  ],
};
