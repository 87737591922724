import moment from "moment";

export const _createChartData = (data: any) => {
  data.map((item: any) => {
    const reservation: any =
      item.reservations.length > 0
        ? item.reservations[item.reservations.length - 1]
        : undefined;

    item.moment = reservation
      ? moment(reservation.created_at)
      : moment(item.created_at);
    item.humanDate = item.moment.format("DD-MM-YY");
    return item;
  });

  data.sort((a: any, b: any) => {
    if (a.moment?.isAfter(b.moment)) return 1;
    if (a.moment?.isBefore(b.moment)) return -1;
    return 0;
  });

  const initiatedOrder: any = data.filter(
    (item: any) => item.state.toLowerCase() === "orden iniciada"
  );
  const pickedUpOrder: any = data.filter(
    (item: any) => item.state.toLowerCase() === "retirado" || item.state.toLowerCase() === "custodia retirada"
  );
  const deliveredOrder: any = data.filter(
    (item: any) => item.state.toLowerCase() === "en el locker" || item.state.toLowerCase() === "custodia por retirar"
  );

  const chartData: any = [];

  initiatedOrder.forEach((item: any) => {
    const chartDataItem: any = chartData.find(
      (cd: any) => cd.dia === item.humanDate
    );

    if (chartDataItem) {
      chartDataItem.Iniciada += 1;
    } else {
      chartData.push({
        dia: item.humanDate,
        Iniciada: 1,
      });
    }
  });

  pickedUpOrder.forEach((item: any) => {
    const chartDataItem: any = chartData.find(
      (cd: any) => cd.dia === item.humanDate
    );

    if (chartDataItem) {
      chartDataItem.Retirado = chartDataItem.Retirado
        ? chartDataItem.Retirado + 1
        : 1;
    } else {
      chartData.push({
        dia: item.humanDate,
        Retirado: 1,
      });
    }
  });

  deliveredOrder.forEach((item: any) => {
    const chartDataItem: any = chartData.find(
      (cd: any) => cd.dia === item.humanDate
    );

    if (chartDataItem) {
      chartDataItem.Entregado = chartDataItem.Entregado
        ? chartDataItem.Entregado + 1
        : 1;
    } else {
      chartData.push({
        dia: item.humanDate,
        Entregado: 1,
      });
    }
  });

  chartData.map((item: any) => {
    if (!item.Iniciada) item.Iniciada = 0;
    if (!item.Retirado) item.Retirado = 0;
    if (!item.Entregado) item.Entregado = 0;
    return item;
  });

  return chartData;
};

export const _createReservationsChartData = (data: any) => {
  let reservations: any = [];

  data.forEach((item: any) => {
    reservations = [...reservations, ...item.reservations];
  });

  const createdReservations: any = [];
  const deliveredReservations: any = [];
  const pickedReservations: any = [];

  reservations.forEach((item: any) => {
    if (item.reservation_type?.indexOf("created") !== -1) {
      item.moment = moment(item.created_at);
      item.humanDate = item.moment.format("DD-MM-YY");
      createdReservations.push(item);
    }

    if (item.reservation_type?.indexOf("picked") !== -1) {
      item.moment = moment(item.created_at);
      item.humanDate = item.moment.format("DD-MM-YY");
      pickedReservations.push(item);
    }

    if (item.reservation_type?.indexOf("delivered") !== -1) {
      item.moment = moment(item.created_at);
      item.humanDate = item.moment.format("DD-MM-YY");
      deliveredReservations.push(item);
    }
  });

  const chartData: any = [];

  createdReservations.forEach((item: any) => {
    const chartDataItem: any = chartData.find(
      (cd: any) => cd.dia === item.humanDate
    );

    if (chartDataItem) {
      chartDataItem.Iniciada += 1;
    } else {
      chartData.push({
        dia: item.humanDate,
        Iniciada: 1,
      });
    }
  });

  deliveredReservations.forEach((item: any) => {
    const chartDataItem: any = chartData.find(
      (cd: any) => cd.dia === item.humanDate
    );

    if (chartDataItem) {
      chartDataItem["Entregado"] = chartDataItem["Entregado"]
        ? chartDataItem["Entregado"] + 1
        : 1;
    } else {
      chartData.push({
        dia: item.humanDate,
        "Entregado": 1,
      });
    }
  });

  pickedReservations.forEach((item: any) => {
    const chartDataItem: any = chartData.find(
      (cd: any) => cd.dia === item.humanDate
    );

    if (chartDataItem) {
      chartDataItem.Retirado = chartDataItem.Retirado
        ? chartDataItem.Retirado + 1
        : 1;
    } else {
      chartData.push({
        dia: item.humanDate,
        Retirado: 1,
      });
    }
  });

  chartData.map((item: any) => {
    if (!item.Iniciada) item.Iniciada = 0;
    if (!item.Retirado) item.Retirado = 0;
    if (!item["Entregado"]) item["Entregado"] = 0;
    return item;
  });

  console.table(deliveredReservations);

  return chartData;
};
