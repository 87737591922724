export default interface IBox {
  name: string;
  width: string;
  height: string;
  depht: string;
}

export const attributes = {
  version: "Version",
  build: "Build",
  terminals: "ID Terminales",
  auto_update: "Actualización automática",
};
