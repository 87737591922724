import { Fragment, useEffect, useState } from "react";
import { Checkbox, Divider, Table, Tooltip, Whisper } from "rsuite";
import ParagraphIcon from "@rsuite/icons/Paragraph";
import MoreIcon from "@rsuite/icons/More";
import { MoreMenu } from "../../../components/MoreMenu";
import { _actionMenuSpeaker } from "../speaker";
import { attributes } from "../Schema";
import ModalComponent from "../../../components/Modal/Modal";
import FormTerminal from "../../../forms/FormTerminal";
import FormBoxTerminal from "../../../forms/FormBoxTerminal";
import { _pushToastMessage } from "../../../helpers";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useDispatch } from "react-redux";
import TerminalService from "../../../services/terminal.service";
import { loading_off, loading_on } from "../../../redux/actions/loader";
import { _handleError } from "../../../helpers/errors";
import { useNavigate } from "react-router-dom";

export const TableComponent = (props: any) => {
  const {
    data,
    indeterminate,
    checked,
    handleCheck,
    handleCheckAll,
    checkedKeys,
    getData,
  } = props;
  const [tableHeader, setTableHeader] = useState<any>([]);
  const [state, setState] = useState<any>({});

  const [confirmation, setConfirmation] = useState<any>({
    open: false,
    text: "",
    handleClose: () => {},
    handleConfirm: () => {},
  });

  const dispatch: any = useDispatch();
  const Terminal = new TerminalService();
  const navigate = useNavigate();

  useEffect(() => {
    const fields: any = { ...attributes };
    const tableHeader: any = Object.keys(fields).map((key: any) => {
      return {
        label: fields[key],
        key: key,
        align: "left",
        alignFlex: "flex-start",
        flexGrow: 1,
      };
    });
    setTableHeader(tableHeader);
  }, []);

  const handleSelectChange = (payload: any) => {
    let selectedData: any = null;
    console.log(payload);
    switch (payload.option) {
      case 1:
        selectedData = data?.data.find((item: any) => item.id === payload.id);
        setState({ ...state, updateTerminalModal: true, selectedData });
        break;
      case 2:
        selectedData = data?.data.find((item: any) => item.id === payload.id);
        setState({ ...state, viewTerminalModal: true, selectedData });
        break;
      case 3:
        setConfirmation({
          open: true,
          text: "¿Seguro que quieres eliminar este terminal",
          handleClose: () => setConfirmation({ ...confirmation, open: false }),
          handleConfirm: () => _deleteSelected(payload.id),
        });
        break;
      case 4:
        selectedData = data?.data.find((item: any) => item.id === payload.id);
        setState({ ...state, adminTerminalBoxes: true, selectedData });
        break;
      case 5:
        selectedData = data?.data.find((item: any) => item.id === payload.id);
        navigate(`/terminals/dynamic-module/${selectedData.id}`);
        break;
      default:
        console.log(payload);
    }
  };

  const _deleteSelected = async (id: any) => {
    dispatch(loading_on());
    try {
      await Terminal.delete(id);
      _pushToastMessage({
        type: "success",
        header: "Éxito",
        text: "Terminal eliminado con éxito",
      });
      setConfirmation({ ...confirmation, open: false });
      getData();
    } catch (e: any) {
      _handleError(e, e.message);
      setConfirmation({ ...confirmation, open: false });
      dispatch(loading_off());
    }
  };

  const _toggleUpdateTerminalModal = () => {
    setState({ ...state, updateTerminalModal: !state.updateTerminalModal });
    getData();
  };

  const _toggleViewTerminalModal = () =>
    setState({ ...state, viewTerminalModal: !state.viewTerminalModal });

  const _toggleAdminTerminalBoxes = () =>
    setState({ ...state, adminTerminalBoxes: !state.adminTerminalBoxes });

  const _toggleAdminTerminalBoxesAndReload = () => {
    setState({ ...state, adminTerminalBoxes: !state.adminTerminalBoxes });
    getData();
  };

  const Actions = (props: any) => {
    const { payload } = props;
    const _speaker: any = _actionMenuSpeaker(payload, handleSelectChange);

    return (
      <div className="row me-3">
        <div className="col-12">
          <button
            className="btn px-0"
            onClick={() => handleSelectChange({ option: 2, id: payload.id })}
          >
            <ParagraphIcon />
          </button>
          <Divider vertical />
          <span style={{ cursor: "pointer" }}>
            <Whisper
              controlId={payload.id}
              placement="auto"
              trigger="click"
              speaker={(whisper_payload: any, ref: any) =>
                MoreMenu(whisper_payload, ref, _speaker)
              }
            >
              <MoreIcon />
            </Whisper>
          </span>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <ModalComponent
        open={state.updateTerminalModal}
        size="md"
        title="Actualizar terminal"
        handleClose={_toggleUpdateTerminalModal}
      >
        <FormTerminal type="update" data={state.selectedData} />
      </ModalComponent>

      <ModalComponent
        open={state.viewTerminalModal}
        size="sm"
        title="Terminal"
        handleClose={_toggleViewTerminalModal}
      >
        <FormTerminal type="view" data={state.selectedData} />
      </ModalComponent>

      <ModalComponent
        open={state.adminTerminalBoxes}
        size="lg"
        title="Terminal - Cajas"
        handleClose={_toggleAdminTerminalBoxes}
      >
        <FormBoxTerminal
          type="create"
          data={state.selectedData}
          handleConfirm={_toggleAdminTerminalBoxesAndReload}
        />
      </ModalComponent>

      <ModalComponent
        open={confirmation.open}
        size="md"
        title="Eliminar empresa"
        handleClose={() => confirmation.handleClose()}
      >
        <ConfirmationModal
          text={confirmation.text}
          handleConfirm={confirmation.handleConfirm}
        />
      </ModalComponent>

      <Table
        data={data?.data}
        rowClassName="striped"
        autoHeight
        rowHeight={80}
        locale={{ emptyMessage: "No encontramos ningún dato." }}
      >
        <Table.Column align="center" verticalAlign="middle">
          <Table.HeaderCell
            verticalAlign="middle"
            align="center"
            style={{ padding: 0 }}
          >
            <Checkbox
              onChange={handleCheckAll}
              indeterminate={indeterminate}
              checked={checked}
            />
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return (
                <Checkbox
                  onChange={handleCheck}
                  value={rowData.id}
                  checked={checkedKeys.some((item: any) => item === rowData.id)}
                />
              );
            }}
          </Table.Cell>
        </Table.Column>

        {tableHeader &&
          tableHeader.map((column: any, index: any) => (
            <Table.Column
              align={column.align}
              flexGrow={column.flexGrow}
              key={`table-column-${index}`}
            >
              <Table.HeaderCell>
                <span className="bold" style={{ textTransform: "capitalize" }}>
                  {column.label}
                </span>
              </Table.HeaderCell>

              <Table.Cell
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: column.alignFlex,
                }}
              >
                {(rowData) => {
                  switch (column.key) {
                    case "module":
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={
                            <Tooltip>
                              {rowData.module ? rowData.module.name : ""}
                            </Tooltip>
                          }
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData.module ? rowData.module.name : ""}
                          </div>
                        </Whisper>
                      );
                      case "dynamic_module":
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={
                            <Tooltip>
                              {rowData.assets?.module?.name}
                            </Tooltip>
                          }
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData.assets?.module?.name}
                          </div>
                        </Whisper>
                      );
                    case "company":
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={
                            <Tooltip>
                              {rowData.company ? rowData.company.name : ""}
                            </Tooltip>
                          }
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData.company ? rowData.company.name : ""}
                          </div>
                        </Whisper>
                      );
                    default:
                      return (
                        <Whisper
                          trigger="hover"
                          placement="auto"
                          controlId={`control-id-auto`}
                          speaker={<Tooltip>{rowData[column.key]}</Tooltip>}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: ".9rem",
                            }}
                          >
                            {rowData[column.key]}
                          </div>
                        </Whisper>
                      );
                  }
                }}
              </Table.Cell>
            </Table.Column>
          ))}

        <Table.Column align="right" flexGrow={1} verticalAlign="middle">
          <Table.HeaderCell>{""}</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return <Actions payload={rowData} />;
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Fragment>
  );
};
