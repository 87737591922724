import { TiPlus } from "react-icons/ti";
import ButtonIcon from "../../../components/ButtonIcon";

const Header = (props: any) => {
  const { handleCreateModal } = props;
  return (
    <>
      <div>Versiones</div>
      <div className="d-flex">
        <ButtonIcon
          Icon={TiPlus}
          label=""
          width="25px"
          height="25px"
          style={{ marginTop: "5px" }}
          onClick={handleCreateModal}
        />
      </div>
    </>
  );
};

export default Header;
