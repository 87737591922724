export default interface IBox {
  name: string;
  width: string;
  height: string;
  depht: string;
}

export const attributes = {
  id: "ID",
  delivery_user_name: "Nombre",
  status: "Status",
  phone: "Teléfono",
  template_name: "Template",
  template_id: "Template ID",
};
