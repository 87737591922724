export const pickupWithoutReservationStateSchema = {
  pickup_without_reservation_home_btn_load_title: "Carga",
  pickup_without_reservation_home_btn_load_description:
    "Haga click aquí para hacer una carga de un pedido.",
  pickup_without_reservation_home_btn_pickup_title: "Retiro",
  pickup_without_reservation_home_btn_pickup_description:
    "Haga click aquí para hacer un retiro de un pedido.",
  pickup_without_reservation_load_step_two_text_one:
    "Introduce o escanea el código QR",
  pickup_without_reservation_load_step_three_text_one:
    "Selecciona cuantas cajas necesitas",
  pickup_without_reservation_pickup_step_one_text_one:
    "Introduce o escanea el código QR",
  pickup_without_reservation_pickup_step_two_text_one:
    "Abre la(s) caja(s) y retira tu paquete",
  pickup_without_reservation_pickup_step_three_text_one:
    "Transacción completada con éxito",
};

export const SchemaSections: any = {
  home: [
    "pickup_without_reservation_home_btn_load_title",
    "pickup_without_reservation_home_btn_load_description",
    "pickup_without_reservation_home_btn_pickup_title",
    "pickup_without_reservation_home_btn_pickup_description",
  ],
  delivery: [
    "pickup_without_reservation_load_step_two_text_one",
    "pickup_without_reservation_load_step_three_text_one",
  ],
  pickup: [
    "pickup_without_reservation_pickup_step_one_text_one",
    "pickup_without_reservation_pickup_step_two_text_one",
    "pickup_without_reservation_pickup_step_three_text_one",
  ],
};
