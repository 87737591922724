/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Modal, SelectPicker } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _handleError } from "../../helpers/errors";
import validate from "../../helpers/validate";
import { loading_off, loading_on } from "../../redux/actions/loader";
import CompanyService from "../../services/company.service";
import { ArticleSchema } from "../../validations/article.validations";
import ArticleService from "../../services/articles.service";

const FormArticle = (props: any) => {
  const { type, data, handleClose } = props;
  const [state, setState] = useState<any>({
    username: "",
    name: "",
    door_com_number: "",
    qrcode_com_number: "",
    password: "",
    cpassword: "",
    address: "",
    company: "",
    module: "",
    validations: {},
  });
  const dispatch: any = useDispatch();

  const Article = new ArticleService();
  const Company = new CompanyService();

  useEffect(() => {
    _loadData();
  }, []);

  const _loadData = async () => {
    let companyResponse: any = [];
    try {
      if (type === "view") {
        setState({ ...state, ...data, companies: companyResponse.data });
      }

      if (type === "create") {
        dispatch(loading_on());
        const response: any = await Company.listByUser();
        const companies: any = response.data?.filter(
          (item: any) => item.company_type?.name === "lending"
        );
        const selectCompanies: any = companies.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setState({ ...state, selectCompanies, companies: selectCompanies });
      }

      if (type === "update") {
        dispatch(loading_on());
        const response: any = await Company.listByUser();
        const companies: any = response.data?.filter(
          (item: any) => item.company_type?.name === "lending"
        );
        const selectCompanies: any = companies.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        const payload: any = { ...state, ...data };
        payload.company = data?.company?.id;
        payload.companies = selectCompanies;
        setState({ ...payload });
      }

      dispatch(loading_off());
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(loading_off());
    }
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    let data: any = value;
    state[name] = data;
    setState({ ...state });
  };

  const _handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (type === "view") {
      handleClose();
      return;
    }

    let validations: any = await validate(ArticleSchema, state);

    if (validations !== false && Object.keys(validations).length > 0) {
      setState({ ...state, validations });
      return;
    }

    setState({ ...state, validations });

    const payload: any = {
      name: state.name,
      code: state.code,
      company_id: state.company,
    };

    dispatch(loading_on());
    try {
      if (type === "create") {
        await Article.create(payload);
      }

      if (type === "update") {
        payload.id = state.id;
        await Article.update(payload);
      }
    } catch (e: any) {
      _handleError(
        e,
        `No fue posible ${
          type === "create" ? "crear" : "actualizar"
        } el terminal`
      );
      if (e.response?.status === 422) {
        setState({ ...state, validations: e.response.data });
      }
      dispatch(loading_off());
      return;
    }

    handleClose(true);
  };

  return (
    <div className="container-fluid">
      <form onSubmit={_handleOnSubmit} autoComplete="none">
        <div className="row">
          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Nombre del articulo</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2">{state.name}</div>
            ) : (
              <Input
                placeholder="Nombre del articulo"
                value={state.name}
                autoComplete="off"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "name", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="name"
            />
          </div>

          <div className="col-6 mb-2">
            <label className="size-08 bold-300 ms-1">Codigo</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">{state.code}</div>
            ) : (
              <Input
                placeholder="Nombre del terminal"
                value={state.code}
                autoComplete="off"
                onChange={(value: any) =>
                  _handleOnChange({ target: { name: "code", value } })
                }
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="code"
            />
          </div>

          <div className="col-12 mb-2">
            <label className="size-08 bold-300 ms-1">Empresa</label>
            {type && type === "view" ? (
              <div className="size-08 ms-2 capitalize">
                {state.company?.name}
              </div>
            ) : (
              <SelectPicker
                className="w-100"
                locale={{
                  placeholder: "Selecciona una empresa",
                  searchPlaceholder: "Buscar",
                  emptyMessage: "Ninguna empresa encontrada",
                  noResultsText: "Ninguna empresa encontrada",
                }}
                onChange={(value: any) =>
                  _handleOnChange({
                    target: { name: "company", value },
                  })
                }
                value={state.company}
                data={state.companies || []}
              />
            )}
            <ErrorValidation
              className="text-end size-08"
              validations={state.validations}
              name="company"
            />
          </div>
        </div>

        <div className="row mt-4">
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cerrar
            </Button>
            <Button type="submit" appearance="primary">
              Confirmar
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </div>
  );
};

export default FormArticle;
